import axios from 'axios';
import React, { Component } from 'react';  

export default class Viewhomework extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {  
            homework: '',
            // homework: {
            //     "title": "dfsdfs",
            //     "date": "11/18/2021",
            //     "questions": [
            //       {
            //         "question": "Question/ Prompt1111111111111",
            //         "answer": "ssdfsdfsd111",
            //         "placeholder": "Answer/ Placeholder11111111111111111"
            //       },
            //       {
            //         "question": "Question/ Prompt22222222222",
            //         "answer": "sdfsdf2222222",
            //         "placeholder": "Answer/ Placeholder222222222222"
            //       },
            //       {
            //         "question": "3Question/ Prompt333333",
            //         "answer": "sdfsdf333",
            //         "placeholder": "Answer/ Placeholder33333333333333333333"
            //       }
            //     ]
            // }
} }/////////////////////////////////////////////////////////////////////////////
goBack() {
    this.props.backTab('');
}
async loadHomework() { 
    var res = await axios.get('/managing/gethomework', { params: {
        folder: this.props.folder,
        title: this.props.title
    }});
    // console.log('homework:',  res.data);  
    // console.log('title:', this.props.title);  
    // console.log('folder:', this.props.folder);  
    if (!res.data) {
        // console.log('flaseddata:',  res.data);  
        this.goBack();
    } else {
        // console.log('else:',  res.data);   
        await this.setState({ homework: res.data }); 
    } 
}
componentDidMount() {  
    // console.log('title:', this.props.title);  
    this.loadHomework();
};
render() { return(<div> {/*//////////////////////////////////////////////////*/}


<div className='column-body'>
{ this.state.homework !== '' ? <> 

    <div className="header-home">
        <input className="btn-blue" type="submit" value="Back" onClick={()=>{ this.goBack(); }}/>  
        <h1>{this.state.homework.title}</h1>   
        <input className="btn-hidden" type="submit" value="Back"     /> 
    </div>

    { this.state.homework.questions.map((question,index) => (<div>  
        <div id='cnt-take-quiz' > 
            <div  id='cnt-take-quiz-question'> 
                <dd> {`${index + 1}) ${question.question}?`} </dd>
            </div> 
            <div id='cnt-take-quiz-answers'>     
               
                    <div className='flex' id='input-take-check' >    
                        {/* <input type='checkbox' className={`check-take-${index}-${indexx}`}    onClick={()=>{ this.handleQuizChecks(indexx,index); }}    />  */}
                        <dd  > { question.answer } </dd>
                        {/* { this.state.isgrading ? <>
                            { this.state.htmlgradedquiz[index].answers[indexx].rightanswer ? <IoCheckmark id='img-check' /> 
                            : this.state.htmlgradedquiz[index].answers[indexx].theiranswer && !this.state.htmlgradedquiz[index].answers[indexx].rightanswer ? 
                                <IoClose id='img-close' /> 
                            : null }
                        </> : null } */}
                    </div>
                
            </div>
        </div>   
        
    </div>))   }

</> : null }
</div>  

</div> ) }}/////////////////////////////////////////////////////////////////////

