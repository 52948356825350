import React, { Component } from 'react'; 
import axios from 'axios';
  
export default class Home extends Component {

    constructor(props) {

        super(props);

        this.state = {
            
           
} }/////////////////////////////////////////////////////////////////////////////

jump() {
    console.log(document.querySelector('.cnt-home-vid').offsetTop)
}
 
render() { return( /////////////////////////////////////////////////////////////

    <div className='cnt-soon'>   
    <h1 className='txt-soon'>Coming Soon...</h1>
</div>



)  } } /////////////////////////////////////////////////////////////////////////

// {/* <div className='cnt-home'>
//     <button onClick={()=>{this.jump()}}>NICK XoX</button>
//     <div className='cnt-home-vid'>
//         <video src='/uploads/videos/galaxyb.mp4' autoPlay loop muted></video>
//     </div>
//     <div    className='cnt-home-vid3'> 
//         <video src='/uploads/videos/1632588773087_Stars Overlay.mp4' autoPlay loop muted></video> 
//     </div>
//     <div className='cnt-home-vid2'>
//         <video src='/uploads/videos/galaxyb.mp4' autoPlay loop muted></video>
//     </div>
// </div>      */}