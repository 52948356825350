import React, { Component } from 'react';
import { Link } from 'react-router-dom';  
import axios from 'axios'; 
import imgtrade from '../../content/images/star.png'; 
import { AiFillSetting, AiOutlineEye } from 'react-icons/ai';
import { FaUserGraduate } from 'react-icons/fa';
import { BsPeopleFill } from "react-icons/bs";
import { BiDuplicate, BiVideoRecording } from "react-icons/bi";
import { FiTrash2, FiHome } from "react-icons/fi"; 
import { MdSpaceDashboard } from "react-icons/md"; 
import { RiDashboardLine } from "react-icons/ri"; 
import { ImUserPlus } from "react-icons/im"; 
import { IoMdHelpCircleOutline } from "react-icons/io"; 
import { FaDiscord } from "react-icons/fa"; 




import Followup from './l_followup'; 
import Students from './l_students'; 
import Discord from './l_discord'; 


export default class Education extends Component {

    constructor(props) {

        super(props);

        this.state = { 
            nav: 'followup', 

} }/////////////////////////////////////////////////////////////////////////////
 
async innerNav(nav) { 
    // window.location.reload(false);  
    await this.setState({ nav: 'refresh_components_default' });

    this.setState({ nav: nav  }); 
}

async componentDidMount() {    
    // console.log('props: ', this.props.user);

    // var user = {}
    // user.name = ''
    await axios.get('/profile/login').then((res) =>{ 
      if (res.data.loggedIn){    
          this.props.updateInfo({
            status: true,
            name: res.data.user[0].name,
            email: res.data.user[0].email,
            folder: res.data.user[0].folder,
            role: res.data.user[0].role ,
            lastname: res.data.user[0].lastname ,
            startdate: res.data.user[0].startdate 
            
          });
        //   user.name = res.data.user[0].name;
        //   user.email = res.data.user[0].email;
        //   user.folder = res.data.user[0].folder;
        //   user.role = res.data.user[0].role;
           
      }   
    });  

    let user = this.props.user;
    // console.log('user: ', user);
    // if ( user === '' ) {
    //     this.props.history.push('/login');
    // } else if (user.role === 'admin') {
    //     this.innerNav('courses');
    // }  else if (user.role === 'monthly'){
    //     this.innerNav('kidscourses');
    // } else if (user.role === 'futures'){
    //     this.innerNav('kidscourses');
    // } else {
    //     this.props.history.push('/login');
    // }


    if ( user === '' ) { 
        this.props.history.push('/login');
    } else if (user.email === 'nick@starxox2.com' || user.email === 'anthony@tradersultimateedge.com') { 
        this.setState({ nav: 'followup'  }); 
    } else {
        this.props.history.push('/login');
    } 
    
};
render() { return(<div> {/*//////////////////////////////////////////////////*/}


<div className='column-ctn'>
    <div className='column'> 
        {/* <h5>{this.props}</h5> */}
     
            {/* <div className= 'column-titles' onClick={()=>{this.innerNav('courses');}}> 
                <Link id='link' className={this.state.nav=='courses' ? 'column-titles-active' : 'column-titles' }>
                    <FaUserGraduate className={this.state.nav=='courses' ? 'img-column-active' : 'img-column' }/>
                    Courses 
                </Link>
            </div> */}
    
            <div className= 'column-titles' onClick={()=>{this.innerNav('followup');}}> 
                <Link id='link' className={this.state.nav=='followup' ? 'column-titles-active' : 'column-titles' }>
                    <BiVideoRecording className={this.state.nav=='followup' ? 'img-column-active' : 'img-column' }/>
                    Follow Up
                </Link>
            </div> 
            <div className= 'column-titles' onClick={()=>{this.innerNav('students');}}> 
                <Link id='link' className={this.state.nav=='students' ? 'column-titles-active' : 'column-titles' }>
                    <BsPeopleFill className={this.state.nav=='students' ? 'img-column-active' : 'img-column' }/>
                    Students 
                </Link>
            </div> 
            <div className= 'column-titles' onClick={()=>{this.innerNav('discord');}}> 
                <Link id='link' className={this.state.nav=='discord' ? 'column-titles-active' : 'column-titles' }>
                    <FaDiscord className={this.state.nav=='discord' ? 'img-column-active' : 'img-column' }/>
                    Discord 
                </Link>
            </div> 
            
            {/* <div className= 'column-titles' onClick={()=>{this.innerNav('homework');}}> 
                <Link id='link' className={this.state.nav=='homework' ? 'column-titles-active' : 'column-titles' }>
                    <FiHome className={this.state.nav=='homework' ? 'img-column-active' : 'img-column' }/>
                    Homework 
                </Link>
            </div> 



            <div className= 'column-titles' onClick={()=>{this.innerNav('onboarding');}}> 
                <Link id='link' className={this.state.nav=='onboarding' ? 'column-titles-active' : 'column-titles' }>
                    <ImUserPlus className={this.state.nav=='onboarding' ? 'img-column-active' : 'img-column' }/>
                    Onboarding 
                </Link>
            </div> 

            <div className= 'column-titles' onClick={()=>{this.innerNav('tutor');}}> 
                <Link id='link' className={this.state.nav=='tutor' ? 'column-titles-active' : 'column-titles' }>
                    <IoMdHelpCircleOutline id='img-help' className={this.state.nav=='tutor' ? 'img-column-active' : 'img-column' }/>
                    Tutorials 
                </Link>
            </div>   */}
      

       

     


    </div>

{ 
    this.state.nav=='followup' ? 
        <Followup/> :  
    this.state.nav=='students' ? 
        <Students/> :  
    this.state.nav=='discord' ? 
        <Discord/> :  
null }


 

</div>
</div> ) }}/////////////////////////////////////////////////////////////////////

