import React, { Component } from 'react';  
// import axios from 'axios';   
import imgtrade from '../../../content/images/star.png'; 
import { FiEdit } from "react-icons/fi";  
import { BiDetail } from "react-icons/bi";  
import {  FaRegPlayCircle ,FaRegQuestionCircle} from "react-icons/fa";   
import { AiOutlineCloudDownload, AiFillCheckCircle } from "react-icons/ai";  
import PreviewTake from './preview-take'; 
import axios from 'axios';



export default class KidsCourse extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {  
            course: '',
            img: '1632323972968_clouds.jpg',
            innertab: 'preview',
            progress: {percent: 0},
           
} }/////////////////////////////////////////////////////////////////////////////

changeTab(tab) {
    this.setState({ innertab: tab });
};
// async loadProgress() {
//     let course =this.props.course;
//     let folder = this.props.user.folder;
//     let res = await axios.get('/takecourse/getprogress', {params: {
//         coursetitle: course.title,
//         folder: folder
//     }});
//     // console.log('res: ', res.data);
//     var currprogress = {percent: 0} ;
//     // console.log('coursetitle: ', course.title);

//     res.data.forEach(pcourse => {
//     // console.log('pcourse: ', pcourse); 
//         if ( pcourse.unqcourse === course.title ) {
//             currprogress = pcourse
//         }

//     });
//     // console.log('currprogress: ', currprogress);
//     this.setState({progress: currprogress });
 
// };

componentDidMount() {     
    this.setState({ course: this.props.course });  
    // this.loadProgress();
    // console.log('qty: ', this.props.qty);
};
render() { return(<div> {/*//////////////////////////////////////////////////*/}
{ this.state.innertab === 'takecourse'  ? 
    <PreviewTake
        changeTab = {this.changeTab.bind(this)}
        course = {this.state.course} 
        backbackTab = {this.props.changeNav}
    /> 
    
: <> 
    <div className='wrap-abs-btn-preview'>
        <button className='btn-blue' onClick={()=>{ this.props.changeNav('courses'); }}  >   Back </button>
    </div>
       
    <div className='wrap-kidcourse-preview-title'>

        <div className='cnt-kidcourse-preview-title' >
        
            <h2> {this.state.course.title}</h2>
            <dd> {this.state.course.synopsis}</dd>
            <div className='cnt-kidcourse-preview-title-button'>
                {
                    this.state.progress.percent === 0 ?
                        <button className='btn-blue' onClick={()=>{ this.changeTab('takecourse'); }}  >
                            Take Course
                        </button>
                    : this.state.progress.percent === 100 ?
                        <button className='btn-blue' onClick={()=>{ this.changeTab('takecourse'); }}  >
                            ReTake Course
                        </button>
                    :
                        <button className='btn-blue' onClick={()=>{ this.changeTab('takecourse'); }}  >
                            Resume Course
                        </button>
                }

                <dt>{`Progress: ${this.state.progress.percent}%`}</dt>

            </div>
        </div>


        <div className='img-kid-course'>
        { this.state.course.img!='' ?  
            <img src={ `/uploads/images/${this.state.course.img}` } alt='' onClick={()=> {this.changeTab('takecourse'); } } ></img>
            :  <img src={imgtrade} alt='' onClick={()=> { this.changeTab('takecourse');  } } ></img>
        }
        </div>
    </div>

    <div id='flex-center' >



        <div className='wrap-kidcourse-preview-details' >



            <div className='cnt-kidcourse-preview-detail' >
                <dd> {this.state.course.description} </dd>
            </div>
            
            <div className='txt-course-previews'> 
                { this.props.qty !== '' ?  <>
                    { this.props.qty.builder !== 0 ? 
                        <>
                            <BiDetail className='img-courses-detail' /> 
                            { this.props.qty === '' ? 
                                <txt2>0</txt2> 
                            :   <txt2>{this.props.qty.builder}</txt2>
                            }
                            
                        </> : null }   
                    { this.props.qty.video !== 0 ? 
                        <>
                            <FaRegPlayCircle className='img-courses-play' /> 
                            { this.props.qty === '' ? 
                                <txt2>0</txt2> 
                            :   <txt2>{this.props.qty.video}</txt2> 
                            }
                        </> : null }   
                    { this.props.qty.quiz !== 0 ? 
                        <>
                            <FaRegQuestionCircle className='img-courses-question' /> 
                            { this.props.qty === '' ? 
                                <txt2>0</txt2> 
                            :    <txt2>{this.props.qty.quiz}</txt2> 
                            }
                        </> : null }   
                    { this.props.qty.worksheet !== 0 ? 
                        <>
                            <FiEdit className='img-courses-worksheet' /> 
                            { this.props.qty === '' ? 
                                <txt2>0</txt2> 
                            :   <txt2>{this.props.qty.worksheet}</txt2> 
                            }
                        </> : null }   
                    { this.props.qty.download !== 0 ? 
                        <>
                            <AiOutlineCloudDownload className='img-courses-download' /> 
                            { this.props.qty === '' ? 
                                <txt2>0</txt2> 
                            :   <txt2>{this.props.qty.download}</txt2> 
                            }
                        </> : null }     
                </> : null }
            </div>


            { this.state.course != '' ? <>
                { this.state.course.unit.map((unit,index) => (<div>   
                    <div className='wrap-kidcourse-preview-box'>     
                        <div className='wrap-kidcourse-preview-box-title' id='flex-between' >     
                            <dt> {`*${unit.title}`}</dt>
                            <dd>{`${this.state.progress.percent}%`}</dd>
                        </div>

                        { unit.lessons.map((lesson,index) => (<div>   
                            { this.state.progress.percent === 0 ? 
                                <div  className='wrap-kidcourse-preview-box-data' id='flex-between'>   
                                    <div   id='flex-start'  >  
                                        <dd>  {lesson.title} </dd> 
                                    </div>
                                    <dl>Incomplete</dl>
                                </div> 
                            :
                                <div className='wrap-kidcourse-preview-box-data' id='flex-between'>    
                                    <div  id='flex-start'  > 
                                        <AiFillCheckCircle className='img-check' />
                                        <dd> {lesson.title}</dd>
                                    </div>
                                    <dt>Completed</dt>
                                </div>
                            }

                        </div>))   }
    

                    </div>  

                </div>))   }
            </> : null }
            



            {/* <div className='wrap-kidcourse-preview-box'>     
                    <div className='wrap-kidcourse-preview-box-title' id='flex-between' >     
                        <dt> *UNit title bitch</dt>
                        <dd>50%</dd>
                    </div>

                    <div className='wrap-kidcourse-preview-box-data' id='flex-between'>    
                        <div  id='flex-start'  > 
                            <AiFillCheckCircle className='img-check' />
                            <dd> thei sis mycloesson titles</dd>
                        </div>
                        <dt>Completed</dt>
                    </div>


                    <div  className='wrap-kidcourse-preview-box-data' id='flex-between'>   
                        <div   id='flex-start'  > 
                        
                            <dd> thei sis mycloesson titles</dd> 
                        </div>
                        <dl>Incomplete</dl>
                    </div> 

            </div>  

            <div className='wrap-kidcourse-preview-box'>     
                    <div className='wrap-kidcourse-preview-box-title' id='flex-between' >     
                        <dt> *UNit title bitch</dt>
                        <dd>50%</dd>
                    </div>

                    <div className='wrap-kidcourse-preview-box-data' id='flex-between'>    
                        <div  id='flex-start'  > 
                            <AiFillCheckCircle className='img-check' />
                            <dd> thei sis mycloesson titles</dd>
                        </div>
                        <dt>Completed</dt>
                    </div>


                    <div  className='wrap-kidcourse-preview-box-data' id='flex-between'>   
                        <div   id='flex-start'  > 
                        
                            <dd> thei sis mycloesson titles</dd> 
                        </div>
                        <dl>Incomplete</dl>
                    </div> 

            </div>   */}


        </div>
    </div>
</> }

</div> ) }}////////////////////////////////////////////////////////////////////////

