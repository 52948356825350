import React, { Component } from 'react';  
import axios from 'axios';   
import { FiTrash2 } from "react-icons/fi";  

export default class XoX extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {  
            showold: false,
            showfollowup: true, 
            // filtered: [
            //   {firstname: 'nick', lastname: 'asdasd', email: 'star@xox.com', phone: '(423) 504-2231' ,       date: 2638138162160, followup: 'No', old:'No'  },
            //   {firstname: 'admin', lastname: 'asdfsdasd', email: 'admin@xox.com', phone: '(423) 504-2231' , date:  1638136178412, followup: 'Yes' ,old:'Yes' },
            //   {firstname: 'xox', lastname: 'asdsdasd', email: 'xox@star.com', phone: '(423) 504-2231' , date: Date.now() , followup: 'No', old:'Yes' }
            // ],
            filtered: '',
} }/////////////////////////////////////////////////////////////////////////////
toSort(array, key) {
    return array.sort((a, b) => {
        var x = a[key]; var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}
// changeOld() {
//     this.setState({ showold: !this.state.showold });
//     var data = this.state.filtered ;
//     if ( this.state.showold) {
//         var filtered = [];
//         data.forEach((item,i) => {   
//             // console.log(`databef${i}: `, data[0].date);
//             var ind = item ;
            
//             filtered.push(ind);
//             // console.log(`dataaft${i}: `, data[0].date);  
//         });
//         //to sort by id/date   
//         filtered = this.toSort(filtered, 'date'); 
//         this.setState({ filtered: filtered });
//     } else {  
//         var filtered = [];
//         data.forEach((item,i) => {  
//             if ( item.old === 'Yes' ) {  
//                 var ind = item ;
                
//                 filtered.push(ind);
//             }  
//         });
//         //to sort by id/date   
//         filtered = this.toSort(filtered, 'date'); 
//         this.setState({ filtered: filtered });
//     }
// }
// changeFollowUp() {
//     var data = this.state.data ;   

//     this.setState({ showfollowup: !this.state.showfollowup });
//     if ( this.state.showfollowup ) {
//         var filtered = [];
//         data.forEach((item,i) => {   
//             // console.log(`databef${i}: `, data[0].date);
//             var ind = item ;
            
//             filtered.push(ind);
//             // console.log(`dataaft${i}: `, data[0].date);  
//         });
//         //to sort by id/date   
//         filtered = this.toSort(filtered, 'date'); 
//         this.setState({ filtered: filtered });
//     } else {  
//         var filtered = [];
//         data.forEach((item,i) => {  
//             if ( item.followup === 'Yes' ) {  
//                 var ind = item ;
                
//                 filtered.push(ind);
//             }  
//         });
//         //to sort by id/date   
//         filtered = this.toSort(filtered, 'date'); 
//         this.setState({ filtered: filtered });
//     }
// }
deleteFollowUp(email) {
    // console.log(email);
    var curr = this.state.filtered;
    var filtered = [];
    curr.forEach((follow,index)=>{
        if (follow.email !== email) {
            filtered.push(follow)
        }
    });
    this.setState({ filtered: filtered });
    axios.post('owner_/deletefollow',{
        email: email
    });

}
async loadAll() {
    var res = await axios.get('owner_/getfollowup');
    // console.log('res: ', res);
    var data = res.data ; 
    // var data = this.state.data ; 
    var filtered = []; 
    data.forEach((item,i) => { 
        var ind = item ;
        if ( item.id !== '0' ) {  
            let tt = new Date(Number(item.id));   
            let date = `${tt.getMonth() +1}/${tt.getDate()}/${tt.getFullYear()}`;
            // ind.date = date; 
            ind.datefor = date; 
        }  
        // if ( item.followup === 'Yes' ) {   
        //     
        // }  
        filtered.push(ind);
    });
    // console.log('data: ', data[0].date);
    
    //to sort by id/date   
    filtered = this.toSort(filtered, 'date'); 

    this.setState({ filtered: filtered });
}
componentDidMount() {    
    this.loadAll();
};
render() { return(<div> {/*//////////////////////////////////////////////////*/}


<div className='column-body'>
    <div className="header-home">
        <h1>Free Webinar</h1> 
        <div>
            {/* { this.state.showfollowup ? 
                <input className="btn-blue" type="submit" value="Show Follw Up Both" onClick={()=>{this.changeFollowUp();}}/> 
            :   <input className="btn-blue" type="submit" value="Only Show Follow Up" onClick={()=>{this.changeFollowUp();}}/> 
            }
            { this.state.showold ? 
                <input className="btn-blue" type="submit" value="Hide Old" onClick={()=>{this.changeOld();}}/> 
            :   <input className="btn-blue" type="submit" value="Show Old" onClick={()=>{this.changeOld();}}/> 
            } */}
        </div>
    </div>

    <div className='student-cnt'>
       
       <div className="main-body-table"> 
                   
            <table>
                <thead>
                <tr className='tr-head'> 
                    <th> First Name </th>  
                    {/* <th> Last Name </th>  */}
                    <th> Eamil </th>
                    <th> Phone </th> 
                    <th> Date </th> 
                    <th> Follow Up </th> 
                    <th> Delete </th> 
                    {/* <th> Old </th>  */}
                </tr>
                </thead>
                <tbody>
    
                {this.state.filtered != ''? <>
                { this.state.filtered.map(data =>  
        
                    <tr className='tr-table' > 
                        <td >{data.firstname}</td>   
                        {/* <td >{data.lastname}</td>   */}
                        <td >{data.email}</td>  
                        <td >{data.phone}</td>  
                        <td >{data.datefor}</td>  
                        <td >{data.followup}</td>  
                        <td ><FiTrash2 className='img-delete-followup'
                            onClick={()=>{ this.deleteFollowUp(data.email); }}
                        /></td>  
                        {/* <td >{data.old}</td>   */}
                      
                        
                        
                    </tr>
                )}
                </> :null }
                </tbody>
            </table>
                         
                            
        </div>
   
   
   </div>

</div>

</div> ) }}/////////////////////////////////////////////////////////////////////

