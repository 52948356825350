import React, { Component } from 'react';  
import { FiTrash2 } from "react-icons/fi";  
import { BsPersonSquare } from "react-icons/bs";  
import axios from 'axios';
import Viewhomework from './view-homework';  
import Viewworksheet from './view-worksheet';  


export default class Student extends Component {

    constructor(props) {

        super(props); 

        this.state = {    
            recourses: '',
            quizes: '',
            worksheets: '',
            homework: '',
            startdate: '',
            inndertab: '',
            
            // quizes: [ 
            //     { name: 'nick', emial: 'nisdasdjl', role: 'asdas' },
            //     { name: 'nasdasdick', emial: 'nisdasdaasdjl', role: 'asdaasds'} 

            // ],

        } 
    }
    showWorksheet(title,lesson) {
        this.setState({ worktitle: title }); 
        this.setState({ worklesson: lesson }); 
        this.setState({ inndertab: 'viewworksheet' }); 
    }
    showHomework(title) {
        this.setState({ title: title }); 
        this.setState({ inndertab: 'viewhomework' }); 
    }
    innderTab(tab) {
        this.setState({ inndertab: tab });
    }

    async goLoadAll() {
        var res = await axios.get('/courses/loadall'); 
        var resp = await axios.get('/takecourse/getprogress', {params: { 
            folder: this.props.user.folder
        }});
        var progress = resp.data ;   
        var courses = res.data ;
        var reformatCourses = [];
        for (let i = 0; i < courses.length; i++) {
            var course = courses[i];
            let base = {};
            base.title = course.title ;
            if ( course.status.includes('monthly') ) {
                if ( course.status.includes('future') ) {
                    base.role = 'monthly & futures BC'
                } else {
                    base.role = 'monthly'
                }
            } else if (course.status.includes('future')) {
                base.role = 'futures BC'
            } else {
                base.role = 'inprogress'
            }
            var percent = 0 ; 
            for (let o = 0; o < progress.length; o++) {
                var coursetouched = progress[o]; 
                if ( coursetouched.unqcourse === course.title ) {
                    percent = coursetouched.percent; 

                } 
            } 
            base.percent = percent ; 
            reformatCourses.push(base) ; 
  
            
        }
        this.setState({ recourses: reformatCourses });
        //loading this tables
        var respo = await axios.get('/takecourse/getquiz', {params: { 
            folder: this.props.user.folder
        }});
        if ( respo.data.length >= 1 ) {
            this.setState({ quizes: respo.data });
        }
         
        var respon = await axios.get('/takecourse/getworksheets', {params: { 
            folder: this.props.user.folder
        }}); 
        if ( respon.data.length >= 1 ) {
            this.setState({ worksheets: respon.data });
        }

        var respons = await axios.get('/takecourse/gethomework', {params: { 
            folder: this.props.user.folder
        }}); 
        if ( respons.data.length >= 1 ) {
            this.setState({ homework: respons.data });
        }
    }   

   
    componentDidMount = () => { 
        
        this.goLoadAll();
        if (this.props.newpage) {
           this.setState({ startdate: this.props.user.startdate});
        } else {
            // console.log('startdate: ', this.props.user.startdate);
            let tt = new Date(Number(this.props.user.startdate));   
            let date = `${tt.getMonth() +1}/${tt.getDate()}/${tt.getFullYear()}`;
            this.setState({ startdate: date});
        }
   
    }
    render() { 
        // const { newpage  } = this.props;
      
        return(    <div>  
 
{this.state.inndertab === 'viewhomework' ? 
    <Viewhomework
        title={this.state.title}
        backTab={this.innderTab.bind(this)}
        folder={this.props.user.folder}
    />
: this.state.inndertab === 'viewworksheet' ?
    <Viewworksheet
        title={this.state.worktitle}
        lesson={this.state.worklesson}
        backTab={this.innderTab.bind(this)}
        folder={this.props.user.folder}
    />
:
<div className='column-body'>

    <div className="header-home">
        { this.props.newpage ? <>
            <input className="btn-blue" type="submit" value="Go Back" onClick={()=>{this.props.newpage('teacher');}} />
            <h1>Manage Student</h1> 
        </>: <h1>  Dashboard</h1>  }
        
        <div> 
            <input className="btn-hidden" type="submit" value="temp-placeholder" />
            <input className="btn-hidden" type="submit" value="temp-placeholder" />
            <input className="btn-hidden" type="submit" value="temp-placeholder" />
        </div>
    </div>
 

 

<div className='cnt-create'> 

<div className='ctn-create-input'>
    Courses
    <div className='ctn-students-course'>

        { this.state.recourses !== '' ?  <>
            { this.state.recourses.map((course,index)=><div> 
                { course.role.includes(this.props.user.role) ? <div> 
                    <div className='ctn-students-courses'>
                        <div className='ctn-students-courses-title'> {course.title}  </div>
                        <div className='ctn-students-courses-tier'> 
                            <txt> {`Role : ${course.role}`} </txt> 
                        </div >
                        <div className='ctn-students-courses-progress'> {`Progress Bar: ${course.percent}`}   </div>  
                        <div className='rand-progressbar-students-courses'>
                        <div className='rand-progressbar-students-courses-perc' 
                            style={{width: `${course.percent}%` }}></div>
                        </div>
                    </div>
                </div> : null }
            </div>) }

        </>: null }  
    </div>


    <div className='ctn-students-quiz-title'> Quizzes </div>
    <div className='ctn-students-quizzes'> 
        <table>
            <thead>
                <tr className='ctn-students-quizzes-header'> 
                    <th> Lesson </th>  
                    <th> Date </th> 
                    <th> Score </th> 
                </tr>
            </thead>
            <tbody> 
                { this.state.quizes != ''? <>
                    { this.state.quizes.map(data =>  
            
                        <tr className='ctn-students-quizzes-body' > 
                            <td   >{data.lesson}</td>  
                            <td   >{data.date}</td>
                            <td  >{data.score}</td> 
                            {/* <td onClick={()=>{ this.getInfo(data); }} >{data.role}</td>  */} 
                        </tr>
                    )}
                </> :null }
            </tbody>
        </table>
    </div>

    <div className='ctn-students-quiz-title'> Worksheets </div>
    <div className='ctn-students-quizzes'> 
        <table>
            <thead>
                <tr className='ctn-students-quizzes-header'> 
                    <th> Title </th>  
                    <th> Lesson </th> 
                    <th> Date </th> 
                </tr>
            </thead>
            <tbody> 
                { this.state.worksheets != ''? <>
                    { this.state.worksheets.map(data =>  
            
                        <tr className='ctn-students-quizzes-body-click' onClick={()=>{ this.showWorksheet(data.title,data.lesson); }} > 
                            <td   >{data.title}</td>  
                            <td   >{data.lesson}</td>
                            <td  >{data.date}</td> 
                            {/* <td onClick={()=>{ this.getInfo(data); }} >{data.role}</td>  */} 
                        </tr>
                    )}
                </> :null }
            </tbody>
        </table>
    </div>
    
    <div className='ctn-students-quiz-title'> Homework </div>
    <div className='ctn-students-quizzes'> 
        <table>
            <thead>
                <tr className='ctn-students-quizzes-header'> 
                    <th> Title </th>   
                    <th> Date </th> 
                </tr>
            </thead>
            <tbody> 
                { this.state.homework != ''? <>
                    { this.state.homework.map(data =>  
            
                        <tr className='ctn-students-quizzes-body-click' onClick={()=>{ this.showHomework(data.title); }} > 
                            <td   >{data.title}</td>   
                            <td  >{data.date}</td> 
                            {/* <td onClick={()=>{ this.getInfo(data); }} >{data.role}</td>  */} 
                        </tr>
                    )}
                </> :null }
            </tbody>
        </table>
    </div>

</div>

<div className='ctn-create-side'> 

    <div className='cnt-student-img'> 
        { this.state.image ? 
            <img  className='img-student' src= {this.state.image}  alt='' ></img> 
            : <BsPersonSquare className='img-student' />}
    <label>{`${this.props.user.name} ${this.props.user.lastname}`}</label> 
    <txt >{`${this.props.user.email}`}</txt>  
    <txt2>{`Member Since: ${this.state.startdate}`}</txt2> 
    </div>
    
</div>
 


 
 


  

</div>

 
</div>
}
        </div> ) } }

