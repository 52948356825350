import React, { Component } from 'react'; 
import ReactPlayer from 'react-player'; 
import axios from 'axios'; 
import { IoClose } from "react-icons/io5";    
import { FiEdit } from "react-icons/fi";  
import { Link } from 'react-router-dom';  

const headers = {
    headers: { 'Content-Type': 'multipart/form-data; charset=utf-8; boundary="another cool boundary";' }
};
// const chunkSize = 10000 * 1024;

export default class Videos extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {   
            status: 'all',
            innertab: 'calls',
            vidupload: '',
            title: '',
            details: '',
            allcalls: '',
            // allcalls: [
            //     { id: 1, title: 'these nutes', details: 'sk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lk111111jlk111lkj111', filename: '1632588785806_nasa1_Trim.mp4', date: '4-5-1995' },
            //     { id: 2, title: 'XoX', details: '22l2jlk2jlk2jkl2jl2klj2', filename: '1632588785806_nasa1_Trim.mp4', date: '4-5-1995' },
            //     { id: 3, title: 'NICK', details: '3kj3lkj3lkj3kjl3kj3l', filename: '1632588785806_nasa1_Trim.mp4', date: '4-5-1995' }
            // ],
            currentChunkIndex : 0,
            chunkSize : 56320000,
            file: '',
            percent: 0
             
} }/////////////////////////////////////////////////////////////////////////////

async editCall(index) {
    var call = this.state.allcalls[index]
    await this.setState({ status : call.status }); 
    await this.setState({ vidupload : call.filename }); 
    await this.setState({ title : call.title }); 
    await this.setState({ details : call.details });    
    await this.innerTab('edit');
    await this.updateChecks() ;
}

async updateVideo2(e) {
    await this.setState({ file : e.target.files[0] }); 
    // console.log('updateVideo2: '); 
    this.readAndUploadCurrentChunk();
    // console.log('after: '); 
    // await this.setState({ tt : Date.now() });   
}

readAndUploadCurrentChunk(){  
    const chunkSize = this.state.chunkSize ;
    const reader = new FileReader();
    const file = this.state.file; 
    const from = this.state.currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = ev => this.uploadChunk(ev);
    reader.readAsDataURL(blob);  
    // console.log('ednd: '); 

} 
uploadChunk(readerEvent) {
    // console.log('readerEvent: '); 
    const chunkSize = this.state.chunkSize ;


    const file = this.state.file;  
    const currentChunkIndex = this.state.currentChunkIndex ;
    const data = readerEvent.target.result;
    // console.log('data: ', data); 
    // console.log('totalChunks: ', Math.ceil(file.size / chunkSize)); 

    const params = new URLSearchParams();
    params.set('name', file.name);
    params.set('size', file.size);
    params.set('currentChunkIndex', currentChunkIndex);
    params.set('totalChunks', Math.ceil(file.size / chunkSize));
    const headersst = {'Content-Type': 'application/octet-stream'};
    const url = '/courses/uploadvidstream?'+params.toString();
    axios.post(url, {data:data}, {headersst})
      .then(async response => { 
        const filesize = file.size;
        const chunks = Math.ceil(filesize / chunkSize) - 1;
        const isLastChunk = currentChunkIndex === chunks  ;
        // console.log('response: '); 

        if (isLastChunk) {
        // console.log('response: ', response.data); 

        //   file.finalFilename = response.data.finalFilename;
            if (!response.data) { 
            } else {
                this.setState({ vidupload: response.data }); 
            }
            
            this.setState({ currentChunkIndex: 0 });  
            this.setState({ file: '' });   
            this.setState({ percent : 0 });
 

        } else { 
        // console.log('else: '); 

            await this.setState({ currentChunkIndex : currentChunkIndex + 1 });  
            let percent = (currentChunkIndex / chunks) * 100
            await this.setState({ percent : percent });  
            this.readAndUploadCurrentChunk();
        }
      });
  }
 
innerTab(tab) {
    this.setState({ innertab: tab });
};


updateVideo(e){
    // this.deleteVideo();
    // console.log('in this bitch');

    let vidfile = e.target.files[0];  
    // console.log('type:', vidfile.type)
    if  (vidfile.type.includes('video/')) {
        // console.log('typein toy:'  )

        let formData = new FormData();
    // console.log('formData:', formData)

        formData.append('file', vidfile ); 
    // console.log('vidfile:', vidfile)

        // let res = await axios.post('/courses/vidupload', formData, headers)  ;
        // this.setState({ vidupload: res.data });
        console.log('resp');

        var res = '';  
        try {
            axios.post("/courses/vidupload", formData, headers).then((resp) => {
                // console.log('resp',resp);
                res = resp ;
                // console.log('res',res.data);
                this.setState({ vidupload: res.data });
        }).catch(err => {
                // console.log('err',err);
        });
        } catch(errr) {
            // console.log('errr',errr);

        }




    } else {
       alert('not a video...');
    };
};
async goSave() {
    var tab = this.state.innertab ;
    if (tab === 'add') {
        await this.create();
    } else if (tab === 'edit') {
        await this.updateEdit();
    }
    
    await this.setState({ innertab: 'calls' });
    this.loadAll();   
};
async updateEdit() {
    if (this.state.title !=='' && this.state.vidupload !=='') {  
        let res= await axios.post('/courses/updatetutor', { 
            title: this.state.title, 
            details: this.state.details,  
            status: this.state.status,
            filename: this.state.vidupload 
        })  
        let nothing = res.data
        this.setState({ vidupload: '' });
        this.setState({ title: '' });
        this.setState({ details: '' }); 
        this.setState({ status: '' }); 
    } 
}

async create() {  
    if (this.state.title !=='' && this.state.vidupload !=='') {  
        let res= await axios.post('/courses/createtutor', 
            { title: this.state.title, details: this.state.details, filename: this.state.vidupload, status: this.state.status })  
        let nothing = res.data
        // console.log('nothing: ', nothing);
        this.setState({ vidupload: '' });
        this.setState({ title: '' });
        this.setState({ details: '' }); 
        this.setState({ status: '' }); 
    } 
};

async deleteCall(index) {  
    let calls = this.state.allcalls; 
    let id = calls[index].id
    await this.setState({ allcalls: ''}); 
    this.goDeleteFile('video', calls[index].filename);  
    calls.splice(index,1);  
    let res= await axios.post('/courses/deletetutor', { id: id } ) ; 
    // console.log( 'rr', res.data)
    this.loadAll();   
    // this.setState({ allcalls: calls}); 
};
async goDeleteFile(type,filename) {
    axios.delete('/courses/deletefile', {params: {type: type, file: filename } } ) 
};
async loadAll() { 
    let res= await axios.get('/courses/loadtutor' ) 
    this.setState({ allcalls: res.data.reverse() }) 
    // console.log('out: ',res.data)  
}
componentDidMount() { 
    this.loadAll();   
}
render() { return(//////////////////////////////////////////////////////////////
<>
{ this.state.innertab ==='calls' ? 
    <div className='column-body'>
        <div className="header-home">
            <h1>Tutorials</h1> 
            <div>
                <input className="btn-blue" type="submit" value="+ Add" onClick={()=>{this.innerTab('add');}}/> 
            </div>
        </div>

        <div className='cnt-calls-videos'>
            { this.state.allcalls !='' ? 
                this.state.allcalls.map((call,index)=>(<>
                    <div className='cnt-call'> 
                        <div className='vid-calls'>
                            {/* <ReactPlayer url={ `/uploads/videos/${call.filename}` } 
                                width='100%' height='98.5%' controls={true}   
                                config={{ file: { 
                                    attributes: {
                                      controlsList: 'nodownload'
                                    }
                                  }}}
                            />  */}
                        <video id='autovideo' controls controlslist="nodownload"  width = '100%' height = '98.5%' 
                            autoPlay muted  
                        >
                            <source src={ `/uploads/videos/${call.filename}` }     type='video/mp4'/>  
                        </video>
                        </div>
                        <div>
                            <div className='cnt-call-details'>
                                <h1>{call.title}</h1>
                                <p>{call.details}</p>
                            </div>
                            <div className='cnt-call-delete'>
                                <div className='txt-call-date'>
                                    <p>{`Release date: ${call.date}`}</p>
                                </div>
                               
                                <Link>
                                    <IoClose className='img-x-calls' onClick={()=>{ this.deleteCall(index) }} />
                                </Link> 
                                <Link>
                                    <FiEdit className='img-edit-calls' onClick={()=>{ this.editCall(index) }} />
                                </Link> 
                             
                            </div>
                        </div> 
                    </div> 
                </>)) 
            : null } 
        </div> 
    </div> 
:
    <div className='column-body'>
        <div className="header-home"> 
            <input className="btn-blue" type="submit" value="Go Back" onClick={()=>{this.goSave();}}/>  
            <h1>Add Tutorial</h1> 
            <input className="btn-blue" type="submit" value="Save" onClick={()=>{this.goSave();}}/>  
        </div>
        <div className='cnt-create'>
            <div className='ctn-create-input'>
                Call Title
                    <input  type="text"  autoComplete="on" autoFocus
                        onChange={(e)=> this.setState({ title: e.target.value }) }
                        value={ this.state.title } 
                    />
                    
                
                Call Description
                    <textarea  className='txta-descrip' autoComplete="on" 
                        onChange={(e)=> this.setState({ details: e.target.value }) }
                        value={ this.state.details } 
                    
                />
            </div>
            {/* <div className='cnt-call-side'>
                { this.state.vidupload ==='' ? 
                    <div>
                        <input type="file" onChange={(e)=> { this.updateVideo(e) }}/>
                    </div>
                : 
                    <div className='vid-call-side'>
                        <ReactPlayer url={ `/uploads/videos/${this.state.vidupload}` } 
                            width='100%' height='93.5%' controls={true}  /> 
                    </div>
                }   

            </div> */}


            <div className='ctn-create-side'>
              
                <div className='cnt-call-side'>
                    { this.state.vidupload ==='' ? <>
                        <div>
                            <input type="file" onChange={(e)=> { this.updateVideo2(e) }}/> 
                        </div>
                   
                <div className='rand-progressbar-vidupload-calls'>
                    <div className='rand-progressbar-vidupload-calls-perc' 
                        style={{width: `${this.state.percent}%` }}> 
                    </div>
                </div>
                    </>: 
                        <div className='vid-call-side'>
                            {/* <ReactPlayer url={ `/uploads/videos/${this.state.vidupload}` } 
                                width='100%' height='93.5%' controls={true}   
                                config={{ file: { 
                                    attributes: {
                                      controlsList: 'nodownload'
                                    }
                                  }}}
                            />  */}
                            <video id='autovideo' controls controlslist="nodownload"  width = '100%' height = '98.5%' 
                                autoPlay muted  
                            >
                                <source src={ `/uploads/videos/${this.state.vidupload}` }      type='video/mp4'/>  
                            </video>
                        </div>
                    }   

                </div>
            </div>
        </div>
    </div> 
}</>
) }}////////////////////////////////////////////////////////////////////////////

