import React, { Component } from 'react';  

import { ReactComponent as YourSvg } from '../../content/images/traders.svg';
// import axios from 'axios';   

// var stripePromise;
 
// const getStripe = () => {
//     if (!stripePromise) {
//         stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
//     }
//     return stripePromise;
// }
// const item = = {
//     price: '',
//     quantity: 1
// }
// const checkoutOptions = {
//     lineItems : [item],
//     mode: 'payment',
//     successUrl: `${window.location.origin}/success`,
//     cancelUrl: `${window.location.origin}/cancel`
// }
// const redirectToCheckout = async () => {
//     console.log('redireting');
    
// }

import Switch from "react-switch";
import { 
    CardNumberElement, 
    CardExpiryElement, 
    CardCvcElement 
} from '@stripe/react-stripe-js';//CardElement

import axios from 'axios';
// import stripe from '../../../../routes/stripe';
// import { loadStripe } from '@stripe/stripe-js';

const cardOptions = {
    style: {
        base: {
            iconColor: '#000000',
            color: '#000000',
            fontWeight: '500',
            fontFamily: 'Montserrat, arial, sans-serif',
            fontSize: '13px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
            color: '#fce883',
            },
            '::placeholder': {
                color: '#d9cac5',
            },
        },
        invalid: {
            iconColor: '#de2629',
            color: '#de2629',
        },
    },
    // disabled: true,
    showIcon: true, 
}



export default class XoX extends Component { 
    constructor(props) { 
        super(props);    
        this.state = {  
            toggle_billed: false, 
            username: '',
            stripe: null,
} }/////////////////////////////////////////////////////////////////////////////
async payBoth() {
    this.goPay2();
};
async goPay2() {
    const { elements, stripe } = this.props; //, elements
    const cardElement = elements.getElement(CardNumberElement);
    var options = {
        name: 'nickx xoxx',
        address_line1: '4109  forst',
        // address_line2: 'na',
        address_city: 'hixson',
        address_state: 'tn',
        address_zip: '37343',
        address_country: 'US',
        currency: 'usd'
      };

    var card_token_pre = await stripe.createToken(cardElement, options);
    console.log('card_token_pre:: ', card_token_pre);
    if (card_token_pre.error) {
        console.log('Icatcherror:: ', card_token_pre);
    }

    var stripe_token_id = card_token_pre.token.id;
    var card_token = card_token_pre.token.card.id;
    console.log('stripe_token_id:: ', stripe_token_id);
    console.log('card_token:: ', card_token);
   
    var res = await axios.post('/stripe/subscription', {
        stripe_token: stripe_token_id
    });
    console.log('res:: ', res.data);

}

async goPay() { 
    var price = 100;
    const { data: clientSecret } = await axios.post("/stripe/payment_intents", {
        amount: price * 100
      });
    console.log('clientSecret:: ', clientSecret);


    const billingDetails = {
        name: 'nick',
        email: 'nick@test.com',
        address: {
            city: 'hixson',
            line1: '409 forest place dr',
            state: 'TN',
            postal_code: '37343'
        }
    };
    const { elements, stripe } = this.props; //, elements
    const cardElement = elements.getElement(CardNumberElement);
    // const cardElement = this.props.elements.getElement('cardNumber');
    // console.log('cardElement:: ', cardElement);
    const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails
    });
    console.log('paymentMethodReq:: ', paymentMethodReq);

    const confirmPayment = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id
      });
    console.log('confirmPayment:: ', confirmPayment);
}
handleToggle() {
    this.setState({ toggle_billed: !this.state.toggle_billed});
  }
// payment() {
//     console.log(process.env.REACT_APP_STRIPE_KEY);
// }
componentDidMount() {    
    
};
render() { return(<div> {/*//////////////////////////////////////////////////*/}



<div className='pay_all'>

    <div className='pay_preview_cnt'> 

        <div className='pay_preview_logo'>
            <YourSvg className='pay_logo' />
            <h1>Traders Ultimate Edge </h1>
        </div> 
        <div className='pay_preview_permonth'>
            <h2>One-Time payment for Silver Plan</h2> 
            <span className='pay_preview_permonth_span'>
                <h3>$1000.00</h3>
                <span> 
                    <p>one</p>
                    <p>time</p>
                </span> 
            </span>
             
        </div>

        <div className='pay_preview_permonth'>
            <h2>Subscribe to Silver Plan - Brett Referral </h2> 
            <span className='pay_preview_permonth_span'>
                <h3>$197.00</h3>
                <span>
                    <p>per</p>
                    <p>month</p>
                </span> 
            </span>
            <h5>Full-Time Day Trader Pathway, one 1-on-1 per month. 6
                month option provides a 10% discount. Referred by Brett Werner.</h5> 
        </div>

        <div className='pay_preview_plan_top'>
            <span className='pay_preview_plan_top_span'>
                <h4>Silver Plan - Brett Referral</h4>
                <h4>$197.00</h4>

            </span>
            <p>Billed monthly</p>
 
        </div> 
        <div className='pay_preview_plan_bottom'>
            <Switch 
                onChange={()=> {this.handleToggle(); }} 
                checked={this.state.toggle_billed} 
                uncheckedIcon={false}
                checkedIcon={false}
                height={20}
                width={40}
            />
            {this.state.toggle_billed ? 
                <span className='pay_preview_spam_bottom'>
                    <p>Switch to monthly billing</p>
                    <h5>$197.00/month</h5>
                </span>
            :
                <span className='pay_preview_span_bottom'>
                    <span className='flex'>
                        <p>Save with 6-month billing</p>
                        <data>$199 off</data>
                    </span>
                    <span>
                        <h5>$1,063.00</h5>
                        <h4>every 6 months</h4>

                    </span>
                    
                </span>
            } 
        </div>
        <div className='pay_preview_total'>
            <p>Total due today</p>
            <data>$197.00</data>
        </div>
    </div>

    <div className='pay_card_cnt'>
        <h1>Pay with card</h1>
        <h2>Contact information</h2>
        <input className='pay_input_email_l'
            type="text"  autoFocus
            onChange={(e) => {
                this.setState({ username: e.target.value });
            }}
            value = {this.state.username?this.state.username:''}
            autocomplete="on"
            placeholder="email@example.com"
        />
        <input className='pay_input_phone'
            type="text"   
            onChange={(e) => {
                this.setState({ username: e.target.value });
            }}
            value = {this.state.username?this.state.username:''}
            autocomplete="on"
            placeholder="(222) 123-4567"
        />

        
        <h2>  Card information </h2>
        




        {/* <input className='pay_input_email_l'
            type="text"  
            onChange={(e) => {
                this.setState({ username: e.target.value });
            }}
            value = {this.state.username?this.state.username:''}
            autocomplete="on"
            placeholder="2052 2052 2052 2052"
        /> */}
        <div className='pay_input_email'> 
            <div className='pay_stripe_card'>
                {/* <CardElement options={cardOptions}/> */}
                <CardNumberElement options={cardOptions}/> 
                 
                
            </div>
        </div>

        <span className='flex pay_card_l'>
            <span className='pay_stripe_ex'>
                <CardExpiryElement options={cardOptions}/>
            </span>
            <span className='pay_stripe_cvc'>
                <CardCvcElement options={cardOptions}/> 
            </span>
       
            
                {/* <CardNumberElement options={cardOptions}/>  */}
            {/* <input className='pay_input_card_month'
                type="text"   
                onChange={(e) => {
                    this.setState({ username: e.target.value });
                }}
                value = {this.state.username?this.state.username:''}
                autocomplete="on"
                placeholder="MM / YY"
            /> */}
            {/* <input className='pay_input_card_cvc'
                type="text"   
                onChange={(e) => {
                    this.setState({ username: e.target.value });
                }}
                value = {this.state.username?this.state.username:''}
                autocomplete="on"
                placeholder="CVC"
            /> */}
        </span>

        <h2>    Name on card </h2>
        <input className='pay_input_name'
            type="text"  
            onChange={(e) => {
                this.setState({ username: e.target.value });
            }}
            value = {this.state.username?this.state.username:''}
            autocomplete="on"
            placeholder="First Last"
        />

        <h2>  Billing address </h2>
        <input className='pay_input_bill_address'
            type="text"  
            onChange={(e) => {
                this.setState({ username: e.target.value });
            }}
            value = {this.state.username?this.state.username:''}
            autocomplete="on"
            placeholder="Address"
        />
        <span className='flex pay_card'>
            <input className='pay_input_city'
                type="text"   
                onChange={(e) => {
                    this.setState({ username: e.target.value });
                }}
                value = {this.state.username?this.state.username:''}
                autocomplete="on"
                placeholder="City"
            />
            <input className='pay_input_zip'
                type="text"   
                onChange={(e) => {
                    this.setState({ username: e.target.value });
                }}
                value = {this.state.username?this.state.username:''}
                autocomplete="on"
                placeholder="ZIP"
            />
        </span>
        <input className='pay_input_state'
            type="text"  
            onChange={(e) => {
                this.setState({ username: e.target.value });
            }}
            value = {this.state.username?this.state.username:''}
            autocomplete="on"
            placeholder="State"
        />
        <button onClick={()=>{ this.goPay2(); }}>  Subscription</button>
        <p>
        By confirming your subscription, 
        you allow Trader's Ultimate Edge to charge your 
        card for this payment and future payments in accordance with their terms.
        </p>

        
    </div>
</div>



</div> ) }}/////////////////////////////////////////////////////////////////////

