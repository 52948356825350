import React, { Component } from 'react';  
import axios from 'axios'; 
import { FiTrash2, FiEdit3 } from "react-icons/fi";   
import { IoClose } from "react-icons/io5";    


import imgstar from '../../../content/images/star.png'; 
import { Link } from 'react-router-dom';  
// import vid1 from '../../../content/uploads/galaxyb.mp4';
import ReactPlayer from 'react-player'; 


// {builder: '', video: '', quiz: [{ question: '', 
// answers: [{ correct: false, answer: '' }] }], worksheet: {title: '', 
// questions: [{ question: '', placeholder: ''}]}, download: '' }}]}

const headers = {
    headers: { 'Content-Type': 'multipart/form-data; charset=utf-8; boundary="another cool boundary";' }
};

export default class Edit extends Component {

    constructor(props) {

        super(props); 

        this.state = {   
            tab: 'video', 
            content: '',
            needit: false,
            currentChunkIndex : 0,
            chunkSize : 56320000 ,
            file: '',
            percent: 0,
            quizrequire: false,

        } 
    }
    changeQuizRequire() { 
        var content = this.state.content ; 
        var requrie = !this.state.quizrequire ;
        content.quiz[0].require = requrie ;
        this.setState({ quizrequire: requrie });
        this.setState({ content: content }); 
    }

    nick() {  
        //  console.log(this.state.content.video);
         if (this.state.content.video =='') {
            // console.log('nick');
         } else {
            // console.log('nnonon');
         }
    };

    dictUpdateEvent(e) { 
        let content = this.state.content; 
        content['builder'] = e.target.value  
        this.setState({ content: content}) 
    };
    dictUpdate(key,value) {
        let content = this.state.content; 
        content[key] = value
        this.setState({ content: content})  
    }
    update2key(tab,key,value) {
        let content = this.state.content; 
        content[tab][key] = value
        this.setState({ content: content})    
    };
    updateWorkQues(index,value) {
        let content = this.state.content; 
        content.worksheet.questions[index].question = value
        this.setState({ content: content})    
    };
    updateWorkAnsw(index,value) {
        let content = this.state.content; 
        content.worksheet.questions[index].placeholder = value
        this.setState({ content: content})    
    }; 
    // updateVideo(e){
    //     // this.deleteVideo();
    //     // console.log('inthis: ')  ;

    //     let vidfile = e.target.files[0];  
        
    //     if  (vidfile.type.includes('video/')) {
    //         // console.log('inif: ')  ;
    //         let formData = new FormData();
    //         formData.append('file', vidfile ); 
    //         // console.log('sendering: ')  ;
    //         // let res = await axios.post('/courses/vidupload', formData) ;
    //         // let res = await axios.post('/courses/vidupload', formData, headers)  ; 
    //         // this.dictUpdate('video', res.data);
    //         // console.log(res.data)
    //         var res = '';  
    //         axios.post("/courses/vidupload", formData, headers).then((resp) => {
    //                 // console.log(resp);
    //                 res = resp ;
    //                 // console.log('res',res.data);
    //                 this.dictUpdate('video', res.data);
    //         }).catch(err => {
    //                 // console.log(err);
    //         });
             
    //     } else {
    //        alert('not a video...');
    //     };
    // };
    async updateVideo(e) {
        await this.setState({ file : e.target.files[0] }); 
        // console.log('updateVideo2: '); 
        this.readAndUploadCurrentChunk();
        // console.log('after: '); 
        // await this.setState({ tt : Date.now() }); 
    
    
    
    }
    
    readAndUploadCurrentChunk(){  
        const chunkSize = this.state.chunkSize ;
        const reader = new FileReader();
        const file = this.state.file; 
        const from = this.state.currentChunkIndex * chunkSize;
        const to = from + chunkSize;
        const blob = file.slice(from, to);
        reader.onload = ev => this.uploadChunk(ev);
        reader.readAsDataURL(blob);  
        // console.log('ednd: '); 
    
    } 
    uploadChunk(readerEvent) {
        // console.log('readerEvent: '); 
        const chunkSize = this.state.chunkSize ;
    
    
        const file = this.state.file;  
        const currentChunkIndex = this.state.currentChunkIndex ;
        const data = readerEvent.target.result;
        // console.log('data: ', data); 
        // console.log('totalChunks: ', Math.ceil(file.size / chunkSize)); 
    
        const params = new URLSearchParams();
        params.set('name', file.name);
        params.set('size', file.size);
        params.set('currentChunkIndex', currentChunkIndex);
        params.set('totalChunks', Math.ceil(file.size / chunkSize));
        const headersst = {'Content-Type': 'application/octet-stream'};
        const url = '/courses/uploadvidstream?'+params.toString();
        axios.post(url, {data:data}, {headersst})
          .then(async response => { 
            const filesize = file.size;
            const chunks = Math.ceil(filesize / chunkSize) - 1;
            const isLastChunk = currentChunkIndex === chunks  ;
            // console.log('response: '); 
    
            if (isLastChunk) {
            // console.log('response: ', response.data); 
    
            //   file.finalFilename = response.data.finalFilename;
                if (!response.data) { 
                } else {
                    this.dictUpdate('video', response.data);
                }
                
                this.setState({ currentChunkIndex: 0 });  
                this.setState({ file: '' });   
                this.setState({ percent : 0 });
     
    
            } else { 
            // console.log('else: '); 
    
                await this.setState({ currentChunkIndex : currentChunkIndex + 1 });  
                let percent = (currentChunkIndex / chunks) * 100
                await this.setState({ percent : percent });  
                this.readAndUploadCurrentChunk();
            }
          });
      }
    async changeTab(tab) {
        await this.setState({ tab: tab });
        if ( tab=='quiz' ) {
            this.updateChecks(); 
            try {
                this.setState({ quizrequire: this.state.content.quiz[0].require  }) ; 
            } catch {}
            
        }
    };

    async goSaveContent(e) {
        e.preventDefault(); 
        await this.setState({ needit: false })
        // console.log('inedit: ', typeof this.state.content)
        this.props.conentUpdate(this.state.content);
        this.props.changePage('lessons');

    };
    updateChecks() { 
        this.state.content.quiz.forEach((questiondic,index) => {
            questiondic.answers.forEach((answerdict,indexx) => {
                if ( answerdict.correct ) {
                    let check = document.querySelectorAll(`.check-${index}-${indexx}`)[0];
                    check.checked = true
                }
            });
        }); 
    };
    handleChecks(index,indexx) {
        // let monthly = document.querySelectorAll('.checkbox-monthly')[0];  
            // monthly.checked = false S
        this.state.content.quiz[index].answers.forEach((answer,indx) => { 
            if (indx!==indexx) { 
                let check = document.querySelectorAll(`.check-${index}-${indx}`)[0];
                check.checked = false
            }  
        }); 
    this.updateCheck(index,indexx);
        // this.dictUpdate('status','monthlyfutures');   
    }; 
    updateCheck(index,indexx) { 
        let content = this.state.content;
        let correct = content.quiz[index].answers[indexx].correct;
        
        this.state.content.quiz[index].answers.forEach((answer,indx) => { 
            if (!correct) {  
                 if (indx===indexx) {
                    content.quiz[index].answers[indx].correct = true
                 } else {
                    content.quiz[index].answers[indx].correct = false
                 }
            }  else if (indx===indexx) {
                content.quiz[index].answers[indx].correct = false
            }
        }); 
        // let content = this.state.content; 
        // content.quiz[index].answers[indexx].answer = e.target.value
        this.setState({ content: content})  
    };
    updateQuizQuesE(index,e) {
        let content = this.state.content; 
        content.quiz[index].question = e.target.value
        this.setState({ content: content})  
    };
    updateAnswer(index,indexx,e) {
        let content = this.state.content; 
        content.quiz[index].answers[indexx].answer = e.target.value
        this.setState({ content: content}) 
    };
    addQuestion() {
        let content = this.state.content;
        let newquestion = { question: '', answers: [{ correct: false, answer: '' }] };
        content.quiz.push(newquestion);
        this.setState({ content: content });
    };
    addAnswer(index) {
        let content = this.state.content;
        let newanswer = { correct: false, answer: '' };
        content.quiz[index].answers.push(newanswer);
        this.setState({ content: content });
    };
    addWork() {
        let content = this.state.content;
        let newwork = { question: '', placeholder: ''};
        content.worksheet.questions.push(newwork);
        this.setState({ content: content });
    };
    async deleteWork(index) {
        let content = this.state.content; 
        content.worksheet.questions.splice(index, 1);
        await this.setState({ content: content });
        if ( content.worksheet.questions.length === 0 ) {
            this.addWork();
        };
    };
    async deleteQuestion(index) {
        let content = this.state.content; 
        content.quiz.splice(index, 1); 
        await this.setState({ content: content });
        if ( content.quiz.length === 0 ) {
            this.addQuestion();
        };
    };
    async deleteAnswer(index,indexx) {
        let content = this.state.content; 
        content.quiz[index].answers.splice(indexx, 1);
        await this.setState({ content: content });
        if ( content.quiz[index].answers.length === 0 ) {
            this.addAnswer(index);
        };
    };
    deleteFile() {
        let content = this.state.content; 
        this.goDeleteFile('file',content.download);
        content.download = '';
        this.setState({ content: content });  
    };
    deleteVideo() { 
        let content = this.state.content; 
        this.goDeleteFile('video',content.video); 
        content['video'] = '';
        this.setState({ content: content}); 
    };
    async goDeleteFile(type,filename) {
        axios.delete('/courses/deletefile', {params: {type: type, file: filename } } ) 
    };
    async saveFile(file) {
        let formData = new FormData();
        formData.append('file', file ); 
        let res =  await axios.post('/courses/fileupload', formData) 

        let content = this.state.content; 
        content.download = res.data;
        this.setState({ content: content }); 
    };
    async componentDidMount() {   
        // console.log('before:', this.props.lesson.content );
        // await this.setState({ needit: false })

        await this.setState({ content: this.props.lesson.content }); 
        this.setState({ needit: true })

        // this.updateChecks();
        // console.log('after:', this.props.lesson);
    }
    render() { 
      
      
        return(    <div>  
 
 
<div className='column-body'>
<div className="header-home">
< input className="btn-blue" type="submit" value="Go Back" onClick={(e)=>{ this.goSaveContent(e); }} />
    <h1>Edit Lessons</h1> 
    <div>
        {/* <input className="btn-blue" type="submit" value="View Course" /> */}
        <input className="btn-blue" type="submit" value="Save"  onClick={(e)=>{ this.goSaveContent(e); }}/>
    </div>
</div>
<div className="header-home-detail"> 
                {/* <button className='rbtn-blue'onClick={()=>{ this.nick(); }} >NICK XoX</button>  */}
    <input className= {this.state.tab=='details' ? 'btn-clear-active' : 'btn-clear'}
        onClick= {()=>{this.changeTab('details')}}
        type="submit" value="Details" />
        
    <input className= {this.state.tab=='video' ? 'btn-clear-active' : 'btn-clear'}
        onClick= {()=>{this.changeTab('video')}}
        type="submit" value="Video" />

    <input className= {this.state.tab=='quiz' ? 'btn-clear-active' : 'btn-clear'}
        onClick= {()=>{this.changeTab('quiz')}}
        type="submit" value="Quiz" />

    <input className= {this.state.tab=='worksheet' ? 'btn-clear-active' : 'btn-clear'}
        onClick= {()=>{this.changeTab('worksheet')}}
        type="submit" value="Worksheet" />

    <input className= {this.state.tab=='download' ? 'btn-clear-active' : 'btn-clear'}
        onClick= {()=>{this.changeTab('download')}}
        type="submit" value="Download" />
</div>

<div className='cnt-create'> 

{ this.state.tab=='details' ? 
        <>
            <div className='ctn-create-input'>
                Lesson Title
                    <input  type="text"  autoComplete="on"
                        onChange={ (e)=>{ this.props.updateTitle(e.target.value); } }
                        value={ this.props.lesson.title } 
                    />
                
            
            Content Builder 
                <textarea  className='txta-descrip' autoComplete="on" autoFocus
                    onChange={(e) => { this.dictUpdateEvent(e); }}  
                    value={ this.state.content.builder } 
                
                />
            </div>
        </>  :
this.state.tab=='video' ? 
    <> 
        <div className='ctn-lesson-video'> 
            { this.state.needit && this.state.content.video == '' ? <>
                <input   type='file' onChange={(e)=>{ this.updateVideo(e); }}/>
                <div className='rand-progressbar-vidupload-calls'>
                <div className='rand-progressbar-vidupload-calls-perc' 
                    style={{width: `${this.state.percent}%` }}> 
                </div>
            </div>
            </> : <> 

                <div className='cnt-delete-file' >
                    <Link>
                        <IoClose className='img-x' onClick={()=>{ this.deleteVideo() }} />
                    </Link> 
                    <h2>{ this.state.content.video }</h2>
                </div>
                <div className='vid-lesson-video'> 
                    {/* <ReactPlayer url={ `/uploads/videos/${this.state.content.video}` } 
                        width='100%' height='98%' controls={true}   
                        config={{ file: { 
                            attributes: {
                              controlsList: 'nodownload'
                            }
                          }}}
                    />  */}
                    <video id='autovideo' controls controlslist="nodownload"  width = '100%' height = '98%' 
                        autoPlay muted  
                    >
                        <source src={ `/uploads/videos/${this.state.content.video}` }     type='video/mp4'/>  
                    </video>
                </div>
            </> }
        
        </div>
      
    
      
            {/* { this.state.content.download ==='' ?
                <input  type='file' onChange={(e)=> { this.saveFile(e.target.files[0]) }} />
            : <div className='cnt-delete-file' >
                <Link>
                    <IoClose className='img-x' onClick={()=>{ this.deleteFile() }} />
                </Link> 
                <h2>{ this.state.content.download }</h2>

            </div> 
            }   */}
    
    </> :
this.state.tab=='quiz' ?  
    this.state.needit ? <> <div> 
        { this.state.content.quiz.map((question,index) => (
              
            <div className='cnt-quiz'> 
                <div className='cnt-unit-header'>
                    <p> Question # { index+1 } </p>      
                    
                    <div className='cnt-lesson-btns-edit'>    
                        <button className='rbtn-clear' 
                            onClick={()=>{ this.deleteQuestion(index); }} >   
                            <FiTrash2 className='img-delete2'/>  Delete
                        </button> 
                    </div>
                        
                </div>
                                                              
                <textarea autoComplete="on" value={this.state.content.quiz[index].question}
                    onChange={(e)=>{ this.updateQuizQuesE(index,e); }} />  
                
                <div className='cnt-answers'>
                    <div> Answers </div> 
                
                    { this.state.content.quiz[index].answers.map((answer,indexx)=>(
                        <div className='cnt-answer'>   
                            <input type='checkbox' className={`check-${index}-${indexx}`} 
                                onClick={()=>{ this.handleChecks(index,indexx); }} /> 

                            <input type="text" autoComplete="on" 
                                onChange={(e)=>{ this.updateAnswer(index,indexx,e); }}
                                value={this.state.content.quiz[index].answers[indexx].answer}  /> 
                            <Link>
                                <IoClose className='img-x' onClick={()=>{ this.deleteAnswer(index,indexx) }} />
                            </Link> 
                        </div>
                    ))} 

                </div>
                <div className='cnt-btn-answer'>
                    <button className='rbtn-blue' onClick={()=>{ this.addAnswer(index); }} >   
                        + Add new Answer
                    </button> 
                </div>  
            </div> 

        
        ))} 
        <div className='cnt-btn-question'>
            <button className='rbtn-blue' 
                onClick={()=>{ this.addQuestion(); }}> + Add New Question </button> 
        </div>
    </div> 
    <div className='ctn-create-side-quiz'>
    <div className='ctn-status id-top20'> Require Quiz
       
            { this.state.quizrequire ?
                <button className="rbtn-mblue" onClick={()=>{ this.changeQuizRequire(); }} >Required </button>           
            : 
                <button className="rbtn-mblue" onClick={()=>{ this.changeQuizRequire(); }}>Not Required </button>   
            }
        
    </div> 
    </div> 

    
    </>: null :
this.state.tab=='worksheet' ? 
    this.state.needit ?  <div> 
        <div>
            <div className='cnt-worksheet'>
                Worksheet Title
                <div>
                        <input type="text" autoComplete="on" 
                            onChange={(e)=>{ this.update2key('worksheet','title',e.target.value); }} 
                            value={this.state.content.worksheet.title} />
                </div>
            </div>

            { this.state.content.worksheet.questions.map((work,index)=>(
                <div className='cnt-worksheet'>
                    <div className='cnt-worksheet-question'>
                        <p> Question/ Prompt </p> 
                        <button className='rbtn-clear' onClick={()=>{ this.deleteWork(index); }}>   
                            <FiTrash2 className='img-delete2'/>  Delete
                        </button> 
                
                    </div>
                    <div>
                        <input type="text" autoComplete="on" 
                            onChange={(e)=>{ this.updateWorkQues(index,e.target.value); }} 
                            value={this.state.content.worksheet.questions[index].question} />
                    </div>
                    <div className='txt-answer'>
                        Answer/ Placeholder
                    </div>
                    <div>
                        <input type="text" autoComplete="on"
                            onChange={(e)=>{ this.updateWorkAnsw(index,e.target.value); }}
                            value={this.state.content.worksheet.questions[index].placeholder} />
                    </div>
                </div>
            ))}
    
            <div className='cnt-btn-question'>
                    <button className='rbtn-blue' 
                        onClick={()=>{ this.addWork(); }}> + Add New Question </button> 
            </div>
        </div> 
    </div> : null : 
this.state.tab=='download' ? 
    <>
        <div className='ctn-lesson-video'>
            { this.state.content.download ==='' ?
                <input  type='file' onChange={(e)=> { this.saveFile(e.target.files[0]) }} />
            : <div className='cnt-delete-file' >
                <Link>
                    <IoClose className='img-x' onClick={()=>{ this.deleteFile() }} />
                </Link> 
                <h2>{ this.state.content.download }</h2>

            </div> 
            }  
        </div>
    </> : null }
</div>

 
</div>

        </div> ) } }

