import axios from 'axios';
import React, { Component } from 'react';
import logo from '../content/images/star.png';
import { Link } from 'react-router-dom'; 

export default class Footer extends Component {

    constructor(props) { 
        super(props);
        // this.getfile = this.getfile.bind(this);   

        this.state = { 

        } 
    } 
        // this.setState({ selectedfile: e.target.files[0] });
//   e.preventDefault();


    render() {

        return(
            <div className='div-footer-main'>
                {/* <Link exact to='/contactus'className="lnk-contactus">
                    Contact Us
                </Link>
                <Link exact to='/contactus'className="lnk-contactus">
                    About Us
                </Link>
                <Link exact to='/contactus'className="lnk-contactus">
                    About Us
                </Link> */}
                <div className='f1'>
                    About Us
                </div>
                <div className='f2'>
                    <t className='footer-title'>  LEARN MORE  </t>
                    <Link exact to='/placeholder' className='foot-links'>
                        Courses
                    </Link>
                    <Link exact to='/placeholder' className='foot-links'>
                        Memberships
                    </Link>
                    {/* <Link exact to='/placeholder' className='foot-links'>
                        About Us
                    </Link>
                    <Link exact to='/placeholder' className='foot-links'>
                        Contact Us
                    </Link>   */}
                             
                </div>
                <div className='f3'>
                    <img src={logo} alt='Logo' className='footer-logo'
                        // onClick={(history) => { history.push('/profile');this.manualNavChange('login');}}  
                        // onDoubleClick={(history) => { history.push('/store/crypto'); this.manualNavChange('store'); }}
                        // onDragEnd={(history) => { history.push('/booking/thinkorswim');this.manualNavChange('booking'); }}   
                        // onMouseEnter={this.hideDropDown}
                    > 
                    </img>
                    {/* <t className='footer-title'> */}
                          Developed by XoX  
                    {/* </t> */}
                    
                </div>
                <div className='f4'>
                <t className='footer-title'>  ABOUT US  </t>
                    {/* <Link exact to='/placeholder' className='foot-links'>
                        Courses
                    </Link>
                    <Link exact to='/placeholder' className='foot-links'>
                        Memberships
                    </Link> */}
                    <Link exact to='/placeholder' className='foot-links'>
                        About Us
                    </Link>
                    <Link exact to='/placeholder' className='foot-links'>
                        Contact Us
                    </Link>  
                </div>
                <div className='f5'>
                    About Us
                </div>
                
            </div>
        )

    }

}