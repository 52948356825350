import React, { Component } from 'react';  
// import axios from 'axios';   

export default class Terms extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {  
            nick: 222
} }/////////////////////////////////////////////////////////////////////////////
 
componentDidMount() {    
};

render() { return(<div> {/*//////////////////////////////////////////////////*/}

<div className='wrap-center-page'>


<h3>Terms and Conditions</h3>
<h4>
I. General Disclaimer</h4>
<p><strong>THERE IS A VERY HIGH DEGREE OF RISK INVOLVED IN TRADING.</strong></p>
<p>ANY AND ALL MATERIAL OFFERED BY TRADER’S ULTIMATE EDGE AND IT’S INDIVIDUAL OWNERS AND INSTRUCTORS IS OFFERED FOR EDUCATIONAL AND GENERAL INFORMATIONAL PURPOSES ONLY. NO INVESTMENT ADVICE OFFERED. WE ARE NOT A FINANCIAL ADVISOR, SECURITIES BROKER-DEALER OR REGISTERED ANALYST.</p>
<p>We (Labrador Trading, LLP dba “Trader’s Ultimate Edge,” referred to as simply “Trader’s Ultimate Edge”) provide online information courses, workshops, classes and other educational programs relating to investing and trading. We are an educational service ONLY. We do not provide investment advice. Pursuant to FTC regulations and federal law, Trader’s Ultimate Edge intends for all information to be truthful and not misleading. Accordingly, any and all readers of this disclaimer are fully informed that none of the information, material, or courses that Trader’s Ultimate Edge offers constitute investment advice as defined by the SEC and by federal law.</p>
<p>These materials DO NOT take into account your particular investment objectives, financial situations or needs and is not intended as a recommendation, offer or solicitation for the purchase or sale of any security or investment strategy. We do not ask for, nor do we want the personal details of your financial situation. Individuals that offer such information to us may have their Membership terminated at our in order to protect ourselves from Federal regulations.</p>
<p>Under SEC regulations and federal law, purchase, sale, or advice regarding any security, other financial instrument or system can only be performed by a registered/licensed industry representative such as, but not limited to, a registered investment advisor. WE ARE NOT A REGISTERED INVESTMENT ADVISOR. It is very important to do your own analysis before making any investment based on your own personal circumstances. Investing and trading involve substantial risk and results are not guaranteed. Neither Trader’s Ultimate Edge, Inc nor its principals or affiliates are registered investment, legal, or tax advisors or broker/dealers. Trader’s Ultimate Edge, Inc is not registered with the SEC or licensed as an investment adviser. We do not offer investment or financial advice. Individual subscribers (this means you, the reader) are solely responsible for confirming the accuracy and appropriateness of the provided educational information for their own uses with their personal tax, finance, or legal advisor.</p>
<p><strong>INVESTING INVOLVES RISK, INCLUDING THE POSSIBLE LOSS OF PRINCIPAL. PAST PERFORMANCE IS NO GUARANTEE OF FUTURE RESULTS. RESULTS ARE NOT GUARANTEED.</strong></p>
<h4>
II. Educational Purposes</h4>
<p>We (“Trader’s Ultimate Edge”) are a provider of educational products that we believe will assist you in trading and investing profitably by increasing your knowledge in these topics and showing you how Trader’s Ultimate Edge’s principals and instructors apply the knowledge to the financial markets. We do not provide any guarantees, warranties or assurances that by using our educational products, you will be successful in trading or investing profitably. The information contained on our website or in any product or service provided by us does not constitute financial advice or a solicitation to buy or sell any futures, currencies, including cryptocurrency, options, or securities. We are not an investment advisor of any sort, we are educators. We do not make investment recommendations and we do not trade anything for our customers. We do not hold customer funds for trading commodity futures or any other investment. <strong>You agree that you will not hold us responsible for any losses you may incur while relying upon our educational products.</strong></p>
<h4>
III. Risk Disclosure Statement</h4>
<p>The risk of loss in trading commodity futures contracts can be substantial. You should, therefore, carefully consider whether such trading is suitable for you considering your circumstances and financial resources. ONLY YOU CAN MAKE THAT DECISION, WE CANNOT PROVIDE ADVICE ON THIS MATTER. Under certain market conditions, you may find it difficult or impossible to liquidate a position.</p>
<p>The high degree of leverage that is often obtainable in futures and options trading because of the small margin requirements can work against you as well as for you. Leverage can lead to large losses as well as gains. You may sustain a total loss of the funds that you deposit with your broker to establish or maintain a position in the commodity futures market, and you may incur losses beyond these amounts.</p>
<p>Futures, options, and stock trading has large potential rewards, but also large potential risk. You must be aware of the risks and be willing to accept them in order to invest in the futures, options or stock markets. Don’t trade with money you can’t afford to lose. This website is neither a solicitation nor an offer to Buy/Sell futures, options or stocks. No representation is being made that any account will or is likely to achieve profits or losses similar to those discussed by our Trader’s Ultimate Edge, Inc, principals, or instructors. The past performance of any trading system or methodology is not necessarily indicative of future results.</p>
<p>CFTC RULE 4.41 – HYPOTHETICAL OR SIMULATED PERFORMANCE RESULTS HAVE CERTAIN LIMITATIONS. UNLIKE AN ACTUAL PERFORMANCE RECORD, SIMULATED RESULTS DO NOT REPRESENT ACTUAL TRADING. ALSO, SINCE THE TRADES HAVE NOT BEEN EXECUTED, THE RESULTS MAY HAVE UNDER-OR-OVER COMPENSATED FOR THE IMPACT, IF ANY, OF CERTAIN MARKET FACTORS, SUCH AS LACK OF LIQUIDITY, SIMULATED TRADING PROGRAMS IN GENERAL ARE ALSO SUBJECT TO THE FACT THAT THEY ARE DESIGNED WITH THE BENEFIT OF HINDSIGHT. NO REPRESENTATION IS BEING MADE THAT ANY ACCOUNT WILL OR IS LIKELY TO ACHIEVE PROFIT OR LOSSES SIMILAR TO THOSE SHOWN.</p>
<p>By accessing our Content, you acknowledge the risks involved in trading the stock, options, futures and cryptocurrency markets and acknowledge that you, the user, are solely responsible for any losses, financial or otherwise, as a result of using this Content. Trader’s Ultimate Edge cannot and does not assess, verify or guarantee the adequacy, accuracy or completeness of any information, the suitability or profitability of any particular investment, or the potential value of any investment or informational source. You are responsible for conducting your own investment research and decisions. We in no way guarantee the solvency, financial condition, or investment advisability of any of the securities mentioned on our live streams, discord, web Content, social media, or any other method of communication used by the Trader’s Ultimate Edge, Inc.</p>
<h4>
IV. Testimonial Disclosure</h4>
<p>We often include testimonials from previous customers who have used our educational products. You should be aware that:<br/>
• All testimonials are real clients that we may or may not have asked to provide the testimonial.<br/>
• The testimonial may not be representative of the experience of other clients.<br/>
• The testimonial is no guarantee of future performance or success.<br/>
• We do not pay or provide any compensation to any person who has provided a testimonial.</p>
<h4>

V. No Warranty or Returns</h4>
<p>For purposes of this Terms and Conditions “Content” is defined as all intellectual property, courses, proprietary code and indicators, Discord access, and anything else not listed here that is the sole property of Trader’s Ultimate Edge and it’s principals.</p>
<p>We provide this Content on a commercially reasonable basis and do not guarantee that you will be able to access or use our Content at times or locations of your choosing, or that we will have adequate capacity for the service as a whole or in any specific geographical location.</p>
<p>To the fullest extent permitted by law, our Content is provided on an “as is” and “as available” basis, for use at your own risk. To the fullest extent permitted by law, we disclaim all warranties, representations and conditions, either express or implied, including without limitation implied warranties of merchantability, non-infringement or fitness for a particular purpose, in connection with the Content provided with which it is linked. We do not warrant that the Content will be uninterrupted or secure, that it will be available at any particular time, free of inaccuracies, errors, omissions, viruses or other harmful components, or will be corrected if found to be defective. We reserve the right to modify and/or discontinue our Content, or access thereto, at any time without notice. To the extent that you communicate with us through any source, the statements and<br/>
promises made or actions taken by them shall not limit or otherwise modify the terms of this disclaimer and/or this Agreement and this disclaimer and this Agreement apply to any information provided to you through such sources. We do not warrant that any of our Content will work with any particular hardware or software systems or configurations.</p>
<p>Trader’s Ultimate Edge shall not be liable for any delay or failure to perform resulting from causes outside the reasonable control of our Trader’s Ultimate Edge, Inc or principals, including without limitation acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.</p>
<p>Due to the nature of the content provided, that is that it is entirely immediately available, purchases of curriculum are <strong>NOT REFUNDABLE. </strong>This means that any purchase of the Basics course cannot be refunded, and $1000 worth of the initial payment (constituting the cost of the Basics course) for the Day Trader or Dynamic Income pathways cannot be refunded.</p>
<p>Some jurisdictions may not allow disclaimers of implied warranties and the above disclaimers may not apply to you to the extent prohibited.</p>
<h4>VI. Memberships</h4>
<p>Once you are an active member, your account will be renewed either monthly or for the appropriate renewal period, depending on your Membership. If you wish to cancel your Membership subscription, you may do so by contacting Anthony Garcia at anthony@tradersultimateedge.com or support@tradersultimateedge.com. Your account will be cancelled as of the moment you send your cancellation email, as well as all access to Content. There are no per-rata refunds on the remainder of the monthly, quarterly or annual subscriptions. There are no refunds or credits for partial monthly subscriptions. If Trader’s Ultimate Edge increases the Membership Fee you will be provided notice. By continuing to use your Membership you accept the new price, and the price change will take effect at the start of the next subscription period following the date of the price change.</p>
<p>By subscribing to a Membership, you agree to the following (“Membership Rules”):<br/>
• You will not share your username and/or password with any other party.<br/>
• You will not reprint, republish, repost, or otherwise distribute or transmit Content or images presented by Trader’s Ultimate Edge without prior permission from Trader’s Ultimate Edge principal Anthony Garcia.<br/>
• You will not advertise, solicit members, trade goods or services or otherwise use or attempt to use our Discord or live video rooms for commercial purposes. Bulk-email, junk mail or spam, chain letters, private messages or repeat postings of the same message is not permitted in the Discord or live video rooms. Soliciting of members is strictly prohibited and will result in immediate termination of your Membership, without refund.<br/>
• You will use your own name when posting to Discord and live video rooms and will not impersonate or attempt to impersonate any other person(s) or entity.<br/>
• You will treat other subscribers with courtesy and respect when posting messages to the Discord group. This includes, but is not limited to, refraining from vulgarity, obscenity, profanity, ethnic slurs, hate speech, or sexually explicit language that is targeted at harassing, abusing or threatening other subscribers. We has the right to remove any messages or posts we deem, in our sole discretion, to be abusive, derogatory, inappropriate or adverse to the policies defined in these Terms and Conditions.<br/>
• By posting Content or speaking/appearing on video (you will always be informed a recording is in progress, and should always assume video calls are recorded), you permit Trader’s Ultimate Edge to display and distribute the Content and use it for advertising and promotion. You grant Trader’s Ultimate Edge the complete, perpetual, non-exclusive right to use, modify, adapt, translate, distribute, sub-license or in any way use that Content in whole or part, throughout the world, on a royalty-free basis.</p>
<p>Failure to abide by these Membership Rules can result in you being banned from Trader’s Ultimate Edge, your account being suspended or terminated completely, and even civil or criminal liability. We may, at our discretion, terminate your<br/>
Membership for any reason or no reason. If we terminate your Membership, we will provide notice to you through the email address associated with your account.</p>
<p>Memberships may be canceled at any time by either party. If you wish to cancel, you may in one of the following ways:</p>
<p>Email:<br/>
anthony@tradersultimateedge.com or support@tradersultimateedge.com.</p>
<p>Cancellation of your Membership is not official until you receive an email confirmation from Trader’s Ultimate Edge.</p>
<h4>
VII. Seminars/Webinars</h4>
<p>In addition to free Content and Memberships, Trader’s Ultimate Edge provides Seminars for free and for purchase. Seminars can include, but are not limited to, classes, live seminars, and webinars. Customers who purchase live Seminars are provided with a link of the recording and any materials, if applicable, following the seminar. There are no refunds on the purchase of seminars.</p>
<p>You cannot reprint, republish, repost, or otherwise distribute or transmit Content or images presented by Trader’s Ultimate Edge in these seminars and webinars without prior permission from Trader’s Ultimate Edge principal Anthony Garcia.</p>
<h4>
VIII. Changes to Terms and Conditions</h4>
<p>You understand that Trader’s Ultimate Edge is an ever evolving company. We may require that you accept updates to our Content and these Terms and Conditions. We reserve the right to change, modify, add or remove portions of this terms and conditions, at any time, for any reason, with or without notice to you, or to do any of the following:</p>
<p>• Modify, suspend, limit or terminate operation of, or access to, any portion of any feature or function of the Content or your Membership and/or any of its applicable policies or terms, including hours of availability.<br/>
• Change any fees or charges that may be related to your use of the Content and Membership.<br/>
• Change the equipment, hardware or software required to access the Content.<br/>
• Interrupt the Content, or any portion of the Content, to perform routine or non-routine maintenance, error correction or other changes.</p>
<p>Any changes to the terms and conditions will be effective immediately upon notice, which Trader’s Ultimate Edge may provide by any means, including, without limitation, by electronic posting, which will be effective immediately.</p>
<p>You agree to check this terms and conditions periodically for the new provisions that govern the Trader’s Ultimate Edge, your Membership, and the Content. Your continued use of the Content following the posting of changes will mean that you accept and agree to them.</p>
<h4>
IX. Privacy and Communications (Privacy Policy)</h4>
<p>Your privacy is very important to us. We designed this Privacy Policy to make important disclosures about how we use your personal information. We encourage you to read this Privacy Policy, and to use it to help you make informed decisions. By agreeing to this Terms and Conditions, you are also agreeing to the terms and conditions of this Privacy Policy.</p>
<p>You consent to allow electronic communications from the Trader’s Ultimate Edge, including any information or notices that the Trader’s Ultimate Edge may be required by law to send to you or that may pertain to this Terms and Conditions or your access or use of the Content. We may provide such communications to you: (1) via e-mail at the e-mail address you designated to us; (2) via “push notifications” to Your mobile device; (3) by access to the Content that will generally be designated in advance for such purpose or designated in an e-mail notice to you; (4) any messaging or chat application for computer or mobile device of your choosing; or (5) in the course of your use of any of our Content at any point in the future.</p>
<p>You certify, warrant and represent that the telephone numbers that you have provided to us are your contact numbers. You represent that you are permitted to receive calls at each of the telephone numbers you have provided to us. You agree to promptly alert us whenever you stop using a particular telephone number. Your cellular or mobile telephone provider will charge you according to the type of plan you carry. You also agree that we may contact you by e-mail, using any email address you have provided to us or that you provide to us in the future. We may listen to and/or record phone calls between you and our representatives without notice to you as permitted by applicable law. For example, we may listen to and record calls for quality monitoring purposes.</p>
<h4>
X. Intellectual Property</h4>
<p>All Content offered by Trader’s Ultimate Edge, including, but not limited to, the free and paid materials, courses, educational material, seminars, proprietary indicators, software, any resources you receive through a representative of Trader’s Ultimate Edge, and all logos, slogans, and taglines (collectively and individually, the “Content”), are protected by trademark and copyright laws.</p>
<p>You may not modify, publish, transmit, transfer, sell, reproduce, upload, post, perform, display, create derivative works from, or in any way exploit such Content or distribute it in any way to any other computer, server, website or other medium for publication or distribution or for any commercial purpose, except as expressly permitted in this Terms and Conditions. Making unauthorized copies of the Content may result in the termination of your Membership, prohibition to use the Content, and further legal action.</p>
<h4>
XI. CAN-SPAM Compliant</h4>
<p>Trader’s Ultimate Edge is CAN-SPAM compliant, which allows for you to request we stop sending you emails. If you have any issues unsubscribing from our emails, please contact us at support@tradersultimateedge.com.</p>
<h4>
XII. Indemnification</h4>
<p>You agree to indemnify, defend and hold Labrador Trading, LLP dba Trader’s Ultimate Edge, its subsidiaries, affiliates, officers, principals, contractors, instructors, and employees harmless from any claim or demand, including reasonable attorneys’ and experts’ fees and costs, arising out of your use of this Content, including information made available to you through this Content or the violation of this Terms and Conditions, including any breach of your covenants or agreements hereunder. In addition, you agree to indemnify, defend and hold Trader’s Ultimate Edge, its subsidiaries, affiliates, officers, principals, contractors, instructors, and employees harmless for any act resulting directly or indirectly from this Content, its data, materials, associated pages and documents.</p>
<h4>
XII. Limitation of Liability</h4>
<p>You expressly understand and agree that, to the full extent permitted by applicable law, Trader’s Ultimate Edge is not liable for damages, losses and expenses of whatever nature and however arising, including without limitation direct or indirect, special, incidental, consequential, exemplary or punitive damages, losses or expenses, including but not limited to, damages for loss of profits, loss of investments, goodwill, use, data or other intangible loss, arising in connection with this Content or use thereof or inability to use by any party, or in connection with any failure or performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line of system failure, even if Trader’s Ultimate Edge, Inc or its representatives are advised of the possibility of such damages, losses or expenses.</p>
<p>Access hyperlinks to or from other internet resources at your own risk and it is your responsibility to take all protective measures to guard against viruses or other destructive elements; the Content, accuracy, opinions expressed and other links provided by these resources are not endorsed by Trader’s Ultimate Edge, Inc. This limitation of liability applies whether the alleged liability is based on contract, negligence, tort, strict liability, or any other basis. Your sole remedy for dissatisfaction with the Content and Trader’s Ultimate Edge is to stop using the Content.</p>
<p>In the event that the foregoing disclaimer of liability is held to be invalid or unenforceable, you agree that the maximum cumulative aggregate liability of Labrador Trading LLP dba Trader’s Ultimate Edge to you for all damages, losses, and causes of action (whether in contract, tort (including, without limitation, negligence and strict liability), or otherwise) at any time shall be the lesser of $100 or the aggregate cumulative amount paid by you to Trader’s Ultimate Edge up to and including the time of the incurrence of such liability, if any, to access the Content. You agree that any cause of action arising out of or related to the Content must commence within one (1) year after the cause of action accrues or the cause of action is permanently barred.</p>
<h4>
XIII. Termination and Survival</h4>
<p>Any obligations which expressly or by their nature are to continue after termination, cancellation, or expiration of these Terms and Conditions, including but not limited to those addressing privacy and Content ownership, shall survive and remain in effect after such event. Furthermore, the following sections of these Terms and Conditions shall survive any termination or discontinuance of the Content or your access to it: Intellectual Property, Indemnification, Limitation of Liability, Privacy (and the Privacy Policy). If you wish to terminate your Membership, you may do so by following the instructions in VI. Memberships.</p>
<h4>
XIV. Governing Jurisdiction</h4>
<p>Trader’s Ultimate Edge administers and operates all content from Colorado. The Federal Arbitration Act, applicable federal law, and the laws of the state of Colorado, without regard to principles of conflict of laws, will govern this Terms and Conditions and any dispute of any sort that might arise between you and Trader’s Ultimate Edge.</p>
<h4>
XVI. Binding Individual Arbitration Agreement and Waiver of Jury Trial</h4>
<p>You agree that any dispute or claim, no matter how described, pleaded, or styled, between You and Trader’s Ultimate Edge (including all past or present parents, affiliates, officers, or employees) arising out of, relating to, under, or in connection with (i) the Terms and Conditions, (ii) the Content or access to or use of the Content, or (iii) the Privacy Policy, shall be resolved by binding, confidential, individual arbitration before a single, neutral arbitrator under the Federal Arbitration Act (“FAA”) conducted by the American Arbitration Association (“AAA”) under its Consumer Arbitration Rules. The FAA (including all its procedural and substantive provisions) and related federal decisional law shall govern this provision to the fullest extent possible.</p>
<p>There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages), and must follow the Terms and Conditions as a court would. Except as specifically required by applicable law, the fact of and all aspects of the arbitration and the underlying claim shall remain strictly confidential by you and Trader’s Ultimate Edge, our respective representatives, the arbitrator, and the arbitral forum.</p>
<p>The AAA’s Consumer Arbitration Rules and related forms and filing instructions are available at www.adr.org or by calling 1-800-778-7879. Payment of all filing, administration, and arbitrator fees will be governed by the AAA’s rules.</p>
<p>You and Trader’s Ultimate Edge each agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. Further, both you and Trader’s Ultimate Edge agree that the arbitrator shall have no authority to join or consolidate any dispute or claim asserted by more than one person. You and Trader’s Ultimate Edge, Inc agree that the arbitrator shall have the power to rule on his or her own jurisdiction, including any objections with respect to the existence, scope, or validity of the arbitration agreement or to the arbitrability of any claim or counterclaim. You and Trader’s Ultimate Edge further agree that the arbitrator shall have the authority to award attorneys’ fees and costs to the prevailing party should the arbitrator determine that the asserted claims are frivolous.</p>
<p>Notwithstanding anything to the contrary in this provision, you and Trader’s Ultimate Edge agree that you or Trader’s Ultimate Edge may bring suit in court to seek injunctive relief, including seeking to enjoin infringement or other misuse of intellectual property rights or to seek injunctive relief in aid of an arbitrator’s award.</p>
<p>Except for the right to seek injunctive relief or relief in a small claims court as provided in this provision, neither you nor Trader’s Ultimate Edge shall file an action in any court against the other, and any such action filed in violation of this provision shall be dismissed in favor of arbitration. You and Trader’s Ultimate Edge both recognize and agree that such a breach of this Terms and Conditions will cause the other damage including, but not limited to, attorneys’ fees and costs incurred in compelling arbitration, which the breaching party will be liable to pay.</p>
<p>If for any reason a claim proceeds in court rather than in arbitration, you and Trader’s Ultimate Edge each knowingly, voluntarily and intentionally waive any right which they may have to a trial by jury in respect to any litigation (including but not limited to any claims, counterclaims, crossclaims and third-party claims) arising out of, relating to, under, or in connection with (i) the Terms and Conditions, (ii) the Content or access to or use of the Content, or (iii) the Privacy Policy.</p>
<h4>
XVII. Entire Agreement</h4>
<p>This “Agreement” (including all Terms and Conditions and policies referenced herein) constitutes the entire agreement between you and Trader’s Ultimate Edge with regard to your use of the Content, and any and all other written or oral agreements or understandings previously existing between you and Trader’s Ultimate Edge with respect to such use are hereby superseded and cancelled.</p>
<p>Trader’s Ultimate Edge, Inc will not accept any counteroffers to this Agreement, and all such offers are hereby categorically rejected. Your use of the Content is not intended and shall not be deemed to create any agency, joint venture, or other legal relationship of any kind between you and Trader’s Ultimate Edge, Inc other than that of independent contractors. This Agreement may not be assigned by you.</p>
<p>Trader’s Ultimate Edge failure to insist on or enforce strict performance of this Agreement shall not be construed as a waiver by Trader’s Ultimate Edge of any provision or any right it has to enforce this Agreement, nor shall any course of conduct between Trader’s Ultimate Edge and you or any other party be deemed to modify any provision of this Agreement. This Agreement shall not be interpreted or construed to confer any rights or remedies on any third parties.</p>
<p>If any provision of this Agreement is found to be illegal, void or unenforceable, then:(i) such provision (or portion thereof as applicable) will be deemed to be restated to reflect as nearly as possible the original intentions of the parties in accordance with applicable law, and (ii) the remaining terms, provisions, covenants and restrictions of this Agreement will remain in full force and effect. Notwithstanding the foregoing, if any provision of this Agreement which is held null, void or otherwise ineffective or invalid by a court of competent jurisdiction cannot be restated by such court to reflect as nearly as possible the original intentions of the parties, then that provision shall be deemed severable from this Agreement.</p>
<p>This Terms and Conditions last revised Oct 12th, 2021. Version 1.1.</p>
<p>Version 1.1 changes: Spelling, grammar, formatting.</p>

<div className='hidden-50' ></div>
<button className="rbtn-blue" onClick={()=>{ 
    this.props.backTab('back_to_signup');
    this.props.changeTerms();
} }> Agree to Terms & Conditions </button>

</div>



</div> ) }}/////////////////////////////////////////////////////////////////////

