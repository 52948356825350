import React, { Component  } from 'react'; 




import axios from 'axios'; 
 
// import XoX from './routes/Store/store';
// import XoX from './routes/Owner/l_followup';
// import XoX from './routes/Education/Helpers/edit';
// import XoX from './routes/Free/freewebinar2';  
// import XoX from './routes/Payment/_payment';   
// import OxO from './routes/Payment/wrapper';   
// import OvO from './routes/xox';  
// import OvO from './routes/Free/contactme';
// import OvO from './routes/Free/justvideo';  
import { ReactComponent as YourSvg } from './content/images/traders.svg';
import {
  BrowserRouter as Router,
  Switch,
  Route, 
  Link
} from 'react-router-dom';


import Home from './routes/home'; 
import Education from './routes/Education/education';
import Assignment from './routes/Education/assignment';
import Store from './routes/Store/store';
import Booking from './routes/Booking/booking';
import Blog from './routes/blog';
import Login from './routes/Profile/login';  
import SignUp from './routes/Profile/signup';
import Profile from './routes/Profile/profile';
import Forgot from './routes/Profile/forgot';
import Reset from './routes/Profile/reset';
import Footer from './routes/footer'; 
import Webinar from './routes/Free/webinar';
import Webinar2 from './routes/Free/freewebinar2'; 
import JustVideo from './routes/Free/justvideo';  
import Contactme from './routes/Free/contactme';
import Owner from './routes/Owner/_main';
import Payment from './routes/Payment/pay_wrapper';   
import Discord from './routes/Free/discord';



import { darkTheme } from './styles/themes.js';
  
//stripe
// import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
// import { loadStripe } from '@stripe/stripe-js'; 
// // const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY);   
// const stripePromise = loadStripe('pk_test_51K41WKCR7u9IW4592u4Z4Q5BtV8hNLDqtJVV3eljXOJjcChZhlpiYNcV9tqQjOvbuzsPsiRqWY8xRdDw4RgZBnrG00JoiRqKZC');  
// // "@stripe/react-stripe-js": "^1.7.0",
// // "@stripe/stripe-js": "^1.22.0", 

// const clearRequire = require('clear-require');
const theme = require('./styles/main.scss');
const themes = require('./styles/themes.js');
// require('./styles/main.scss')  ;
// clearRequire('./styles/main.scss');
 
export default class App extends Component {

  constructor(props) {

    super(props);
    // this.nick = this.nick.bind(this);  

    this.state = {
      activeNav: '',
      tempNav: 'placeholder',
      userstatus: false,
      username: '', 
      useremail: '',
      userfolder: '',
      userrole: '',
      userdisplay: '',  
      startdate: '',
      user: '',  //'',  xox {role:'asdas'}
      theme: false,
      hidenav: false,
      

    } 
  }
  
  // handleScroll = () => {  window.scrollY>0 ? 
  //   document.querySelector('.nav-container').className='nav-container active' :
  //   document.querySelector('.nav-container').className='nav-container'
  // } 

  changeColor() {
    let theme = localStorage.xoxtheme ;

    if ( theme === 'lightTheme' ) {
      themes.darkTheme(); 
      localStorage.xoxtheme = 'darkTheme' ;
 
    }  else {
      themes.lightTheme(); 
      localStorage.xoxtheme = 'lightTheme'  ; 
    }
   
  };


//   changeTheme() {   
//     if (!this.state.theme) {
//       console.log('infirst')
//       const theme = require('./styles/main.scss');
//     } else {
//       console.log('secondin')
//       // delete require.cache[require.resolve('./styles/main.scss')]
//       // theme = 'nick'
//       // const theme = unrequire('./styles/darkmain.scss');
// //       const disrequire = require('disrequire');   // now you see it
// // theme.quick('./styles/main.scss');   
//     }
   
//     this.setState({ theme: !this.state.theme });  
  // }
  activateNav = (e) => {   
    this.setState({ tempNav: e.target.className }); 
  }

  deactivateNav = () => { 
    this.setState({ tempNav: ''});  
  }

  changeNav = (e) => {  
    //checking to make sure the current class is active
    if (e.target.className.includes('active')) {}
    else {document.querySelector(`.${e.target.className}`).className=
      `${e.target.className} active`;}
    //deactives the last active navbar
    if (this.state.activeNav===''){}
    else if (this.state.activeNav===e.target.className){}
    else{ 
      let activeNav =this.state.activeNav.replace(' active','.active');
      let deactiveNav= this.state.activeNav.replace(' active',''); 
      document.querySelector(`.${activeNav}`).className=deactiveNav;
    } 
    // sets the new active nav bar
    this.setState({ activeNav: e.target.className}); 
  } 

  manualNavChange(page) {
    //deactives the last active navbar
    if (this.state.activeNav===''){} 
    else{ 
      let activeNav =this.state.activeNav.replace(' active','.active');
      let deactiveNav= this.state.activeNav.replace(' active',''); 
      document.querySelector(`.${activeNav}`).className=deactiveNav;
    } 
    // sets the new active nav bar
    document.querySelector(`.nav-${page}`).className=`nav-${page} active`;
    this.setState({ activeNav: `nav-${page} active`}); 

  } 

  onRefresh = (page) => { 
    if (page != 'subscribe' && page != 'free' && page != 'webinar') {
      document.querySelector(`.nav-${page}`).className=`nav-${page} active`;
      this.setState({ activeNav: `nav-${page} active`}); 
      this.setState({ hidenav: false });
    } else {
      this.setState({ hidenav: true });
    } 
  }

  hideDropDown = () => {
    this.setState({ tempNav: 'placeholder'});  
  }
  
  UselessLogo= (page) => { 
    document.querySelector(`.nav-${page}`).className=`nav-${page} active`;
    this.setState({ activeNav: `nav-${page} active`});  
  }
  
 async updateInfo(userinfo) {
    // console.log('user:', user);
    this.setState({ userstatus: userinfo.status });  
    this.setState({ username: userinfo.name });  
    await this.setState({ useremail: userinfo.email });  
    this.setState({ userfolder: userinfo.folder });   
    this.setState({ userrole: userinfo.role });  
    let displayname_ = `${userinfo.name.toLowerCase().split(" ")[0]}`;
    let displayname = displayname_.charAt(0).toUpperCase() + displayname_.slice(1); 
    this.setState({ userdisplay: displayname });  
    this.setState({ userstatedate: userinfo.startdate });  

    let user = {};
    user.status = userinfo.status ;
    user.name = userinfo.name ;
    user.email = userinfo.email ;
    user.folder = userinfo.folder ;
    user.role = userinfo.role ;
    user.display = displayname ;
    user.lastname = userinfo.lastname;
    user.startdate = userinfo.startdate;
    this.setState({ user: user }); 
 
  }

async  componentDidMount() { 

    var res = '';
     axios.get('/profile/login').then((res) =>{ 
      if (res.data.loggedIn){   
        // console.log('data: ', res.data);  
         this.updateInfo({
            status: true,
            name: res.data.user[0].name,
            email: res.data.user[0].email,
            folder: res.data.user[0].folder,
            role: res.data.user[0].role ,
            lastname: res.data.user[0].lastname  ,
            startdate: res.data.user[0].startdate    
          })
          // res = res.data.msg[0]
      }   
    });  
    // window.addEventListener('scroll', this.handleScroll); 
    // console.log('onrefresh: ');
    let theme = localStorage.xoxtheme ; 
    if ( theme === 'darkTheme' ) {
      themes.darkTheme();
    } else {
      themes.lightTheme();
    }
    // localStorage.clear();

    let urlpath=window.location.pathname  
    if (urlpath.includes('invest')) { this.onRefresh('invest'); }
    else if (urlpath.includes('education')) { this.onRefresh('education'); }
    else if (urlpath.includes('store')) { this.onRefresh('store'); }
    else if (urlpath.includes('booking')) { this.onRefresh('booking'); }
    else if (urlpath.includes('blog')) { this.onRefresh('blog'); }
    else if (urlpath.includes('login')) { this.onRefresh('login'); }
    else if (urlpath.includes('profile')) { this.onRefresh('login'); }
    else if (urlpath.includes('owner')) { this.onRefresh('owner'); }
    else if (urlpath.includes('subscribe')) { this.onRefresh('subscribe'); }
    else if (urlpath.includes('free')) { this.onRefresh('free'); }
    else if (urlpath.includes('webinar')) { this.onRefresh('webinar'); }
    else if (urlpath.includes('ovo')) { this.onRefresh('webinar'); }
    else if (urlpath.includes('contactme')) { this.onRefresh('webinar'); }
    else if (urlpath.includes('discord')) { this.onRefresh('webinar'); }
    else if (urlpath.includes('forgot_password')) { }
    else {this.onRefresh('education');} 


  
    // if ( res != '' ) {
    //   this.history.push('/education');
    // } else {
    //   this.history.push('/login'); 

    // }
  }
  
  render() {  
    
    const navLinks = [
      // {  content: 'Home' }, 
      { name: 'education', content: 'Education' },
      // { name: 'store', content: 'Store' },
      // { name: 'booking', content: 'Booking' },
      // { name: 'blog', content: 'Blog' }, 
      { name: 'login', content: this.state.userstatus ? this.state.userdisplay : 'Log In' }
    ]; 
    return (
      <div className='body'> 
        <div className='body-wrapper'> 
        <Router>  
          { !this.state.hidenav ? 
            <div className="nav-container"  > 
            { this.state.useremail === 'nick@starxox2.com' || this.state.useremail === 'anthony@tradersultimateedge.com' ?
                <Link exact to='/owner'
                className={`nav-owner`}
                onClick={this.changeNav}
                onMouseEnter={this.activateNav} 
                onMouseLeave={this.deactivateNav}>Owner</Link>
             : null  }
            {
                navLinks.map(link => (

                  <Link exact to={ 
                    // if logged switch login redirect to /profile
                      this.state.userstatus && link.name=='login' ? 
                      '/profile' : `/${link.name || ''}`}
                    className={`nav-${link.name || link.content.toLowerCase()}`}
                    onClick={this.changeNav}
                    onMouseEnter={this.activateNav} 
                    onMouseLeave={this.deactivateNav}>{link.content}</Link>
                ))
              }
              
         
              <Link className='logo-nav'>
              <Route render={({history})=>(<>
                  {/* //  <img src={logo} alt='Logo' 
                // // onClick={() => { history.push('/profile');this.manualNavChange('login');}}  
                // onClick={()=>{this.changeColor();}}
                // // onDoubleClick={() => { history.push('/store/crypto'); this.manualNavChange('store'); }}
                // // onDragEnd={() => { history.push('/booking/thinkorswim');this.manualNavChange('booking'); }}   
                // // onMouseEnter={this.hideDropDown}
                // ></img>  */}
                <YourSvg className='nav-logo' 
                  font-family= 'Comic Sans MS'
                  onClick={()=>{this.changeColor();}}
                />
                {/* <titlelogo>TRADER'S ULTIMATE EDGE</titlelogo> */}
              </>)}/>
              </Link>
            </div>
    :null}
          
          <div className='nav-redirect' > 
          {
            this.state.tempNav==='' ?
            this.state.activeNav==='nav-home' || this.state.activeNav==='nav-home active' ?   
              <h2> contactUs blog whyUS </h2> :  
            this.state.activeNav==='nav-invest' || this.state.activeNav==='nav-invest active' ?   
              <h2> join the fun </h2> :  
            this.state.activeNav==='nav-education' || this.state.activeNav==='nav-education active' ?   
              <h2> hosting custom </h2> : 
            this.state.activeNav==='nav-store' || this.state.activeNav==='nav-store active' ?   
              <h2> stocks crypto forex futures </h2> : 
            this.state.activeNav==='nav-booking'|| this.state.activeNav==='nav-booking active' ?   
              <h2> thinkorswim tradingview </h2>   :
            this.state.activeNav==='nav-blog'|| this.state.activeNav==='nav-blog active' ?   
              <h2> FAQ contact us</h2> : null: 

            this.state.tempNav!=='' ?
            this.state.tempNav==='nav-home' || this.state.tempNav==='nav-home active' ?   
              <h2> contactUs blog whyUS </h2> :  
            this.state.tempNav==='nav-invest' || this.state.tempNav==='nav-invest active' ?   
              <h2> join the fun </h2> :  
            this.state.tempNav==='nav-education' || this.state.tempNav==='nav-education active' ?
              <h2> hosting custom </h2> : 
            this.state.tempNav==='nav-store' || this.state.tempNav==='nav-store active' ?   
              <h2> stocks crypto forex futures </h2> : 
            this.state.tempNav==='nav-booking'|| this.state.tempNav==='nav-booking active' ?   
              <h2> thinkorswim tradingview </h2>   :
            this.state.tempNav==='nav-blog'|| this.state.tempNav==='nav-blog active' ?   
              <h2>  FAQ contact us </h2>   :
            null:null
          }
          </div>
           
          {/* <button className='rbtn-blue'  onClick={(e)=>{this.changeColor(e);}} > nick me nick</button> */}

          <Switch>
            <Route exact path='/' render={(props) => (<Education {...props}
               user = { this.state.user}
               updateInfo={this.updateInfo.bind(this)} 
            />)} /> 
            <Route exact path='/education' render={(props) => (<Education {...props}
                user = { this.state.user}
                updateInfo={this.updateInfo.bind(this)}
            />)} />
            <Route exact path='/profile' render={(props) => (<Profile {...props}
              updateInfo={this.updateInfo.bind(this)}
              user = { this.state.user}
            />)} />
           
            <Route exact path='/login' render={props => (
              <Login {...props}
              manualNavChange={this.manualNavChange.bind(this)}
              updateInfo={this.updateInfo.bind(this)}
              // nick={this.state.nick}
              />)} />  
        
            <Route exact path='/reset_password' render={(props) => (<Reset {...props}/>)} />
            <Route exact path='/forgot/:token' render={(props) => (<Forgot {...props}/>)} />
            

            {/* <Route exact path='/ovo' render={(props) => (<OvO {...props}/>)} /> */}


            <Route exact path='/signup/:token' render={(props) => (<SignUp {...props}/>)} />
            <Route exact path='/webinar/:token' render={(props) => (<JustVideo {...props}/>)} />
            <Route exact path='/freewebinar' render={(props) => (<Webinar {...props}/>)} />
            <Route exact path='/free-webinar' render={(props) => (<Webinar2 {...props}/>)} />

            
            <Route exact path='/contactme' render={(props) => (<Contactme {...props}/>)} />
            <Route exact path='/owner' render={(props) => (<Owner {...props}
              user = { this.state.user}
            />)} />
            
            <Route exact path='/discord' render={(props) => (<Discord {...props}/>)} />


            {/* <Route exact path='/subscribe' render={(props) => (<Payment {...props}/>)} /> */}

{/* 
            <Route exact path='/subscribe' render={(props) => (
              <Elements stripe={stripePromise}>
                <ElementsConsumer>
                  <Paymentx {...props}/>
                </ElementsConsumer>
              </Elements>
            )} /> */}

 
            {/* <Route exact path='/xox' render={(props) => (
              <Elements stripe={stripePromise}>
                <ElementsConsumer>
                  <XoX {...props}/>
                </ElementsConsumer>
                </Elements>
            )} /> */}
 
     
          {/* {(ctx: any) => <PaymentForm {...ctx} />} */}
        


            

          </Switch> 
         
        </Router>

        
</div> {/*//end of 100% */}
         
          {/* <div className="vidBody">
            <video src={vid1} autoPlay loop muted></video> */}
            {/* <img src={vidp} alt=''></img>  */}
          {/* </div> */}

        


          {/* <Router>
            <Footer/>
          </Router>  */}
      </div>
      
    )

  }

}