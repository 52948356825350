import React, { Component } from 'react';  
import axios from 'axios'; 
import { FiTrash2, FiEdit3 ,FiEdit } from "react-icons/fi";  
import { BiDetail } from "react-icons/bi";  
import {  FaRegPlayCircle ,FaRegQuestionCircle} from "react-icons/fa";   
import { AiOutlineCloudDownload } from "react-icons/ai";     
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// a little function to help us with reordering the result

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};
  
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250
});
 

// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));
const defaultcontent = {builder: '', video: '', quiz: [{ question: '', 
answers: [{ correct: false, answer: '' }] }], worksheet: {title: '', 
questions: [{ question: '', placeholder: ''}]}, download: '' }

export default class Lessons extends Component {

    constructor(props) {

        super(props); 
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragEnd2 = this.onDragEnd2.bind(this);
        this.state = {  
            unit: [{num: 1, title: '', lessons: [{num: 1, title: '', 
                content: {builder: '', video: '', quiz: [{ question: '', 
                answers: [{ correct: false, answer: '' }] }], worksheet: {title: '', 
                questions: [{ question: '', placeholder: ''}]}, download: '' }}]}],
            // unit: [
            // { num: 1, title: 'tttt1', lessons: [{ num: 1, title: '', 
            // content: {builder: '', video: '', quiz: [{ question: '', 
            // answers: [{ correct: false, answer: '' }] }], worksheet: {title: '', 
            // questions: [{ question: '', placeholder: ''}]}, download: '' }}]},
            // {num: 2, title: 'tttt2222', lessons: [{num: 1, title: '', 
            // content: {builder: '', video: '', quiz: [{ question: '', 
            // answers: [{ correct: false, answer: '' }] }], worksheet: {title: '', 
            // questions: [{ question: '', placeholder: ''}]}, download: '' }}]},
            // {num: 3, title: 'ttttt3333', lessons: [{num: 1, title: '', 
            // content: {builder: '', video: '', quiz: [{ question: '', 
            // answers: [{ correct: false, answer: '' }] }], worksheet: {title: '', 
            // questions: [{ question: '', placeholder: ''}]}, download: '' }}]},
            // {num: 4, title: 'rrrr4444', lessons: [{num: 1, title: '', 
            // content: {builder: '', video: '', quiz: [{ question: '', 
            // answers: [{ correct: false, answer: '' }] }], worksheet: {title: '', 
            // questions: [{ question: '', placeholder: ''}]}, download: '' }}]}],

            // unit:[
            //     {num:'0', title:'tttt111'},
            //     {num:'1', title:'t2t2t2t2t'},
            //     {num:'2', title:'t3t3t3t3tt'},
            //     {num:'3', title:'ttt4t4t4t4t4t4t111'}
            // ],
            currentunitindex: 0,
            items: getItems(10),
            resettingmap: true, 
            ccdetails: false,
            ccvideo: false,
            ccquestion: false,
            ccworksheet: false,
            ccdownload: false,
        } 
    }
    onDragEnd(result) {
        // dropped outside the list
        // console.log('result: ', result);
        if (!result.destination) {
          return;
        }
    
        var unit = reorder(
          this.state.unit,
          result.source.index,
          result.destination.index
        );
        unit.forEach((uni,ind) => {
            unit[ind].num = ind +1 ; 
        });
    
        this.setState({
            unit
        });
      }
      onDragEnd2(result) {
        // dropped outside the list
        // console.log('result2: ', result);
        // var sid= JSON.parse( result.source.index );
        // var did= JSON.parse( result.destination.index );
        // console.log('inds: ', inds);
        // console.log('indsty: ',typeof inds);


        if (!result.destination) {
          return;
        }
        var unitidx= this.state.currentunitindex ;
        // console.log('beofre: ', this.state.unit[unitidx].lessons);
        
        const lesson = reorder(
          this.state.unit[unitidx].lessons,
        //   result.source.index,
        result.source.index,
        result.destination.index
        );
        // console.log('after: ', lesson);
        lesson.forEach((less,inde) => { 
            lesson[inde].num = inde +1 ;  
        });
        var unit = this.state.unit ;
        unit[unitidx].lessons = lesson ; 
        this.setState({
            unit: unit
        });
      }
      getUnit(index) {
          this.setState({ currentunitindex: index });
      }
    XoX() {
        // console.log('XoX1: ',this.state.unit[0]);
        // console.log('XoX2: ',this.state.unit[1]);
        // console.log('XoX3: ',this.state.unit[2]);
    }
    countItem() {
       
    }
    lessonUpdateEvent(e,unitnum,lessnum) { 
        let unit = this.state.unit; 
        unit[unitnum-1].lessons[lessnum-1].title = e.target.value 
        this.setState({ unit: unit}) 
    }
    dictUpdateEvent(e,unitnum) { 
        let unit = this.state.unit; 
        unit[unitnum-1]['title'] =   e.target.value 
        this.setState({ unit: unit}) 
    }
    resetLessonNum() { 
        let currentunit = this.state.unit;
        let newunit = [];
        currentunit.forEach((unit) => { 
            let newlesson = [];
            let x = 1;
            unit.lessons.forEach((lesson)=>{
                lesson.num = x 
                newlesson.push(lesson);
                x += 1
            }) 
            unit.lessons = newlesson
            newunit.push(unit);  
        });  
        this.setState({ unit: newunit });
    }

    resetUnitNum() {
        let currentunit = this.state.unit;
        let newunit = [];
        let x = 1;
        currentunit.forEach((unit) => { 
            unit.num = x 
            newunit.push(unit); 
            x += 1
        }); 
        this.setState({ unit: newunit });
    }

    async deleteLesson(unitnum,lessnum) { 
        await this.setState({ resettingmap: false });  
        let currentunit = this.state.unit
        let currlesson = currentunit[unitnum-1].lessons
        let deleless = currlesson[lessnum-1].content 
        if ( deleless.video != '' ) {
            this.goDeleteFile('video', deleless.video) 
        }
        if ( deleless.download != '' ) {
            this.goDeleteFile('file', deleless.download) 
        }
           
        currlesson.splice(  lessnum-1 , 1);
        currentunit[unitnum-1].lessons = currlesson
        await this.setState({ unit: currentunit });
        await this.resetLessonNum();

        if ( this.state.unit[unitnum-1].lessons.length === 0 ) {
            this.newLesson(unitnum)
        }   
 
        await this.setState({ resettingmap: true });
    }

    async deleteUnit(unitnum) {   
        await this.setState({ resettingmap: false }); 
        let currentunit = this.state.unit;
        let deleunit = currentunit[unitnum-1].lessons;
        deleunit.forEach((lesson)=>{
            if ( lesson.content.video != '' ) {
                this.goDeleteFile('video', lesson.content.video) 
            }
            if ( lesson.content.download != '' ) {
                this.goDeleteFile('file', lesson.content.download) 
            }
        });


        currentunit.splice(  unitnum-1 , 1);
        await this.setState({ unit: currentunit }); 
        await this.resetUnitNum();

        if ( this.state.unit.length === 0 ) {
            this.newUnit()
        }   

        await this.setState({ resettingmap: true });
    }
    async goDeleteFile(type,filename) {        
        axios.delete('/courses/deletefile', {params: {type: type, file: filename } }) 
    };
    newUnit() {
        let currentunit = this.state.unit
        let newunit = {num: currentunit.length+1, title: '', lessons: [{num: 1, title: '', 
            content: {builder: '', video: '', quiz: [{ question: '', 
            answers: [{ correct: false, answer: '' }] }], worksheet: {title: '', 
            questions: [{ question: '', placeholder: ''}]}, download: '' }}]}
        currentunit.push(newunit);
        this.setState({ unit: currentunit });
    }
    newLesson(unitnum) {
        // console.log(this.state.unit[unitnum-1]); 
        let currentunit = this.state.unit
        let currlesson = currentunit[unitnum-1].lessons
        let newlesson = {num: currlesson.length +1, title: '', 
            content: {builder: '', video: '', quiz: [{ question: '', 
            answers: [{ correct: false, answer: '' }] }], worksheet: {title: '', 
            questions: [{ question: '', placeholder: ''}]}, download: '' }}
        currlesson.push(newlesson);
        currentunit[unitnum-1].lessons = currlesson
        this.setState({ unit: currentunit });
    }
    async goinSave() {
        await this.props.unitUpdate('unit',this.state.unit);
        await this.props.goSave();
        // this.props.changeNav('courses');
        // window.location.reload(false); //refresh page
    }
   
    componentDidMount() {  
        this.setState({ unit: this.props.unit });

        // console.log('inmount: ',this.props.unit);
        // console.log('injson: ', JSON.parse(this.props.unit));
        // if (this.props.unit=='""') {// placeholder 
        // } else if ( this.props.unit ) { 
        //     await this.setState({ resettingmap: false });
        //     await this.setState({ unit: JSON.parse(this.props.unit)});
        //     await this.setState({ resettingmap: true });
        // }  
        
    }

    render() { 
        // const {  } = this.props;
      
        return(    <div>  
 {/* {true? <div className="header-home">{this.state.unit[1]}</div> :null} */}
 
<div className='column-body'>
<div className="header-home">
    < input className="btn-blue" type="submit" value="Go Back" 
        onClick={()=>{ this.goinSave(); }} />
    <h1>Edit Course</h1> 
    <div>
    {/* <input className="btn-blue" type="submit" value="View Course" /> */}
    <input className="btn-blue" type="submit" value="Save" 
        onClick={()=>{ this.goinSave(); }} />
    
    </div>
</div>
<div className="header-home-detail"> 
 
    <input className= 'btn-clear'
        onClick= {()=>{this.props.changeTab('details')}}
        type="submit" value="Course Details" />
    <input className= 'btn-clear-active'   
        onClick= {()=>{this.props.changeTab('lessons')}}
        type="submit" value="Units & Lessons" />
  
</div>
<div className='cnt-create'> 

<div className='ctn-lesson-input'>
    <div>
        <input className="btn-blue" type="submit" value="+ Create New Unit" onClick={()=>{ this.newUnit(); }} />
        {/* { JSON.stringify(this.state.unit) } */}
    </div> 
   
    {/* <div>
        <input className="btn-blue" type="submit" value="Nick XoX" onClick={()=>{ this.XoX(); }} />
    </div>  */} 
    {/* <button className='rbtn-clear' onClick={()=>{ this.deleteUnit(1); }} >   <FiTrash2 className='img-delete2'/>  Delete
                    </button>  */}
{ this.state.resettingmap ? <>
<DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            //   style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.state.unit.map((unit, index) => (
                <Draggable key={`${unit.num}`} draggableId={`${unit.num}`} index={index}>
                  {(provided, snapshot) => (<>
                       <div className='cnt-unit' ref={provided.innerRef}
                       {...provided.draggableProps}
                       {...provided.dragHandleProps}
                    //  style={getItemStyle(
                    //    snapshot.isDragging,
                    //    provided.draggableProps.style
                    //  )}
                        onMouseEnter={()=>{this.getUnit(index);}}
                    > 

                       <div className='cnt-unit-header'>
                           <p> Unit { unit.num }</p>  
                 
                      
                           <div className='cnt-lesson-btns-edit'>    
                    <button className='rbtn-clear' onClick={()=>{ this.deleteUnit(unit.num); }} >   
                    <FiTrash2 className='img-delete2'/>  Delete
                    </button> 
                </div>
                    
            </div>
            <input type="text" autoComplete="on" 
                value={ this.state.unit[unit.num-1].title } 
                onChange={(e)=>{ this.dictUpdateEvent(e,unit.num) }}/> 
<DragDropContext onDragEnd={this.onDragEnd2}>
        <Droppable droppableId="droppable2">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            //   style={getListStyle(snapshot.isDraggingOver)}
            >


            { unit.lessons.map((lesson,idx) =>(<>  
                {/* <Draggable key={`${lesson.num}`} draggableId={`${lesson.num}`} index={idx} > */}
                <Draggable key={`${lesson.num}`} draggableId={`${lesson.num}`} index={idx} >
                
                  {(provided, snapshot) => (<>
                    <div className='cnt-lesson'
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <div className='cnt-unit-lesson'>
                        <p> Lesson # { lesson.num }  </p>            
                        <div className='cnt-lesson-btns-edit'>  
                            <button className='rbtn-clear' 
                                onClick={ async ()=>{ 
                                    await this.props.unitUpdate('unit',this.state.unit);
                                    this.props.changeTabEdit('edit', unit.num, lesson.num); }}>   
                                <FiEdit3 className='img-edit'/>  Edit  
                            </button>
                        
                            <button className='rbtn-clear' onClick={()=>{ this.deleteLesson(unit.num, lesson.num); }} >   
                            <FiTrash2 className='img-delete2'/>  Delete
                            </button> 
                        </div>
                    </div>
                    <input  type="text"   autoComplete="on" 
                        value={ this.state.unit[unit.num-1].lessons[lesson.num-1].title } 
                        onChange={(e)=>{ this.lessonUpdateEvent( e, unit.num, lesson.num ) }}/> 
                    <div className='cnt-worksheet-previews'> 
                        { defaultcontent.builder != this.state.unit[unit.num-1].lessons[lesson.num-1].content.builder ? 
                            <BiDetail className='img-detail' /> : null }   
                        { defaultcontent.video != this.state.unit[unit.num-1].lessons[lesson.num-1].content.video ? 
                            <FaRegPlayCircle className='img-play' />  : null }   
                        { this.state.unit[unit.num-1].lessons[lesson.num-1].content.quiz[0].question != '' ? 
                            <FaRegQuestionCircle className='img-question' />  : null }  
                        { this.state.unit[unit.num-1].lessons[lesson.num-1].content.worksheet.title != '' ? 
                            <FiEdit className='img-worksheet' />   : null }  
                        { this.state.unit[unit.num-1].lessons[lesson.num-1].content.download ? 
                            <AiOutlineCloudDownload className='img-download' />   : null }  
                           
                    </div>
                </div>
                  </>)}
                </Draggable> 
            </> )) }
            {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    
            <div className='ctn-unit-footer'>
                <input className="btn-blue" type="submit" value="+ Add New Lesson" onClick={()=>{ this.newLesson(unit.num); }} />
            </div>
            
        </div>
                  </>)}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
</> : null }
    


    {/* { this.state.resettingmap ? 
     this.state.unit.map(unit => (<>
        <div className='cnt-unit'> 

            <div className='cnt-unit-header'>
                <p> Unit { unit.num }</p>      
                
                <div className='cnt-lesson-btns-edit'>    
                    <button className='rbtn-clear' onClick={()=>{ this.deleteUnit(unit.num); }} >   
                    <FiTrash2 className='img-delete2'/>  Delete
                    </button> 
                </div>
                    
            </div>
            <input type="text" autoComplete="on" 
                value={ this.state.unit[unit.num-1].title } 
                onChange={(e)=>{ this.dictUpdateEvent(e,unit.num) }}/> 

            { unit.lessons.map(lesson =>(<>  
                <div className='cnt-lesson'>
                    <div className='cnt-unit-lesson'>
                        <p> Lesson # { lesson.num }  </p>            
                        <div className='cnt-lesson-btns-edit'>  
                            <button className='rbtn-clear' 
                                onClick={ async ()=>{ 
                                    await this.props.unitUpdate('unit',this.state.unit);
                                    this.props.changeTabEdit('edit', unit.num, lesson.num); }}>   
                                <FiEdit3 className='img-edit'/>  Edit  
                            </button>
                        
                            <button className='rbtn-clear' onClick={()=>{ this.deleteLesson(unit.num, lesson.num); }} >   
                            <FiTrash2 className='img-delete2'/>  Delete
                            </button> 
                        </div>
                    </div>
                    <input  type="text"   autoComplete="on" 
                        value={ this.state.unit[unit.num-1].lessons[lesson.num-1].title } 
                        onChange={(e)=>{ this.lessonUpdateEvent( e, unit.num, lesson.num ) }}/> 
                    <div className='cnt-worksheet-previews'> 
                        { defaultcontent.builder != this.state.unit[unit.num-1].lessons[lesson.num-1].content.builder ? 
                            <BiDetail className='img-detail' /> : null }   
                        { defaultcontent.video != this.state.unit[unit.num-1].lessons[lesson.num-1].content.video ? 
                            <FaRegPlayCircle className='img-play' />  : null }   
                        { this.state.unit[unit.num-1].lessons[lesson.num-1].content.quiz[0].question != '' ? 
                            <FaRegQuestionCircle className='img-question' />  : null }  
                        { this.state.unit[unit.num-1].lessons[lesson.num-1].content.worksheet.title != '' ? 
                            <FiEdit className='img-worksheet' />   : null }  
                        { this.state.unit[unit.num-1].lessons[lesson.num-1].content.download ? 
                            <AiOutlineCloudDownload className='img-download' />   : null }  
                           
                    </div>
                </div>
            </> )) }

    
            <div className='ctn-unit-footer'>
                <input className="btn-blue" type="submit" value="+ Add New Lesson" onClick={()=>{ this.newLesson(unit.num); }} />
            </div>
            
        </div>
    </> )) : null } */}
 
    

</div>
  

</div>

 
</div>

        </div> ) } }


