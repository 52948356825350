import React, { Component } from 'react'; 
import axios from 'axios'; 
import imgstar from '../../../content/images/star.png'; 
// import imgstar from '../../../content/uploads/images/IMG_4454.PNG'; 
import Lessons from './lessons';
import Edit from './edit'; 

export default class Create extends Component {

    constructor(props) {

        super(props); 

        this.state = {    
                course: {
                    unit: [{num: 1, title: '', lessons: [{num: 1, title: '', 
                        content: {builder: '', video: '', quiz: [{ question: '', 
                        answers: [{ correct: false, answer: '' }] }], worksheet: {title: '', 
                        questions: [{ question: '', placeholder: ''}]}, download: '' }}]}],
                    ID: 0,
                    status: 'inprogress',
                    title: '',
                    synopsis: '',
                    description: '',
                    img: '', } ,
                tab: 'details', 
                unitlessholder: {unitnum: 0, lessnum: 0},
                roles: '',

       
                
        } 
    } 
    dictUpdateEvent(key,e) { 
        let current = { ...this.state.course};  
        current[key] =   e.target.value 
        this.setState({ course: current}) 
    }
    dictUpdate(key,value) { 
        // console.log('key: ', key);
        // console.log('value: ', value); 
        let current = { ...this.state.course};  
        current[key] =   value
        this.setState({ course: current })  
    }
    async goSave() {
        // console.log('goSave: ', this.state.course); 
        if (this.state.course.ID==0) { 
            await this.create();
        }
        else { 
            await this.save(); 
        }
        this.props.changeNav('courses');
    }
    async goSaveNoRefresh() {
        // console.log('goSave: ', this.state.course); 
        if (this.state.course.ID==0) { 
            await this.create();
        }
        else { 
            await this.save(); 
        }
        
    }
    async save() {  
        // if (this.state.course.title!='') { 
        let currcourse = this.state.course
        let defaultunit = [{num: 1, title: '', lessons: [{num: 1, title: '', 
            content: {builder: '', video: '', quiz: [{ question: '', 
            answers: [{ correct: false, answer: '' }] }], worksheet: {title: '', 
            questions: [{ question: '', placeholder: ''}]}, download: '' }}]}];
        // console.log('defaultunit: ',defaultunit)
        // let string =  JSON.stringify( defaultunit) 
        // console.log('string: ',string)
        // let nick = '""'
        // console.log('nick: ',nick)
        // let xxx =  JSON.stringify( nick) 
        // console.log('xxx: ',currcourse.unit)

        if (currcourse.unit !='' ) { 
            if (currcourse.unit != defaultunit) { 
                currcourse.unit =  JSON.stringify(currcourse.unit) 
            } else { 
                currcourse.unit = false
            } 
        } else { 
            currcourse.unit = false
        }
        

        let res= await axios.post('/courses/save', { course: currcourse }) 
        // console.log('outsave: ',res.data)
       

    }
    async create() {  
        if (this.state.course.title!='') {
            let currcourse = this.state.course
            currcourse.unit =  JSON.stringify(currcourse.unit) 
            // console.log('goin dark');
            let res= await axios.post('/courses/create', { course: currcourse }) 
            // console.log('res dark',res.data);
            if (res.data) {
                let course = this.state.course
                course.ID = res.data
                this.setState({ course: course })
                // console.log('outcreate: ',typeof res.data)  
            } else {
                // console.log('nottt: ', res.data)
            } 
        } 
    }
    async load(id) { 
        let res= await axios.get('/courses/load', {params: { id: id }})

        let thecourse = res.data; 

        thecourse.unit = JSON.parse( thecourse.unit ); 

        await this.setState({ course: thecourse })   
        // console.log('load: ',res.data)
        // if ( this.state.course.img !=='' ) {
        //     let req = await axios.post('/courses/loadimg', { filename: this.state.course.img } ) 
        //     // console.log('imageURL: ',req.data); 
        //     this.setState({ imageURL:  req.data }); 
        // }
    }

    async updatedImgage(e){
        let course = this.state.course
        this.goDeleteFile('image',course.img); 

        let imgfile = e.target.files[0];   
        let formData = new FormData();
        formData.append('file', imgfile ); 
        let res =  await axios.post('/courses/imgupload', formData) 

        course.img = res.data  
        this.setState({ course: course })   
 
      }

   

 

    async changeTab(tab) {
        await this.setState({ tab: tab });
        if ( this.state.tab !== 'lessons' ) {
            this.updateChecks();
        } 
    }
    async changeTabEdit(tab,unitnum,lessnum) {    
        let unitlessholder = {unitnum: unitnum, lessnum: lessnum}
        await this.setState({ unitlessholder: unitlessholder }); 

        // console.log('tab:', tab );
        // console.log('unitnum:', unitnum );
        // console.log('lessnum:', lessnum );
        // console.log('course:', this.state.course );
       
        this.setState({ tab: tab });
       
    }

    checkboxInprogress() {
        let inprogress = document.querySelectorAll('.checkbox-inprogress')[0].checked; 
        // let futures = document.querySelectorAll('.checkbox-futures')[0];  
        // let monthly = document.querySelectorAll('.checkbox-monthly')[0]; 
        // futures.checked = !inprogress 
        // if (inprogress) {
        //     monthly.checked = !inprogress 
        //     this.dictUpdate('status','inprogress');  
        // }
        if (inprogress) {
            this.dictUpdate('status','inprogress');  
            this.state.roles.forEach((role,index) => {
                if (  role.name !== 'XoX_admin_oVo') {
                    var check = document.querySelectorAll(`.checkbox-${role.name}`)[0]; 
                    check.checked = false ;
                }

            });
        }
    
    }
    checkboxMonthly() { 
        // let monthly = document.querySelectorAll('.checkbox-monthly')[0].checked; 
        // let futures = document.querySelectorAll('.checkbox-futures')[0].checked;  
        // let inprogress = document.querySelectorAll('.checkbox-inprogress')[0]; 
        // if (monthly && futures) {
        //     inprogress.checked = false
        //     this.dictUpdate('status','monthlyfutures');   
        // }  
        // else if (!monthly && futures) {
        //     inprogress.checked = false
        //     this.dictUpdate('status','futures');   
        // }  
        // else if (monthly && !futures) {
        //     inprogress.checked = false
        //     this.dictUpdate('status','monthly');   
        // }  
        // else if (!monthly && !futures) {
        //     inprogress.checked = true
        //     this.dictUpdate('status','inprogress');   
        // }
        var inprogress = document.querySelectorAll('.checkbox-inprogress')[0]; 
        var status = '' ;
        // console.log('inprogress: ', inprogress);
        // console.log('roles: ', this.state.roles);
        // console.log('rolesty: ',typeof this.state.roles);

        this.state.roles.forEach((role,index) => {
            // console.log('role: ', role);
            if (  role.name !== 'XoX_admin_oVo') {
                var check = document.querySelectorAll(`.checkbox-${role.name}`)[0];   
                if (check.checked) {
                    inprogress.checked = false ;
                    status += role.name ;  
                }   
            } 
        });
        if (status === '') {
            inprogress.checked = true ;
            this.dictUpdate('status','inprogress');   
        }
        // console.log('status: ', status);
        this.dictUpdate('status', status);  
    }  

    updateChecks() {
        let inprogress = document.querySelectorAll('.checkbox-inprogress')[0];
        // let monthly = document.querySelectorAll('.checkbox-monthly')[0]; 
        // let futures = document.querySelectorAll('.checkbox-futures')[0];  
        if (this.state.course.status === 'inprogress') {
            inprogress.checked = true 
        } else {
            this.state.roles.forEach((role,index) => { 
                if (  role.name !== 'XoX_admin_oVo') {
                    var check = document.querySelectorAll(`.checkbox-${role.name}`)[0];  
                    if ( this.state.course.status.includes(role.name) ) {
                        check.checked = true
                    }
                } 
            });
        }  
        // if (this.state.course.status.includes('monthly')) {
        //     monthly.checked = true  } 
        // if (this.state.course.status.includes('futures')) {
        //     futures.checked = true  } 
    }
    //this.state.course.unit[this.state.unitlessholder.unitnum-1].lessons[this.state.unitlessholder.lessnum-1]
    async lessonUpdateEvent(value) {  
        // var unit = this.state.course.unit[this.state.unitlessholder.unitnum-1] ;
        // console.log('unit: ', unit);
        // unit.lessons[this.state.unitlessholder.lessnum-1].title = value  ;
        // console.log('after: ', unit);

        // console.log('bbee: ', this.state.course.unit[this.state.unitlessholder.unitnum-1].lessons[this.state.unitlessholder.lessnum-1]);

        let current = { ...this.state.course};  
        current.unit[this.state.unitlessholder.unitnum-1].lessons[this.state.unitlessholder.lessnum-1].title = value  ;
        await this.setState({ course: current }) ;  

        // console.log('afteeeer: ', this.state.course.unit[this.state.unitlessholder.unitnum-1].lessons[this.state.unitlessholder.lessnum-1]);

        
    }
    async conentUpdate(newcontent) { 
        // console.log('beforecrea: ', typeof this.state.course) 
        let course = this.state.course; 
        let unitnum = this.state.unitlessholder.unitnum
        let lessnum = this.state.unitlessholder.lessnum
        course.unit[unitnum-1].lessons[lessnum-1].content = newcontent  
        await this.setState({ course: course})  
        await this.goSaveNoRefresh();  

        let cours2 = this.state.course;
        cours2.unit = JSON.parse(cours2.unit);
        this.setState({ course: cours2}) 
    }
    async goDeleteFile(type,filename) {        
        axios.delete('/courses/deletefile', {params: {type: type, file: filename } }) 
    };
    async componentDidMount() { 
        var res = await axios.get('/managing/getroles');
        // console.log('res: ', res.data); 
        await this.setState({ roles: res.data }); 

        if ( this.props.editID ) { 
            await this.load( this.props.editID ); 
            this.updateChecks();
        } else { 
            this.updateChecks();
        }   
    } 
    render() { 
        
        // const { changeNav } = this.props;
        return(
            <div>  
 {/* {true? <div className="header-home">{this.state.course.status}</div> :null} 
 {this.state.course.status.includes('monthly') ? <div className="header-home">yes to monthly</div> :null}
 {this.state.course.status.includes('futures') ? <div className="header-home">yes to futures</div> :null} */}
<div className='column-body'>
{ this.state.tab=='lessons' ? 
//  <Lessons Edit
    <Lessons  
        unitUpdate={this.dictUpdate.bind(this)} 
        unit={this.state.course.unit}
        // course={this.state.course} 
        changeTab={this.changeTab.bind(this)}  
        changeTabEdit={this.changeTabEdit.bind(this)}
        changeNav={this.props.changeNav.bind(this)}
        goSave={this.goSave.bind(this)}
    /> 
: this.state.tab=='edit' ? 
    <Edit   
        lesson={this.state.course.unit[this.state.unitlessholder.unitnum-1].lessons[this.state.unitlessholder.lessnum-1]}
        updateTitle={this.lessonUpdateEvent.bind(this)}  
        conentUpdate={this.conentUpdate.bind(this)} 
        // changeTabEdit={this.changeTabEdit.bind(this)}
        changePage={this.changeTab.bind(this)}
    /> 
:<>

<div className="header-home">
<input className="btn-blue" type="submit" value="Go Back" onClick={()=>{
    this.goSave(); 
    // this.props.changeNav('courses');
    // window.location.reload(false); //refresh page
    
    
}}/>
    <h1>Create Course</h1> 
    <div>
    {/* <input className="btn-blue" type="submit" value="View Course" /> */}
    <input className="btn-blue" type="submit" value="Save" 
        onClick = {()=>{
            this.goSave(); 
            // this.props.changeNav('courses');
            // window.location.reload(false); 
        }}
    />
    </div>
</div>
<div className="header-home-detail"> 

    <input className= 'btn-clear-active' 
        onClick= {()=>{this.changeTab('details')}}
        type="submit" value="Course Details" />
    <input className= 'btn-clear' 
        onClick= {()=>{this.changeTab('lessons')}}
        type="submit" value="Units & Lessons" />
  
</div>
<div className='cnt-create'> 

                      
<div className='ctn-create-input'>
 Course Title
    <input  type="text" autoFocus
        onChange={(e) => { this.dictUpdateEvent('title',e) }}  
        value = {this.state.course.title}
        autoComplete="on"
    />
    
Course Synopsis
    <textarea  className='txta-sys'
            onChange={(e) =>  { this.dictUpdateEvent('synopsis',e) }}  
            value = {this.state.course.synopsis}
            autoComplete="on"
    />
Course Description
    <textarea  className='txta-descrip'
        onChange={(e) =>  { this.dictUpdateEvent('description',e) }}  
        value = {this.state.course.description}
        autoComplete="on"
    />
</div>

<div className='ctn-create-side'>
    <div className='ctn-status'> Status
        <div className='ctn-check'>
            {/* <div>
                <input type='checkbox' className='checkbox-inprogress' onClick={()=>{ this.checkboxInprogress(); }} /> 
                    <label  onClick={()=>{ this.checkboxInprogress(); }} >In progress...</label>
            </div>
            <div>
                <input type='checkbox' className='checkbox-monthly' onClick={()=>{ this.checkboxMonthly(); }} /> 
                <label onClick={()=>{ this.checkboxMonthly(); }} >Publish: Monthly</label>
            </div>
            <div>  
                <input type='checkbox' className='checkbox-futures' onClick={()=>{ this.checkboxMonthly(); }} /> 
                <label onClick={()=>{ this.checkboxMonthly(); }} >Publish: Futures BootCamp</label>
            </div>  */}
            <div>
                <input type='checkbox' className='checkbox-inprogress' onClick={()=>{ this.checkboxInprogress(); }} /> 
                    <label  onClick={()=>{ this.checkboxInprogress(); }} >In progress...</label>
            </div>
            { this.state.roles !== '' ?  
                this.state.roles.map((role,index)=>(<> 
                    {  role.name === 'XoX_admin_oVo' ? null :
                        <div>
                            <input type='checkbox' className={`checkbox-${role.name}`}  onClick={()=>{ this.checkboxMonthly(); }} /> 
                            <label onClick={()=>{ this.checkboxMonthly(); }} >{role.display} </label>
                        </div>
                    }
                </>))
            : null }
        </div>
    </div>
    <div className='cnt-create-img'>
        <label>Featured Image</label>  
      
            { this.state.course.img != '' ?  
                <img className='img-course'   
                    src =  { `/uploads/images/${this.state.course.img}` }  
                    alt =''/> 
                : <img className='img-course'   
                    src = { imgstar }  
                    alt =''/> }
 
            
        {/* <input   type='file' onChange={(e)=>{ this.onImageChange(e) }}/>  */}
        <input type="file" onChange={(e)=> { this.updatedImgage(e) }}/>
        {/* <button onClick={()=> { this.uploadHandler() }}>Upload!</button>
        <button onClick={()=> { this.onImageChange() }}>nick!</button> */}
        {/* {this.state.uploadstatus} */}
    </div>
</div>
{/* '../../../content/uploads/images/IMG_4454.PNG' */}
{/* require( this.state.course.img ).default */}


</div>

</>}
</div>



</div>
          
        )

    }

}

