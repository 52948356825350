import React, { Component } from 'react';
import imgtos from '../../content/images/tos.png';
import imgtv from '../../content/images/star.png'; 
import imgsc from '../../content/images/sced.png'; 
import { Link } from 'react-router-dom';

export default class Booking extends Component {

    constructor(props) {

        super(props);

        this.state = {
            imageURL: false,
            nick: 'nick.png',
            xox: 'xox.png',
            myimg: '',
        }
    }
    // xox() {
    //     this.onStart();
    // }
    // nick() {
    //     this.setState({ imageURL:  !this.state.imageURL }); 
    // }
    // ovo() {
    //     let nickimg = require(`../../content/uploads/${this.state.xox}`).default;
    //     this.setState({ myimg:  nickimg }); 
    // }
    xox() {
        let nick = `/uploads/images/xox.png`
        this.setState({ myimg:  nick }); 
    }
    nick() {
        this.setState({ imageURL:  !this.state.imageURL }); 
    }
    ovo() {
        let nick = `/uploads/images/nick.png`
        this.setState({ myimg:  nick }); 
    }
//     imgurl:~blob:http://localhost:3000/9f4a3931-7804-4631-a744-a21127b49fec
// booking.js:24 imgurl:~blob:http://localhost:3000/a284627a-b515-40b1-8730-b4e5ebe479c7
// booking.js:24 imgurl:~blob:http://localhost:3000/69def046-7250-417d-879b-500eade7cfb2
//imgurl:~blob:http://localhost:3000/5d79c575-88ba-4c54-b904-065cefbdc551
    updatedImgage(e) {
        let imgfile = e.target.files[0];  
        let imgurl = URL.createObjectURL(imgfile);
        console.log(`imgurl:~${imgurl}`)
        this.setState({ imageURL:  imgurl }); 
    }
    async onStart() {
        // let nickimg = await require(`../../content/uploads/${this.state.nick}`).default;
        // this.setState({ myimg:  nickimg }); 
    }
    componentDidMount() {
        // this.onStart();
    }
    render() {

        return(
            <div className='cnt-soon'>   
            <h1 className='txt-soon'>Coming Soon...</h1>
        </div>
        )

    }

}