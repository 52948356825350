import axios from 'axios';
import React, { Component } from 'react';   

import UpdateCard from '../Payment/update_wrapper';  

axios.defaults.withCredentials=true;

export default class Profile extends Component {
    constructor(props) {
        super(props); 

        this.state = {  
            innertab: 'info',
            lastmsg: false,
            name: 'nick',
            lastname: 'xox',
            email: 'starcoc@snic./com',
            mystripe: false,
            expireat: false,

        }; 
    };

    goLogout() {    
        axios.delete('/profile/logout') ;
        this.props.updateInfo({
            status: false,
            name: '',
            email: '',
            folder: '',
            role: '' 
          });
        this.props.history.push('/login')  
    };

 
    updateCard() {
        this.setState({ innertab: 'updatecard' }); 
    }
 
    async getstripeinfo() { 
        var res = await axios.post('/stripe/stripeinfo', {
            email: this.props.user.email, 
        });
        this.setState({ mystripe: res.data }); 
    };
    async delsub() {
        let res = await axios.post('/stripe/delsub', {
            email: this.props.user.email, 
        });
        this.setState({ expireat: res.data });

    }
    sleep(sec) {
        return new Promise(resolve => setTimeout(resolve, sec*1000));
    }
    async onStart() {
        await this.sleep(1);
        if (!this.props.user.status) {
            this.props.history.push('/login')  
        } else {
            this.getstripeinfo();
        }
    }
    componentDidMount = () => { 
        this.onStart();//xox

    };

    render() {  
        return ( 
            this.state.innertab == 'updatecard' ?
                <UpdateCard
                    props = { this.props }
                    mystripe = { this.state.mystripe }
                />
            :
                <div className="login-Body-profile">   
                {this.state.expireat ?
                    <h2>{`Plan will expire on ${this.state.expireat}`}</h2>
                : null }
                

                <div className='input-title-profile'>
                    {`First Name: ${this.props.user.name}`} 
                </div> 
                <div className='input-title-profile'>
                    {`Last Name: ${this.props.user.lastname}`}
                       
                </div>  
                <div className='input-title-profile'>
                    {`Email: ${this.props.user.email}`} 
                </div>  
                <div className='input-title-profile'>
                    {`Card: ${this.state.mystripe.card_num}`} 
                </div>  
                <div className='input-title-profile'>
                    <button className='rbtn-blue'
                        onClick={()=>{ this.updateCard(); }}>
                        Update Card
                    </button>
                    {/* <button className='rbtn-blue'>Delete Card</button> */}
                    <button className='rbtn-blue' onClick={()=>{ this.delsub(); }}>
                        Cancel Plan
                    </button>
                </div>
                <div  > 
                    <button className='rbtn-blue' onClick={()=>{ this.goLogout(); }}>   
                        Logout
                    </button> 
                </div>  


       
        </div>
 
  
             
        );
    }
}
