import React, { Component } from 'react';  
import axios from 'axios';   
// import ReactPlayer from 'react-player'; 
// import { Link } from 'react-router-dom';  
import imgtrade from '../../../content/images/star.png'; 
import { FiEdit } from "react-icons/fi";  
import { BiDetail } from "react-icons/bi";  
import {  FaRegPlayCircle ,FaRegQuestionCircle} from "react-icons/fa";   
import { AiOutlineCloudDownload } from "react-icons/ai";    
import KidCourse from './course'; 

export default class KidCourses extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {  
            course: '', 
            qty: '',
            qtypass: '',
            // qtypass: { builder: 2, video: 1, quiz: 4, worksheet: 5, download: 8 },  
            innertab: '',
            progress: {},
            courses: '',
            // courses:  [
            //         {
            //         ID: 151,
            //         status: 'inprogress',
            //         title: 'The Dynamic Income Courses',
            //         synopsis: 'Increase Your Confidence in Taking Winning trades',
            //         description: 'asdasdasdasdasdasdsa',
            //         img: '1632323972968_clouds.jpg',
            //         unit:  [{"num":1,"title":"union1","lessons":[{"num":1,"title":"less1","content":{"builder":"bob the builder bob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builderbob the builder","video":"1633211957819_Stars Overlay.mp4","quiz":[{"question":"questin1","answers":[{"correct":false,"answer":"a1"},{"correct":true,"answer":"a2"},{"correct":false,"answer":"a3"}]},{"question":"questin2","answers":[{"correct":false,"answer":"true"},{"correct":true,"answer":"false"}]},{"question":"wewe","answers":[{"correct":false,"answer":"qwe"}]}],"worksheet":{"title":"work title","questions":[{"question":"questin1 promot","placeholder":"Placeholder 1"},{"question":"questin2 promot","placeholder":"Placeholder 2"}]},"download":""}},{"num":2,"title":"lss2","content":{"builder":"","video":"","quiz":[{"question":"sdfsdfsd","answers":[{"correct":false,"answer":"sdfsdf"},{"correct":true,"answer":"sdfsdfsdfs"}]}],"worksheet":{"title":"asda","questions":[{"question":"asd","placeholder":"asd"}]},"download":"1634797489516_test.py"}}]}]
            //         },
            //         {
            //         ID: 152,
            //         status: 'inprogress',
            //         title: 'GC Indicator Quick Start Course',
            //         synopsis: 'A summary of the movements of the crude market, and strategies for profiting off of those movements',
            //         description: '',
            //         img: '',
            //         unit: '[{"num":1,"title":"","lessons":[{"num":1,"title":"","content":{"builder":"","video":"","quiz":[{"question":"","answers":[{"correct":false,"answer":""}]}],"worksheet":{"title":"","questions":[{"question":"","placeholder":""}]},"download":""}}]}]'
            //         },
            //         {
            //             ID: 151,
            //             status: 'inprogress',
            //             title: 'The Dynamic Income Courses',
            //             synopsis: 'Learn how to create a consistent weekly income stream by consistently selling options contracts and leveraging account margin to take advantage of capital gains.',
            //             description: '',
            //             img: '1632323972968_clouds.jpg',
            //             unit: '[{"num":1,"title":"","lessons":[{"num":1,"title":"","content":{"builder":"","video":"","quiz":[{"question":"","answers":[{"correct":false,"answer":""}]}],"worksheet":{"title":"","questions":[{"question":"","placeholder":""}]},"download":""}}]}]'
            //             },
            //             {
            //             ID: 152,
            //             status: 'inprogress',
            //             title: 'GC Indicator Quick Start Course',
            //             synopsis: 'Learn how to create a consistent weekly income stream by consistently selling options contracts and leveraging account margin to take advantage of capital gains.',
            //             description: '',
            //             img: '',
            //             unit: '[{"num":1,"title":"","lessons":[{"num":1,"title":"","content":{"builder":"","video":"","quiz":[{"question":"","answers":[{"correct":false,"answer":""}]}],"worksheet":{"title":"","questions":[{"question":"","placeholder":""}]},"download":""}}]}]'
            //             }
            //     ]
} }/////////////////////////////////////////////////////////////////////////////
async checkQty() {
    var qty = []  
    this.state.courses.forEach((course,index)=>{
        // courses[index].unit = JSON.parse(course.unit)  
        var base = { builder: 0, video: 0, quiz: 0, worksheet: 0, download: 0 }
        course.unit.forEach((unit,indexx)=>{
            unit.lessons.forEach((lesson,idex)=>{
                if ( lesson.content.builder !== '') {
                    base.builder += 1
                }
                if ( lesson.content.video !== '') {
                    base.video += 1
                }
                if ( lesson.content.quiz[0].question !== '') {
                    // alert('quiz: ',lesson.content.quiz) 
                    base.quiz += 1
                }
                if ( lesson.content.worksheet.questions[0].question !== '') {
                    // alert('worksheet: ',lesson.content.worksheet) 
                    base.worksheet += 1
                }
                if ( lesson.content.download !== '') {
                    base.download += 1
                } 
            })
        }) 
        qty.push(base) 
    })  
    this.setState({ qty: qty });
    // console.log(qty) 
    // axios.post('/helper/print', JSON.stringify(xox) ) 
} 


updateInnerTab(tab) {
    this.setState({ innertab: tab });
};

async takeCourse(index) {
    // console.log('index: ', index);
    // let course = JSON.stringify(this.state.courses[index]);
    let course = this.state.courses[index];
    // console.log('course: ', course);
    await this.setState({ course: course });
    // console.log('qty: ', this.state.qty);
    // console.log('index: ', this.state.qty[index]);
    await this.setState({ qtypass: this.state.qty[index] });//xox
    
    this.updateInnerTab('course');
    
}

async loadAll() { 
    let res= await axios.get('/courses/loadall' ) 

    let courses = res.data 
    courses.forEach((course,index)=>{
            courses[index].unit = JSON.parse(course.unit)  
    })  
    await this.setState({ courses: courses });
    this.checkQty();

    //async loadProgress() { 
    let folder = this.props.user.folder;
    let resp = await axios.get('/takecourse/getprogress', {params: {
        // coursetitle: course.title,
        folder: folder
    }});
    // console.log('res: ', res.data);
    
    // console.log('coursetitle: ', course.title);
    var progress = {};
    resp.data.forEach(pcourse => { 

        progress[pcourse.unqcourse] = {}; 
        progress[pcourse.unqcourse].percent = pcourse.percent; 
    });
    // console.log('progress: ', progress);
    // console.log('courses: ', courses);
    this.setState({progress: progress });
};
 
 
componentDidMount() {   
    this.loadAll();  
}
render() { return(<> {/*/////////////////////////////////////////////////////*/}
{ this.state.innertab === 'course' ?
    <KidCourse
        course={this.state.course}
        qty={this.state.qtypass}
        backtab={this.updateInnerTab.bind(this)}
        user={this.props.user}

    />
:<>  
    <div className='column-body'>
            <div className="header-home">
                <h1> Course Library </h1>  
            </div>
            <div className='cnt-kid-body-courses'>
                { this.state.courses!= '' ?
                    this.state.courses.map((course,index)=>(<>
                        { course.status.includes(this.props.user.role) ? <> 
                        <div className='cnt-kid-courses'>
                            <div className='img-kid-courses'> 
                                { course.img!='' ?  
                                    <img src={ `/uploads/images/${course.img}` } alt='' onClick={()=> { this.takeCourse(index); } } ></img>
                                    :  <img src={imgtrade} alt='' onClick={()=> { this.takeCourse(index); } } ></img>
                                }
                            </div>
                            <div>
                                <div className='txt-kid-courses-title'>
                                    <h2>{course.title}</h2> 
                                    <txt>{course.synopsis}</txt> 
                                </div>

                                <div className='txt-courses-previews'> 
                                    { this.state.qty !== '' ?  <>
                                        { this.state.qty[index].builder !== 0 ? 
                                            <>
                                                <BiDetail className='img-courses-detail' /> 
                                                { this.state.qty === '' ? 
                                                    <txt2>0</txt2> 
                                                :   <txt2>{this.state.qty[index].builder}</txt2>
                                                }
                                                
                                            </> : null }   
                                        { this.state.qty[index].video !== 0 ? 
                                            <>
                                                <FaRegPlayCircle className='img-courses-play' /> 
                                                { this.state.qty === '' ? 
                                                    <txt2>0</txt2> 
                                                :   <txt2>{this.state.qty[index].video}</txt2> 
                                                }
                                            </> : null }   
                                        { this.state.qty[index].quiz !== 0 ? 
                                            <>
                                                <FaRegQuestionCircle className='img-courses-question' /> 
                                                { this.state.qty === '' ? 
                                                    <txt2>0</txt2> 
                                                :    <txt2>{this.state.qty[index].quiz}</txt2> 
                                                }
                                            </> : null }   
                                        { this.state.qty[index].worksheet !== 0 ? 
                                            <>
                                                <FiEdit className='img-courses-worksheet' /> 
                                                { this.state.qty === '' ? 
                                                    <txt2>0</txt2> 
                                                :   <txt2>{this.state.qty[index].worksheet}</txt2> 
                                                }
                                            </> : null }   
                                        { this.state.qty[index].download !== 0 ? 
                                            <>
                                                <AiOutlineCloudDownload className='img-courses-download' /> 
                                                { this.state.qty === '' ? 
                                                    <txt2>0</txt2> 
                                                :   <txt2>{this.state.qty[index].download}</txt2> 
                                                }
                                            </> : null }     
                                    </> : null }
                                </div>
                            </div>
                            <div className='cnt-kids-courses-start'>
                                { this.state.progress[course.title] ? <>
                                    {this.state.progress[course.title].percent === 100 ?
                                        <button className='rbtn-blue'
                                            onClick={()=>{this.takeCourse(index);}}
                                        >Retake Course</button>
                                    : 
                                        <button className='rbtn-blue'
                                            onClick={()=>{this.takeCourse(index);}}
                                        >Resume Course</button>
                                    }
                                </>:
                                    <button className='rbtn-blue'
                                        onClick={()=>{this.takeCourse(index);}}
                                    >Start Course</button>
                                }
                            </div>
                        </div>
                        </> :null}
                    </>))
                : null }

            </div>
            
    </div> 
</> }
</> ) }}////////////////////////////////////////////////////////////////////////

