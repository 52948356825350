import React, { Component } from 'react';
import axios from 'axios'; 
import imgtrade from '../../content/images/star.png'; 
import { AiFillSetting, AiOutlineEye } from 'react-icons/ai';
import { FaUserGraduate } from 'react-icons/fa';
import { BsPeopleFill } from "react-icons/bs";
import { BiDuplicate, BiVideoRecording } from "react-icons/bi";
import { FiTrash2, FiHome } from "react-icons/fi"; 
import { MdSpaceDashboard } from "react-icons/md"; 
import { RiDashboardLine } from "react-icons/ri"; 
import { ImUserPlus } from "react-icons/im"; 
import { IoMdHelpCircleOutline } from "react-icons/io"; 


import { Link } from 'react-router-dom';  
import Students from './Helpers/students';
import Courses from './Helpers/courses';
import Calls from './Helpers/calls';
import Homework from './Helpers/homework';
import KidCalls from './HelpKids/calls';  
import KidCourses from './HelpKids/courses'; 
import Student from './Helpers/student';
import KidHomework from './HelpKids/homework'; 
import Onboarding from '../Store/store'; 
import Tutor from './Helpers/helpvideos';  
import KidTutor from './HelpKids/tutor';  


export default class Education extends Component {

    constructor(props) {

        super(props);

        this.state = { 
            nav: '', 

} }/////////////////////////////////////////////////////////////////////////////
 
async innerNav(nav) { 
    // window.location.reload(false);  
    await this.setState({ nav: 'refresh_components_default' });

    this.setState({ nav: nav  }); 
}

async componentDidMount() {    
    // console.log('props: ', this.props.user);

    // var user = {}
    // user.name = ''
    await axios.get('/profile/login').then((res) =>{ 
      if (res.data.loggedIn){    
          this.props.updateInfo({
            status: true,
            name: res.data.user[0].name,
            email: res.data.user[0].email,
            folder: res.data.user[0].folder,
            role: res.data.user[0].role ,
            lastname: res.data.user[0].lastname ,
            startdate: res.data.user[0].startdate 
            
          });
        //   user.name = res.data.user[0].name;
        //   user.email = res.data.user[0].email;
        //   user.folder = res.data.user[0].folder;
        //   user.role = res.data.user[0].role;
           
      }   
    });  

    let user = this.props.user;
    // console.log('user: ', user);
    // if ( user === '' ) {
    //     this.props.history.push('/login');
    // } else if (user.role === 'admin') {
    //     this.innerNav('courses');
    // }  else if (user.role === 'monthly'){
    //     this.innerNav('kidscourses');
    // } else if (user.role === 'futures'){
    //     this.innerNav('kidscourses');
    // } else {
    //     this.props.history.push('/login');
    // }
    if ( user === '' ) {
        this.props.history.push('/login');
    } else if (user.role === 'XoX_admin_oVo') {
        this.innerNav('courses');
    }  else if (user.role === ''){
        this.props.history.push('/login');
    } else  {
        this.innerNav('kidscourses');
    }  

    
};
render() { return(<div> {/*//////////////////////////////////////////////////*/}


<div className='column-ctn'>
    <div className='column'> 
        {/* <h5>{this.props}</h5> */}
        {  this.props.user.role === 'XoX_admin_oVo' ? <> 
            <div className= 'column-titles' onClick={()=>{this.innerNav('courses');}}> 
                <Link id='link' className={this.state.nav=='courses' ? 'column-titles-active' : 'column-titles' }>
                    <FaUserGraduate className={this.state.nav=='courses' ? 'img-column-active' : 'img-column' }/>
                    Courses 
                </Link>
            </div>
    
            <div className= 'column-titles' onClick={()=>{this.innerNav('calls');}}> 
                <Link id='link' className={this.state.nav=='calls' ? 'column-titles-active' : 'column-titles' }>
                    <BiVideoRecording className={this.state.nav=='calls' ? 'img-column-active' : 'img-column' }/>
                    Recorded Calls 
                </Link>
            </div> 

            <div className= 'column-titles' onClick={()=>{this.innerNav('homework');}}> 
                <Link id='link' className={this.state.nav=='homework' ? 'column-titles-active' : 'column-titles' }>
                    <FiHome className={this.state.nav=='homework' ? 'img-column-active' : 'img-column' }/>
                    Homework 
                </Link>
            </div> 

            <div className= 'column-titles' onClick={()=>{this.innerNav('students');}}> 
                <Link id='link' className={this.state.nav=='students' ? 'column-titles-active' : 'column-titles' }>
                    <BsPeopleFill className={this.state.nav=='students' ? 'img-column-active' : 'img-column' }/>
                    Students 
                </Link>
            </div> 

            <div className= 'column-titles' onClick={()=>{this.innerNav('onboarding');}}> 
                <Link id='link' className={this.state.nav=='onboarding' ? 'column-titles-active' : 'column-titles' }>
                    <ImUserPlus className={this.state.nav=='onboarding' ? 'img-column-active' : 'img-column' }/>
                    Onboarding 
                </Link>
            </div> 

            <div className= 'column-titles' onClick={()=>{this.innerNav('tutor');}}> 
                <Link id='link' className={this.state.nav=='tutor' ? 'column-titles-active' : 'column-titles' }>
                    <IoMdHelpCircleOutline id='img-help' className={this.state.nav=='tutor' ? 'img-column-active' : 'img-column' }/>
                    Tutorials 
                </Link>
            </div>  
        </> : <>
            <div className= 'column-titles' onClick={()=>{this.innerNav('kidscourses');}}> 
                <Link id='link' className={this.state.nav=='kidscourses' ? 'column-titles-active' : 'column-titles' }>
                    <FaUserGraduate className={this.state.nav=='kidscourses' ? 'img-column-active' : 'img-column' }/>
                    Courses
                </Link>
            </div> 

            <div className= 'column-titles' onClick={()=>{this.innerNav('kidscalls');}}> 
                <Link id='link' className={this.state.nav=='kidscalls' ? 'column-titles-active' : 'column-titles' }>
                    <BiVideoRecording className={this.state.nav=='kidscalls' ? 'img-column-active' : 'img-column' }/>
                    Recorded Calls
                </Link>
            </div> 
 

            <div className= 'column-titles' onClick={()=>{this.innerNav('kidshomework');}}> 
                <Link id='link' className={this.state.nav=='kidshomework' ? 'column-titles-active' : 'column-titles' }>
                    <FiHome className={this.state.nav=='kidshomework' ? 'img-column-active' : 'img-column' }/>
                    Homework
                </Link>
            </div> 

            <div className= 'column-titles' onClick={()=>{this.innerNav('kidsdashboard');}}> 
                <Link id='link' className={this.state.nav=='kidsdashboard' ? 'column-titles-active' : 'column-titles' }>
                    <RiDashboardLine className={this.state.nav=='kidsdashboard' ? 'img-column-active' : 'img-column' }/>
                    Dashboard
                </Link>
            </div> 

            <div className= 'column-titles' onClick={()=>{this.innerNav('kidstutor');}}> 
                <Link id='link' className={this.state.nav=='kidstutor' ? 'column-titles-active' : 'column-titles' }>
                    <IoMdHelpCircleOutline id='img-help' className={this.state.nav=='kidstutor' ? 'img-column-active' : 'img-column' }/>
                    Tutorials 
                </Link>
            </div>  
        </>}

        {/* <div className='column-titles'> 
            <AiFillSetting className='img-column'/>
    Settings 
        </div>   */}


{/* .ForgotP{
    position: relative;
    left: -20px;
    font-size: 14px;
    top: 2px;
    color: $mred;  
    text-decoration: none;
} */}
{/* <Link exact to='/reset_password'className="ForgotP">
Forgot Password?</Link> */}



    </div>

{this.state.nav=='courses' ? 
    <Courses/> :  
    this.state.nav=='students' ?
    <Students/> : 
    this.state.nav=='calls' ?
    <Calls/> : 
    this.state.nav=='homework' ?
    <Homework/> :  
    this.state.nav=='kidscourses' ?
    <KidCourses
        user={this.props.user}
    /> : 
    this.state.nav=='kidscalls' ?
    <KidCalls
        user={this.props.user} 
    /> : 
    this.state.nav=='kidshomework' ?
    <KidHomework
        user={this.props.user}
    /> : 
    this.state.nav=='kidsdashboard' ?
    <Student
        user={this.props.user}
    /> : 

    this.state.nav=='tutor' ?
    <Tutor /> : 
    this.state.nav=='kidstutor' ?
    <KidTutor /> : 

    this.state.nav=='onboarding' ?
    <Onboarding/> :  
    null 
}


 

</div>
</div> ) }}/////////////////////////////////////////////////////////////////////

