import React, { Component } from 'react';  

import { ReactComponent as YourSvg } from '../../content/images/traders.svg';
// import axios from 'axios';   

// var stripePromise;
 
// const getStripe = () => {
//     if (!stripePromise) {
//         stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
//     }
//     return stripePromise;
// }
// const item = = {
//     price: '',
//     quantity: 1
// }
// const checkoutOptions = {
//     lineItems : [item],
//     mode: 'payment',
//     successUrl: `${window.location.origin}/success`,
//     cancelUrl: `${window.location.origin}/cancel`
// }
// const redirectToCheckout = async () => {
//     console.log('redireting');
    
// }

import Switch from "react-switch";
import { 
    CardNumberElement, 
    CardExpiryElement, 
    CardCvcElement 
} from '@stripe/react-stripe-js';//CardElement

import axios from 'axios';
// import stripe from '../../../../routes/stripe';
// import { loadStripe } from '@stripe/stripe-js';

const cardOptions = {
    style: {
        base: {
            iconColor: '#000000',
            color: '#000000',
            fontWeight: '500',
            fontFamily: 'Montserrat, arial, sans-serif',
            fontSize: '13px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
            color: '#fce883',
            },
            '::placeholder': {
                color: '#d9cac5',
            },
        },
        invalid: {
            iconColor: '#de2629',
            color: '#de2629',
        },
    },
    // disabled: true,
    showIcon: true, 
}



export default class XoX extends Component { 
    constructor(props) { 
        super(props);    
        this.state = {   
            nameofcard: '',
            address: '',
            city: '',
            zip: '',
            state: '', 
            stripe: null,
            error: false,
} }/////////////////////////////////////////////////////////////////////////////
 
async updateCard() {
    if (this.state.error) {
        this.setState({error: false});
    }
    const { elements, stripe } = this.props; //, elements
    const cardElement = elements.getElement(CardNumberElement);
    var options = {
        name: this.state.nameofcard,
        address_line1: this.state.address,
        // address_line2: 'na',
        address_city: this.state.city,
        address_state: this.state.state,
        address_zip: this.state.zip,
        address_country: 'US',
        currency: 'usd'
      };

    var card_token_pre = await stripe.createToken(cardElement, options);
    if (card_token_pre.error) {
        this.setState({error: card_token_pre.error.message});
    } else {
        var card_tok = card_token_pre.token.id;
        var card_id = card_token_pre.token.card.id;
         
       
        var res = await axios.post('/stripe/updatecard', {
            card_tok: card_tok,
            card_id: card_id,
            cus_id: this.props.mystripe.cus_id,
            sub_id: this.props.mystripe.sub_id
        });
        if (res.data) {
            window.location.reload(false);
        } else {
            this.setState({error: 'Error occurred updating card please contact support@tradersultimateedge.com'});
        }
    } 
}

componentDidMount() {     
};
render() { return(<div> {/*//////////////////////////////////////////////////*/}

 

 

<div className='pay_card_cnt'>
    { this.state.error ? 
        <h1 className='pay_err'> {`Error: ${this.state.error}`} </h1>
    : null }
    <h1>Update Card</h1>
    
    
    <h2>  Card information </h2>
    




    {/* <input className='pay_input_email_l'
        type="text"  
        onChange={(e) => {
            this.setState({ username: e.target.value });
        }}
        value = {this.state.username?this.state.username:''}
        autocomplete="on"
        placeholder="2052 2052 2052 2052"
    /> */}
    <div className='pay_input_email'> 
        <div className='pay_stripe_card'>
            {/* <CardElement options={cardOptions}/> */}
            <CardNumberElement options={cardOptions}/> 
                
            
        </div>
    </div>

    <span className='flex pay_card_l'>
        <span className='pay_stripe_ex'>
            <CardExpiryElement options={cardOptions}/>
        </span>
        <span className='pay_stripe_cvc'>
            <CardCvcElement options={cardOptions}/> 
        </span>
    
        
            {/* <CardNumberElement options={cardOptions}/>  */}
        {/* <input className='pay_input_card_month'
            type="text"   
            onChange={(e) => {
                this.setState({ username: e.target.value });
            }}
            value = {this.state.username?this.state.username:''}
            autocomplete="on"
            placeholder="MM / YY"
        /> */}
        {/* <input className='pay_input_card_cvc'
            type="text"   
            onChange={(e) => {
                this.setState({ username: e.target.value });
            }}
            value = {this.state.username?this.state.username:''}
            autocomplete="on"
            placeholder="CVC"
        /> */}
    </span>

    <h2>    Name on card </h2>
    <input className='pay_input_name'
        type="text"  
        onChange={(e) => {
            this.setState({ nameofcard: e.target.value });
        }}
        value = {this.state.nameofcard?this.state.nameofcard:''}
        autocomplete="on"
        placeholder="First Last"
    />

    <h2>  Billing address </h2>
    <input className='pay_input_bill_address'
        type="text"  
        onChange={(e) => {
            this.setState({ address: e.target.value });
        }}
        value = {this.state.address?this.state.address:''}
        autocomplete="on"
        placeholder="Address"
    />
    <span className='flex pay_card'>
        <input className='pay_input_city'
            type="text"   
            onChange={(e) => {
                this.setState({ city: e.target.value });
            }}
            value = {this.state.city?this.state.city:''}
            autocomplete="on"
            placeholder="City"
        />
        <input className='pay_input_zip'
            type="text"   
            onChange={(e) => {
                this.setState({ zip: e.target.value });
            }}
            value = {this.state.zip?this.state.zip:''}
            autocomplete="on"
            placeholder="ZIP"
        />
    </span>
    <input className='pay_input_state'
        type="text"  
        onChange={(e) => {
            this.setState({ state: e.target.value });
        }}
        value = {this.state.state?this.state.state:''}
        autocomplete="on"
        placeholder="State"
    />
    <button onClick={()=>{ this.updateCard(); }}>  Update Card</button>
    

    
</div> 



</div> ) }}/////////////////////////////////////////////////////////////////////

