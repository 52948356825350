//imports
import React, { Component  } from 'react';   
// import imgperson from '../../content/images/person.png';
// import imglock from '../../content/images/lock.png'; 
import { FaLock, FaUser } from "react-icons/fa";    

import axios from 'axios'; 
import Recaptcha from 'react-recaptcha';
import { Link } from 'react-router-dom';  
//settings
axios.defaults.withCredentials=true;

//variables
let recaptchaInstance;
const { captchakey } = require('../../keys.json');

export default class Login extends Component {   
    constructor(props) {
        super(props);  
        // this.goLogin = this.goLogin.bind(this);  
        // this.removeInfo = this.removeInfo.bind(this);  

        this.state = { 
            username:  false, 
            password: false, 
            loginStatus: false, 
            loginbtn: 'Log In',
            username_err: false, 
            needCaptcha: false, 
            loadedcaptcha: false,

 
 
        };
        
    }

    async checkCredentials() {
        // let getip= await fetch('https://api.ipify.org/?format=json').
        // then( results => results.json());
        // let ip=getip.ip;  
        let res= await axios.post('/profile/login', {
            username: this.state.username,
            password: this.state.password
        })
        // console.log('res: ', res.data);
        if (res.data.auth) { 
            recaptchaInstance.reset(); 
            // console.log('msg:', res.data.msg[0]);

            this.setState({ loginStatus: true}); 
            this.props.updateInfo({
                status: true,
                name: res.data.msg[0].name,
                email: res.data.msg[0].email,
                folder: res.data.msg[0].folder,
                role: res.data.msg[0].role
            }); 
            this.props.history.push('/education') 
        } else { 
            recaptchaInstance.reset(); 
            this.setState({ loginStatus: false });  
            this.setState({ loginbtn: 'Log In'});
            this.setState({ username_err: res.data.msg}); 
            document.querySelector('input[type=text]').focus();  

            // let resp= await axios.get('/security/badlogin',{params: {ip: ip}})    
      
        } 
    }


    async goLogin(e) { 
        e.preventDefault();   
        if (true) {
        // if (this.state.loadedcaptcha) {
            await this.setState({ loginbtn: 'Logging in...'}); 
            document.querySelector('.btn-con input[type=submit]').focus();
           
        
            if (false) {
            // if (!this.state.finishedcaptcha) {
                document.querySelector('.hidden-btn input[type=text]').focus();  
                recaptchaInstance.execute(); 
            } else {
                await this.checkCredentials()
                this.setState({ finishedcaptcha: false});  
            }
        }

    };
  
    focusPassword(e) {
        document.querySelector('input[type=password]').focus(); 
        e.preventDefault(); 
    }
   
    testbutton = () => {
        axios.get('/profile/isUserAuth', {
            headers:{
                'x-access-token' : localStorage.getItem('token')
            },
        }).then((response) => {
            // console.log(response);
        });
    };
    sleep(sec) {
        return new Promise(resolve => setTimeout(resolve, sec*1000));
    }
    goSignupPage = () => {
        this.props.history.push('/signup') 
    }  
    loadedCaptcha() {
        this.setState({ loadedcaptcha: true });   
    }
    completedCaptcha() {
        this.setState({ finishedcaptcha: true}); 
        this.checkCredentials();
    }
    expiredCaptcha() {
        this.setState({ finishedcaptcha: false});
        recaptchaInstance.reset();    
    }  
    componentDidMount = () => { // run only once  
        // axios.get('/profile/login').then((res) =>{ 
        //     // console.log('res: ', res.data);
        //     if (res.data.loggedIn){    
        //         this.setState({ loginStatus: true });  
        //         this.props.updateInfo({
        //           status: true,
        //           name: res.data.user[0].name,
        //           email: res.data.user[0].email,
        //           folder: res.data.user[0].folder,
        //           role: res.data.user[0].role 
        //         });
        //         this.props.history.push('/education') ;
               
                 
        //     }   
        //   });  

        // axios.get('/profile/login').then((res) =>{ 
        //     if (res.data.loggedIn){
        //         this.setState({ loginStatus: true });    
        //         this.props.updateInfo({
        //             status: true,
        //             name: res.data.user[0].name,
        //             email: res.data.user[0].email}); 
        //         this.props.history.push('/profile') ;
        //     }
        // });   
        
        try { //if redirected here auto input email & pass
            if (this.props.location.state.email) {
                this.setState({ username: this.props.location.state.email});
            } 
            if (this.props.location.state.password) {
                this.setState({ password: this.props.location.state.password});
            }
        } catch {}
    };

    render() { 
        // {if (this.state.isRedirect) 
        //     { return <Redirect to={`/${this.state.isRedirect}`} push={true} /> };}
        // const { nick } = this.props; 
        return (
            
           
                <div className="loginBody"> 
                {/* <button onClick={()=>{this.test()}}>~test~</button> */}
                {/* <button onClick={()=>{this.updateInfo()}}>~XoX~</button>
                <button onClick={()=>{this.removeInfo()}}>~oVo~</button> */} 
 
                    <div className="loginUser">
                        <div className='input-title'>
                        Email: 
                                <div className='input-error'>
                                    {this.state.username_err?
                                    <error>{this.state.username_err}</error> : null
                                }</div>
                        </div> 
                        <div className="loginUserCon">
                        
                            {/* <img src={imgperson} alt='' ></img> */}
                            <FaUser/>
                            <form onSubmit={this.focusPassword} className='Username'> 
                                <input
                                    type="text"  autoFocus
                                    onChange={(e) => {
                                        this.setState({ username: e.target.value });
                                    }}
                                    value = {this.state.username?this.state.username:''}
                                    autocomplete="on"
                                />
                            </form>
                        </div>
                        <div className="PasswordA">
                            <div className="PasswordC">Password:</div>
                            
                            <Link exact to='/reset_password' className="ForgotP">
                                Forgot Password?</Link>
                                {/* <Link className="ForgotP" onClick= > Forgot Password?</Link> */}
                        </div>
                        <div className="loginUserCon">
                            {/* <img className="imgLock" src={imglock} alt=''></img> */}
                            <FaLock/>
                            <form onSubmit={(e)=>{this.goLogin(e)}} className='Password'> 
                                <input  type="password"
                                    onChange={(e) => {
                                        this.setState({ password: e.target.value });
                                    }} 
                                    value = {this.state.password?this.state.password:''}
                                    autocomplete="on"
                                 />
                            </form>
                        </div>  

                    <Recaptcha  
                        ref={e => recaptchaInstance = e}
                        sitekey= {captchakey}
                        render="explicit"
                        theme= "dark"
                        size= 'invisible'
                        badge = 'bottomright'
                        onloadCallback={()=>{this.loadedCaptcha()}}
                        verifyCallback={()=>{this.completedCaptcha()}}
                        expiredCallback={()=>{this.expiredCaptcha()}} 
                    />      
                        <div className="btn-con">
                            <form onSubmit={(e)=>{this.goLogin(e)}}> 
                            <input className="buttonLogin" type="submit" value={this.state.loginbtn} />
                            </form> 

                            {/* <form onSubmit={this.goSignupPage}> */}
                            {/* <input className="buttonSignup" type="submit" value="Sign Up" onClick={this.goSignupPage}/> */}
                            {/* </form> */} 
                            
                        </div> 
                        <div className="hidden-btn" >
                        <input className="hidden-input" type="text" />
                        </div>
                    </div>  
                </div>
             
        );
    }
}
