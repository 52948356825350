//imports
import React, { Component  } from 'react';    
import imglock from '../../content/images/lock.png';
import imgunlock from '../../content/images/unlock.png'; 
import { FaLock, FaUnlock } from "react-icons/fa";   
import axios from 'axios'; 
import Recaptcha from 'react-recaptcha'; 
//variables
let recaptchaInstance;
const { captchakey } = require('../../keys.json');

export default class Forgot extends Component {   
    constructor(props) {
        super(props);   

        this.state = {   
            repeatpassword: false,
            newpassword: false,
            repeatpassword_err: false,
            newpassword_err: false,
            email: '',
        };
        
    }
 
   
    testbutton = () => {
        axios.get('/profile/isUserAuth', {
            headers:{
                'x-access-token' : localStorage.getItem('token')
            },
        }).then((response) => {
            console.log(response);
        });
    }; 
    doNothing() {

    }
    goLogin(e) {
        e.preventDefault();
        var pass = this.state.newpassword ;
        var repass = this.state.repeatpassword ;
        var email = this.state.email ;
        if (pass === repass) {
            axios.post('/profile/updatepass', {
                token: 'nickxox',
                email: email,
                pass: pass 
            });

            this.props.history.push('/login');
        } else {
            this.setState({ newpassword_err: "Passwords don't Match" });

        }


    }
    async componentDidMount() { // run only once  
        try {
            var token = this.props.match.params.token ; 
            var res = await axios.get('/profile/isAuthed',{ params: {
                token : token 
            } });
            // console.log('res: ', res.data);
            // console.log('res22: ', res);
            var email = res.data ; 
            this.setState({ email: email });
            if (!email) {
                this.props.history.push('/login');
            }  
        } catch {
            this.props.history.push('/login');
        }
 
    };

    render() { 
      
        return ( 
                <div className="loginBody">  
                <div className="loginUser">

                    <div className='input-title'>
                    New Password: 
                            <div className='input-error'>
                                {this.state.newpassword_err?
                                <error>{this.state.newpassword_err}</error> : null
                            }</div>
                        </div> 
                        <div className="loginUserCon">  
                            {/* <img src={imgunlock} alt='' ></img>  */}
                            <FaUnlock/>
                            <form onSubmit={(e)=>{this.goLogin(e)}}> 
                                <input
                                    type="password"  autoFocus
                                    onChange={(e) => {
                                        this.setState({ newpassword: e.target.value }); }
                                    } />
                            </form> 
                        </div>

                        <div className='input-title'>
                    Repeat Password: 
                            <div className='input-error'>
                                {this.state.repeatpassword_err?
                                <error>{this.state.repeatpassword_err}</error> : null
                            }</div>
                        </div> 
                        <div className="loginUserCon">  
                            {/* <img src={imglock} alt='' ></img>  */}
                            <FaLock/>
                            <form onSubmit={(e)=>{this.goLogin(e)}}> 
                                <input
                                    type="password"  
                                    onChange={(e) => {
                                        this.setState({ repeatpassword: e.target.value }); }
                                    } />
                            </form> 
                        </div>

                        <div className="forgot-btn-con">
                            <form onSubmit={(e)=>{this.goLogin(e)}}> 
                            <input className="buttonLogin" type="submit" value='Set Password' />
                            </form>  
                            
                        </div> 


                    {/* <Recaptcha  
                        ref={e => recaptchaInstance = e}
                        sitekey= {captchakey}
                        render="explicit"
                        theme= "dark"
                        size= 'invisible'
                        badge = 'bottomright'
                        onloadCallback={()=>{this.doNothing()}}
                        verifyCallback={()=>{this.doNothing()}}
                        expiredCallback={()=>{this.doNothing()}} 
                    />        */}
                </div>
                </div>
             
        );
    }
}

