//imports
import React, { Component  } from 'react';    
import imgperson from '../../content/images/person.png';
import axios from 'axios'; 
import Recaptcha from 'react-recaptcha'; 
import validator from 'validator'; 
import {  FaUser } from "react-icons/fa"; 
//variables
let recaptchaInstance2;
const { captchakey } = require('../../keys.json');


export default class Reset extends Component {   
    constructor(props) {
        super(props);   

        this.state = {   
            email: '', 
            email_err: false,
            showmsg: false,
            buttontxt: 'Get Reset Link',
            isloadedcaptcha: false,
        };
        
    }
 
    goSendLink() {
        if (this.state.buttontxt === 'Loading...' && this.state.isloadedcaptcha ) {
            if (validator.isEmail(this.state.email)) { 
                // console.log('isEmail: ');
                // recaptchaInstance2.execute();  
                this.sendLink();
                this.setState({ email_err: false });
                this.setState({ showmsg: true });  
                this.setState({ buttontxt: 'Resend Link' });  
                document.querySelector('.hidden-input').focus(); 
            } else {
                // console.log('email_err: ');
    
                this.setState({ email_err: 'Invaid Email' });  
            }
        } 
    }
    testbutton = () => {
        axios.get('/profile/isUserAuth', {
            headers:{
                'x-access-token' : localStorage.getItem('token')
            },
        }).then((response) => {
            console.log(response);
        });
    }; 

sendLink() {
    let email = this.state.email ;
    axios.post('/profile/resetlink',{
        email: email,
        auth: 'nickxox'
    });

}
loadedCaptcha2() {
        console.log('laoded');
        // this.setState({ isloadedcaptcha: true });   
        // this.setState({ buttontxt: 'Get Reset Link' });   
    }
    completedCaptcha2() { 
        // console.log('isEmail: ');
        this.sendLink();
        this.setState({ email_err: false });
        this.setState({ showmsg: true });  
        this.setState({ buttontxt: 'Resend Link' });  
        document.querySelector('.hidden-input').focus(); 
        recaptchaInstance2.reset();
    }
    expiredCaptcha2() { 
        recaptchaInstance2.reset();    
    }  
    componentDidMount = () => { // run only once   
    //     this.loadedCaptcha2 = this.loadedCaptcha2.bind(this);
    //     this.completedCaptcha2 = this.completedCaptcha2.bind(this);
    //     this.expiredCaptcha2 = this.expiredCaptcha2.bind(this);
    };

    render() { 
      
        return (  
                <div className="reset-body">  
                <div className="loginUser">
               
                    <div className='input-title'>
                    Enter Email: 
                            <div className='input-error'>
                                {this.state.email_err?
                                <error>{this.state.email_err}</error> : null
                            }</div>
                        </div> 
                        <div className="loginUserCon">  
                            {/* <img src={imgperson} alt='' ></img>  */}
                            <FaUser/>
                            {/* <form onSubmit={(e)=>{this.goSendLink(e)}}>  */}
                                <input
                                    type="text"  autoFocus
                                    onChange={(e) => {
                                        this.setState({ email: e.target.value }); }
                                    } />
                            {/* </form>  */}
                        </div>


                        <div className="forgot-btn-con">
                            {/* <form onSubmit={(e)=>{this.goSendLink(e)}}> 
                            <input className="buttonLogin" type="submit" value={this.state.buttontxt} />
                            </form>   */}
                            <button  className="buttonLogin" onClick={()=>{this.goSendLink()}}>{this.state.buttontxt}</button>
                            
                        </div> 

                        <Recaptcha   className='2_ntues'
                            ref={e => recaptchaInstance2 = e}
                            sitekey= {captchakey}
                            render="explicit"
                            theme= "dark"
                            size= 'invisible'
                            badge = 'bottomright'
                            // onloadCallback={this.loadedCaptcha2}
                            // verifyCallback={this.completedCaptcha2}
                            // expiredCallback={this.expiredCaptcha2} 
                    />  


                    
                {this.state.showmsg ? 
                <div>
                    <p className='msg-alert'> A password reset link was sent to 
                    {` ${this.state.email}`}. 
                    Click the link in the email to create a new password. If you do 
                    not receive an email within 2 minutes, please click the re-send 
                    email link above. 
                    </p>  
                    <div className="forgot-btn-con">
                            <form onSubmit={(e)=>{this.props.history.push('/login') }}> 
                            <input className="buttonLogin" type="submit" value='Go Login' />
                            </form>  
                            
                    </div> 
                </div>
                :null}  
                
                </div>
                    <div className="hidden-div" >
                        <input className="hidden-input" type="text" />
                    </div>
                </div>
             
        );
    }
}

