import React, { Component } from 'react'; 
// import imgperson from '../../content/images/person.png';
// import imglock from '../../content/images/lock.png';
// import imgunlock from '../../content/images/unlock.png';
// import imgname from '../../content/images/name.png';
import { FaLock, FaUnlock } from "react-icons/fa";   
import { MdEmail } from "react-icons/md";   
import { BsFillPersonLinesFill } from "react-icons/bs";   


import Terms from './terms';



import validator from 'validator'; 
import Axios from 'axios'; 
import Recaptcha from 'react-recaptcha';
import axios from 'axios';
//variables
let recaptchaInstance; 
const { captchakey } = require('../../keys.json');

export default class SignUp extends Component {
    constructor(props) {
        super(props);
        this.focusSendCode = this.focusSendCode.bind(this); 
        this.focusConfirmPassword = this.focusConfirmPassword.bind(this); 
        this.focusSignupBtn = this.focusSignupBtn.bind(this); 
        this.focusEmail = this.focusEmail.bind(this); 
        this.focusPassword = this.focusPassword.bind(this); 
        this.goSignUp = this.goSignUp.bind(this);  
        this.sendCode = this.sendCode.bind(this);  
        this.checkUniqueEmail = this.checkUniqueEmail.bind(this);   
 
        this.state = { 
            preferredname: '',
            lastname: '',
            useremail: '',
            emailcode: '',
            password: '',
            phone: '',
            discord: '',
            tradingview: '',
            condfirmpassword: '',
            filteredphone: '',
            preferredname_err: false,
            useremail_err: false,
            emailcode_err: false,
            password_err: false,
            condfirmpassword_err: false,
            role_err: false,
            errormsg: false,
            myemailcode: 'the_code_generated',
            verifiedemail: 'the email to match the code',
            isuniqueemail: false, 
            loadedcaptcha: false,
            verifiedcaptcha: false,
            role: '',
            istermed: false,
            innertab: ''

} }/////////////////////////////////////////////////////////////////////////////
updatePhone(value) { 
    var newvalue = ''
    if (value.length<this.state.phone.length) {
        newvalue = value
    } else {
        if (value.length == 1) {
            if (parseInt(value) || value == 0 ) {
                newvalue = `(${value}`
            } else {
                newvalue = `(`
            }
        } else if (value.length == 2) {
            if (parseInt(value[1]) || value[1] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 3) {
            if (parseInt(value[2]) || value[2] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 4) {
            if (parseInt(value[3]) || value[3] == 0 ) {
                newvalue = `${value}) `
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 5) {
            if (parseInt(value[4]) || value[4] == 0 ) {
                newvalue = `${this.state.phone}) ${value[4]}`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 6) {
            if (parseInt(value[5]) || value[5] == 0 ) {
                newvalue = `${this.state.phone} ${value[5]}`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 7) {
            if (parseInt(value[6]) || value[6] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 8) {
            if (parseInt(value[7]) || value[7] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 9) {
            if (parseInt(value[8]) || value[8] == 0 ) {
                newvalue = `${value}-`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 10) {
            if (parseInt(value[9]) || value[9] == 0 ) {
                newvalue = `${this.state.phone}-${value[9]}`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 11) {
            if (parseInt(value[10]) || value[10] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 12) {
            if (parseInt(value[11]) || value[11] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 13) {
            if (parseInt(value[12]) || value[12] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 14) {
            if (parseInt(value[13]) || value[13] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else {
            if (value.length<this.state.phone.length) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        }
    }  
    var filteredphone = newvalue.replace('(','').replace(') ','').replace('-','');
    this.setState({ phone: newvalue });
    this.setState({ filteredphone: filteredphone });
}

async goToTerms() {
    // document.querySelector('.hidden-input').focus(); 
    await this.checkAll(); 
    await this.confirmForms(); 
    if (!this.state.errormsg) {
        this.innertab('terms');
    }
}
innertab(tab) {
    this.setState({ innertab: tab }); 

}
changeTerms() {
    this.setState({ istermed: !this.state.istermed }); 

} 
async checkAll() {
    if (this.state.preferredname.length<1) {
        this.setState({ preferredname_err: 'Your Name is empty' }); 
    } else {
        this.setState({ preferredname_err: false }); 
    }

    if (validator.isEmail(this.state.useremail)) { 
        await this.checkUniqueEmail();
        // console.log('isuniqueemail', this.state.isuniqueemail)
        if (this.state.isuniqueemail) {
            this.setState({ useremail_err: false }); 
        } else {
            this.setState({ useremail_err: 'Email already in use'}); 
        }
        
    } else {
        this.setState({ useremail_err: 'Invaid Email' }); 
    } 
        
    // if (this.state.emailcode===this.state.myemailcode && 
    //     this.state.verifiedemail===this.state.useremail) {
    //         this.setState({ emailcode_err: false }); 
    //     } else {
    //         this.setState({ emailcode_err: 'Invaid Code' }); 
    //     }
    this.passwordStrength();
    if (this.state.password===this.state.condfirmpassword) {
        this.setState({ condfirmpassword_err: false });
    } else {
        this.setState({ condfirmpassword_err: "Passwords don't match" });
    } 
};

confirmForms() {
    // console.log('role:', this.state.role_err);
    if (!this.state.preferredname_err && !this.state.useremail_err
        && !this.state.emailcode_err && !this.state.password_err
        && !this.state.condfirmpassword_err && !this.state.role_err
        ) {
        this.setState({ errormsg: false }); 
    } else {
        if (this.state.preferredname_err) {
        this.setState({ errormsg: this.state.preferredname_err });
        } else if (this.state.useremail_err) {
        this.setState({ errormsg: this.state.useremail_err });
        } else if (this.state.emailcode_err) {
        this.setState({ errormsg: this.state.emailcode_err });
        } else if (this.state.password_err) {
        this.setState({ errormsg: this.state.password_err });
        } else if (this.state.condfirmpassword_err) {
        this.setState({ errormsg: this.state.condfirmpassword_err });
        } else if (this.state.role_err) {
        this.setState({ errormsg: this.state.role_err });
        } 
    }   
};

async checkUniqueEmail() {    //xox
        let res= await Axios.post('/profile/uniqueemail', { email: this.state.useremail })
        // console.log('res:', res.data);
        if (res.data) { 
            // console.log('true:' );
            await this.setState({ isuniqueemail: true });
        } else {
            // console.log('false:' );
            await this.setState({ isuniqueemail: false });
        } 
        // await this.setState({ isuniqueemail: true });
};
async checkToken() {
    let token = this.props.match.params.token;
    // console.log('token:', token);
    // if ( token === 'uniqueID=Xccj9hWdChXhiEiCI9R3_XoX_AjN3guqEAtbNTWmVkHPC' ) {
    //     this.setState({ role: 'monthly' });
    // } else if ( token === 'uniqueID=d47kKnWEVKhYLj34lr7A_XoX_NfS4tX5Ja759MAVf3gFS' ) {
    //     this.setState({ role: 'futures' });
    // } else if ( token === 'uniqueID=fUyqprCpJO2rBVJ2itlo_XoX_NVkje7pcaIqnLNYsiiKm' ) {
    //     this.setState({ role: 'admin' }); 
    // } else {
    //     this.setState({ role_err: 'INVALID SIGNUP TOKEN' });
    // }
    let res = await axios.post('/managing/checktoken', {
        token: token,
        xox: 'nickxox'
    }); 
    if (!res.data) {
        this.setState({ role_err: 'INVALID SIGNUP TOKEN' });
    } else {
        this.setState({ role: res.data.name });  
    }

}
exeSignup(e) {
    e.preventDefault();   
    if (this.state.verifiedcaptcha) {
        this.goSignUp();
    } else {
        recaptchaInstance.execute(); 
    }

    
}

async goSignUp() { 
    document.querySelector('.hidden-input').focus(); 
    await this.checkAll();
    await this.checkToken();
    await this.confirmForms(); 
    if (!this.state.errormsg) {
        let res= await Axios.post('/profile/signup',{
            name: this.state.preferredname,
            lastname: this.state.lastname,
            email: this.state.useremail,
            password: this.state.password,
            role: this.state.role,
            phone: this.state.filteredphone,
            discord: this.state.discord,
            tradingview: this.state.tradingview,
            xox: 'nickxox'
        })
        // console.log('res  : ',res.data);

        if (res.data)  {
            this.props.history.push({
                pathname: '/login', 
                state: {
                    email: this.state.useremail, 
                    password: this.state.password
                }
            }) 
        } else {
            this.setState({ errormsg: 'Error Accrued' });
        }
    }  
}; 

passwordStrength() {
    var isSymbol = /[!@#$%^&*()_+\-=\[\]{':"\\|,.<>\/?]+/;
    var pass=  this.state.password;
    if (pass.length <9 ) {
        this.setState({ password_err: 'Password needs to be >= 7' });  
    } else if (!isSymbol.test( pass )) {
        this.setState({ password_err: 'Must contain 1 Symbol' }); 
    } else if (!/[A-Z]/.test(pass)) {
        this.setState({ password_err: 'Must have an 1 Upper Case' }); 
    } else if (!/[a-z]/.test(pass)) {
        this.setState({ password_err: 'Must have an 1 Lower Case' }); 
    } else if (!/\d/.test(pass)) {
        this.setState({ password_err: 'Must have an 1 Number' }); 
    } else {
        this.setState({ password_err: false }); 
    }
};



async sendCode(e) {  
    e.preventDefault(); 
    if (validator.isEmail(this.state.useremail)) { 
        await this.checkUniqueEmail();
        if (this.state.isuniqueemail) {
            document.querySelector('.input-code').focus(); 
            this.setState({ useremail_err: false }); 
            this.setState({ emailcode_err: false }); 
            await this.setState({ myemailcode: Math.floor(100000+ Math.random()* 900000)});   
            Axios.post('/profile/emailcode', {
                email: this.state.useremail,
                code: this.state.myemailcode
            }).then(
                res => { 
                    if (res.data==='bad') {
                        this.setState({ emailcode_err: 'Invaid Email'}); 
                        document.querySelector('.input-email').focus(); 
                    } else {
                        this.setState({ emailcode_err: false}); 
                        this.setState({ verifiedemail: this.state.useremail}); 
                        
                    }
                }
            ) 
        } else {
            this.setState({ useremail_err: 'Email already in use'});
            this.setState({ emailcode_err: 'Error'});
            document.querySelector('.input-email').focus(); 
        }
    } else {
        this.setState({ useremail_err: 'Invaid Email'});
        this.setState({ emailcode_err: 'Error'});
        document.querySelector('.input-email').focus(); 
    }  
};

focusEmail(e) {
    e.preventDefault(); 
    if (this.state.preferredname.length<1) {
        this.setState({ preferredname_err: 'Your Name is empty' }); 
        document.querySelector('input[type=text]').focus(); 
    } else {
        this.setState({ preferredname_err: false }); 
        document.querySelector('.input-email').focus(); 
    }
};
async focusSendCode(e) {
    e.preventDefault(); 
    if (validator.isEmail(this.state.useremail)) { 
        await this.checkUniqueEmail();
        if (this.state.isuniqueemail) {
            this.setState({ useremail_err: false }); 
            document.querySelector('.btn-send-code').focus(); 
        } else {
            this.setState({ useremail_err: 'Email already in use'}); 
            document.querySelector('.input-email').focus(); 
        } 
    } else {
        this.setState({ useremail_err: 'Invaid Email' }); 
        document.querySelector('.input-email').focus(); 
    } 
};
focusPassword(e) {
    e.preventDefault(); 
    if (this.state.emailcode===this.state.myemailcode) {
        this.setState({ emailcode_err: false }); 
        document.querySelector('input[type=password]').focus(); 
    } else {
        this.setState({ emailcode_err: 'Invaid Code' }); 
        document.querySelector('.input-code').focus(); 
    }
};
async focusConfirmPassword(e) {
    e.preventDefault();
    await this.passwordStrength();
    if (!this.state.password_err) {
        document.querySelector('.input-confirm-password').focus();
    } else {
        document.querySelector('input[type=password]').focus(); 
    }
        
    
};
focusSignupBtn(e) {
    e.preventDefault();  
    if (this.state.password===this.state.condfirmpassword) {
        this.setState({ condfirmpassword_err: false });
        try {
            document.querySelector('.buttonSignup').focus();   
        } catch {}
    } else {
        this.setState({ condfirmpassword_err: "Passwords don't match" });
        document.querySelector('.input-confirm-password').focus();
    }
};
    
loadedCaptcha() {
    this.setState({ loadedcaptcha: true });   
}
completedCaptcha() { 
    if (this.state.loadedcaptcha) {
        this.goSignUp();
        this.setState({ verifiedcaptcha: true });
    }
}
expiredCaptcha() { 
    recaptchaInstance.reset();    
    this.setState({ verifiedcaptcha: false });
} 
componentDidMount = () => { 
};
render() { return(<div> {/*//////////////////////////////////////////////////*/}

{ this.state.innertab === 'terms' ? 
    <Terms
        backTab={this.innertab.bind(this)} 
        changeTerms={this.changeTerms.bind(this)}
    />
: <div> 
                <div className="login-Body">
                    <div className="loginUser">
                    <div className='alert-error'>
                            {this.state.errormsg?
                                <error>{this.state.errormsg}</error> : null
                            }</div>
                        <div className='input-title'>
                    First Name: 
                            <div className='input-error'>
                                {this.state.preferredname_err?
                                <error>{this.state.preferredname_err}</error> : null
                            }</div>
                        </div> 
                        <div className="loginUserCon"> 
                        
                            {/* <img src={imgname} alt='' ></img>  */}
                            <BsFillPersonLinesFill className='login-img-name'/>
                            <form onSubmit={this.focusEmail}> 
                                <input
                                    type="text"  autoFocus
                                    onChange={(e) => {
                                        this.setState({ preferredname: e.target.value }); }  } 
                                    value={this.state.preferredname}
                                />
                            </form> 
                        </div>
                        <div className='input-title'>
                    Last Name: 
                            <div className='input-error'>
                                {this.state.preferredname_err?
                                <error>{this.state.preferredname_err}</error> : null
                            }</div>
                        </div> 
                        <div className="loginUserCon"> 
                        
                            {/* <img src={imgname} alt='' ></img>  */}
                            <BsFillPersonLinesFill className='login-img-name'/>
                            <form onSubmit={this.focusEmail}> 
                                <input
                                    type="text"  
                                    onChange={(e) => {
                                        this.setState({ lastname: e.target.value }); }
                                    } 
                                    value={this.state.lastname}
                                    />
                            </form> 
                        </div>

                        <div className='input-title'>
                    Phone Number:  
                        </div> 
                        <div className="loginUserCon"> 
                        
                            {/* <img src={imgname} alt='' ></img>  */}
                            <BsFillPersonLinesFill className='login-img-name'/>
                            <form onSubmit={this.focusEmail}> 
                                <input
                                    type="text"  
                                    onChange={(e) => {
                                        this.updatePhone(e.target.value);
                                    } } 
                                    value={this.state.phone}
                                    // placeholder='optional'
                                    />
                            </form> 
                        </div>

                        <div className='input-title'> 
                    Discord Name:  
                        </div> 
                        <div className="loginUserCon"> 
                        
                            {/* <img src={imgname} alt='' ></img>  */}
                            <BsFillPersonLinesFill className='login-img-name'/>
                            <form onSubmit={this.focusEmail}> 
                                <input
                                    type="text"  
                                    onChange={(e) => {
                                        this.setState({ discord: e.target.value }); }
                                    } 
                                    value={this.state.discord}
                                    placeholder='optional'
                                    />
                            </form> 
                            </div>

                        <div className='input-title'> 
                    TradingView Name:  
                        </div> 
                        <div className="loginUserCon"> 
                        
                            {/* <img src={imgname} alt='' ></img>  */}
                            <BsFillPersonLinesFill className='login-img-name'/>
                            <form onSubmit={this.focusEmail}> 
                                <input
                                    type="text"  
                                    onChange={(e) => {
                                        this.setState({ tradingview: e.target.value }); }
                                    } 
                                    value={this.state.tradingview}
                                    placeholder='optional'
                                    />
                            </form> 
                            </div>

                        <div className='input-title'>
                    Email: 
                            <div className='input-error'>
                                {this.state.useremail_err?
                                <error>{this.state.useremail_err}</error> : null
                            }</div>
                        </div>  
                        <div className="loginUserCon"> 
                            {/* <img src={imgperson} alt='' ></img>  */}
                            <MdEmail className='login-img-email'/>
                            <form onSubmit={this.focusSendCode}> 
                                <input className='input-email'
                                    type="text"  
                                    onChange={(e) => {
                                        this.setState({ useremail: e.target.value }); }
                                    } 
                                    value={this.state.useremail}
                                    />
                            </form> 
                        </div> 

                        {/* <div className='input-title'>
                    Email confirmation code: 
                            <div className='input-error'>
                                {this.state.emailcode_err?
                                <error>{this.state.emailcode_err}</error> : null
                            }</div>
                        </div>   
                        <div className="code-input"> 
                            <img src={imgunlock} alt='' ></img> 
                            <form onSubmit={this.focusPassword}>
                                <input  className='input-code'
                                    type="text"  
                                    onChange={(e) => {
                                        this.setState({ emailcode: parseInt(e.target.value) }); }
                                    } />
                            </form> 

                            <form onSubmit={this.sendCode}> 
                            <input className="btn-send-code" type="submit" value="Send" />
                            </form>
                        </div>  */}

                        <div className='input-title'>
                    Password:
                            <div className='input-error'>
                                {!this.state.password_err ? null :
                                 <error>{this.state.password_err}</error> 
                            }</div>
                        </div>   
                        <div className="loginUserCon">
                            {/* <img className="imgLock" src={imglock} alt=''></img>  */}
                            <FaUnlock/>
                            <form onSubmit={this.focusConfirmPassword}> 
                                <input  type="password"
                                    onChange={(e) => {
                                    this.setState({ password: e.target.value }); }
                                } 
                                value={this.state.password}
                                />
                            </form> 
 
                        </div> 

                        <div className='input-title'>
                    Confirm Password: 
                            <div className='input-error'>
                                {!this.state.condfirmpassword_err ? null:
                                 <error>{this.state.condfirmpassword_err}</error> 
                            }</div>
                        </div>   
                        <div className="loginUserCon"> 
                            {/* <img src={imglock} alt='' ></img>  */}
                            <FaLock/>
                            <form onSubmit={this.focusSignupBtn}> 
                                <input className='input-confirm-password'
                                    type="password"  
                                    onChange={(e) => {
                                        this.setState({ condfirmpassword: e.target.value }); }
                                    } 
                                value={this.state.condfirmpassword}
                                />
                            </form> 
                        </div>

                        { !this.state.istermed ? 
                            <div className="buttonC">   
                                <button className="rbtn-blue" onClick={()=>{ this.goToTerms('terms');} }>Read & Accept the Terms & Conditions</button>
                                 
                            </div>  
                        :
                            <div className="buttonC">  
                                <form onSubmit={(e)=>{this.exeSignup(e)}}> 
                                <input className="buttonSignup" type="submit" value="Sign Up" />
                                <input className="hidden-input" type="text" />
                                </form>
                            </div>   
                        }
                       
                    </div> 
                    <Recaptcha className='thesenuts'
                        ref={e => recaptchaInstance = e}
                        sitekey= {captchakey}
                        render="explicit"
                        theme= "dark"
                        size= 'invisible'
                        badge = 'bottomright'
                        onloadCallback={()=>{this.loadedCaptcha()}}
                        verifyCallback={()=>{this.completedCaptcha()}}
                        expiredCallback={()=>{this.expiredCaptcha()}}
                        
                    />     
                </div>
</div> }
</div> ) }}/////////////////////////////////////////////////////////////////////
