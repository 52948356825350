import React, { Component } from 'react';
import imgtrade from '../../../content/images/star.png'; 
import { AiFillSetting, AiOutlineEye } from 'react-icons/ai'; 
import { BiDuplicate } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi"; 
import Student from './student';
import axios from 'axios';

export default class Students extends Component {

    constructor(props) {

        super(props);

        this.state = {  
          studentpage: 'teacher',
          user: '',
          status: '',
          data: '',
        //   data: [
        //       {name: 'nick', email: 'star@xox.com' ,role : 'XoX_Monthly_dickheads_oVo' ,startdate: 'never'},
        //       {name: 'admin', email: 'admin@xox.com' ,role : 'XoX_admin_oVo' ,startdate: 'never'},
        //       {name: 'xox', email: 'xox@star.com' ,role : 'XoX_Futures_fuckers_oVo' ,startdate: 'never'}
        //     ],
        }

    }
    newpage(page) { 
      this.setState({ studentpage: page });
    }
    async getInfo(user) {
      await this.setState({ user: user });
      this.newpage('student');
    }

    async loadData(){
        let res = await axios.get('/profile/getallusers');
        if (!res.data ) {
              //returned an error
        } else {
          var data = res.data ; 
          for (let i = 0; i < data.length; i++) { 
            if ( data[i].startdate !== '0' ) {  
              let tt = new Date(Number(data[i].startdate));   
              let date = `${tt.getMonth() +1}/${tt.getDate()}/${tt.getFullYear()}`;
              data[i].startdate = date; 
            } 
          }
          this.setState({ data: data });
        }
        
    }
    checkboxMonthly(email) { 
        var status = this.state.status ;
        var ischecked = document.querySelectorAll(`.checkbox-${email}`)[0] ; 
        // console.log('ischecked: ', ischecked.checked);
        if (ischecked.checked) { 
            // console.log('statusbef: ',  status);
            let stat = status ;
            status = stat.replace(`${email}`, ''); 
            

            // console.log('statusafer: ',  status);
            ischecked.checked = false
        } else {
            
            status += email ;  
            ischecked.checked = true 
        } 
        this.setState({ status: status });  
    
    };
    updateChecks() { 
        let inprogress = document.querySelectorAll('.checkbox-inprogress')[0]; 
     
        this.state.data.forEach((student,index) => { 
            
            var check = document.querySelectorAll(`.checkbox-${student.email.replace('@','-').replace('.','-')}`)[0];  
            if ( this.props.status.includes(student.email.replace('@','-').replace('.','-')) ) {
                check.checked = true
                 
            } 
        });
        
    }

    goSave() {
        this.props.changeStatus(this.state.status);
        this.props.backTab('add');
    }

async  componentDidMount() { 
    await this.loadData();
    this.setState({ status: this.props.status }); 
    this.updateChecks(); 
  }
    render() { 
        return(
            <div>  
 
 
<div className='column-body'>

 



<div className="header-home">
    {/* <h1>Students</h1>  */}
            <input className="btn-blue" type="submit" value="Go Back" onClick={()=>{this.goSave();}}/>  
            <h1>   Students </h1> 
            <input className="btn-blue" type="submit" value="Save" onClick={()=>{this.goSave();}}/> 
    {/* <div> */}
    {/* <input className="btn-blue" type="submit" value="+ Create" />
    <input className="btn-blue" type="submit" value="Manage" /> */}
    {/* </div> */}
</div>
 
<div className='student-cnt'>
       
    <div className="main-body-table"> 
                
                <table>
                  <thead>
                    <tr className='tr-head'> 
                    
                      <th> Checked </th> 
                      <th> Name </th>  
                      <th> Email </th> 
                      <th> Role </th>
                    </tr>
                  </thead>
                  <tbody>
         
                  {this.state.data != ''? <>
                    { this.state.data.map(data =>  
           
                        <tr className='tr-table' > 
                            {data.name? <td onClick={()=>{ this.checkboxMonthly(data.email.replace('@','-').replace('.','-')); }} > 
                                <div>
                                    <input type='checkbox' className={`checkbox-${data.email.replace('@','-').replace('.','-')}`} 
                                        onClick={()=>{ this.checkboxMonthly(data.email.replace('@','-').replace('.','-')); }}/>  
                                </div>
                            </td> :null } 
                            {data.name? <td onClick={()=>{ this.checkboxMonthly(data.email.replace('@','-').replace('.','-')); }} >{data.name}</td>  :null } 
                            {data.name? <td onClick={()=>{ this.checkboxMonthly(data.email.replace('@','-').replace('.','-')); }} >{data.email}</td> :null } 
                            {data.name? <td onClick={()=>{ this.checkboxMonthly(data.email.replace('@','-').replace('.','-')); }} >
                              {`${data.role.replace('XoX_','').replace('_oVo', '').replaceAll('_',' ')}`}</td> :null }
                            
                            
                           
                        </tr>
                    )}
                  </> :null }
                  </tbody>
                </table>
                      
                         
        </div>


</div>


 


 

 
</div>

</div>
          
        )

    }

}

