import React, { Component } from 'react';
import imgtrade from '../../../content/images/star.png'; 
import { AiFillSetting, AiOutlineEye } from 'react-icons/ai'; 
import { BiDuplicate } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi"; 
import Student from './student';
import axios from 'axios'; 

export default class Students extends Component {

    constructor(props) {

        super(props);

        this.state = {  
          studentpage: 'teacher',
          user: '',
          roles: '',
          // roles: [{"name": "mtohm", "amount1": "", "amountm": "", "display": "mtohm", "showurl": false, "gostripe": false}, {"name": "Monthly_members", "amount1": "", "amountm": "", "display": "Monthly members", "showurl": false, "gostripe": false}, {"name": "futures2", "amount1": "", "amountm": "", "display": "futures222", "showurl": false, "gostripe": false}, {"name": "base", "amount1": "", "amountm": "", "display": "base", "showurl": false, "gostripe": false}, {"name": "admin", "amount1": "", "amountm": "", "display": "Admin", "showurl": false, "gostripe": false}, {"name": "nutes2", "amount1": "", "amountm": "", "display": "nutes", "showurl": false, "gostripe": false}, {"name": "XoX_Terrible_Role_For_no_body_oVo", "amount1": "", "amountm": "", "display": "Terrible Role For no body", "showurl": false, "gostripe": false}],
          data: '',
          // data: [
          //     {name: 'nick', email: 'star@xox.com' ,role : 'XoX_Monthly_oVo' ,startdate: 'never'},
          //     {name: 'admin', email: 'admin@xox.com' ,role : 'XoX_Admin_oVo' ,startdate: 'never'},
          //     {name: 'xox', email: 'xox@star.com' ,role : 'XoX_Futures_oVo' ,startdate: 'never'}
          //   ]
        }

    }

   changeRole(e,email) {
      var display = e.currentTarget.value ; 
      // console.log('display: ', display);
      var roles = this.state.roles ;
      for (let index = 0; index < roles.length; index++) {
        const role = roles[index];
        // console.log('ole.d: ', role.display);  
        if (role.display === display) {
            axios.post('/profile/updaterole',{
              email: email,
              role: role.name,
              token: 'nickxox'
            });
        }
        
      }

      
    }
    newpage(page) { 
      this.setState({ studentpage: page });
    }
    async getInfo(user) {
      await this.setState({ user: user });
      this.newpage('student');
    }

    async loadData(){
        let res = await axios.get('/profile/getallusers');
        if (!res.data ) {
              //returned an error
        } else {
          var data = res.data ; 
          for (let i = 0; i < data.length; i++) { 
            if ( data[i].startdate !== '0' ) {  
              let tt = new Date(Number(data[i].startdate));   
              let date = `${tt.getMonth() +1}/${tt.getDate()}/${tt.getFullYear()}`;
              data[i].startdate = date; 
            } 
          }
          this.setState({ data: data });
        }
        
    }
async componentDidMount() { 
    this.loadData();
    var res = await axios.get('/managing/getroles');
      // console.log('res: ', res.data); 
      await this.setState({ roles: res.data }); 
    
  }
    render() { 
        return(
            <div>  
 
 
<div className='column-body'>

{this.state.studentpage == 'student' ?    
  <Student
    newpage={this.newpage.bind(this)}
    user={this.state.user}
  />  

:<>



<div className="header-home">
    <h1>Students</h1> 
    <div>
    {/* <input className="btn-blue" type="submit" value="+ Create" />
    <input className="btn-blue" type="submit" value="Manage" /> */}
    </div>
</div>
 
<div className='student-cnt'>
       
    <div className="main-body-table"> 
                
                <table>
                  <thead>
                    <tr className='tr-head'> 
                      <th> Name </th>  
                      <th> Email </th> 
                      <th> Role </th>
                      <th> Date Joined </th> 
                    </tr>
                  </thead>
                  <tbody>
         
                  {this.state.data != ''? <>
                    { this.state.data.map(data =>  
           
                        <tr className='tr-table' > 
                            {data.name? <td onClick={()=>{ this.getInfo(data); }} >{data.name}</td>  :null } 
                            {data.name? <td onClick={()=>{ this.getInfo(data); }} >{data.email}</td> :null } 
                            {data.name? <td  >

                             
                            <select className="table-dropdown" onChange={(e)=>{ this.changeRole(e,data.email); }} >
                                  <option >{`${data.role.replace('XoX_','').replace('_oVo', '').replaceAll('_',' ')}`} </option>
                                  
                                  { this.state.roles !== '' ?  
                                    this.state.roles.map((role,index)=>(<> 
                                      {  role.name === 'XoX_admin_oVo' ? null :
                                      <option >{role.display}</option>
                                        // <div>
                                        //   <input type='checkbox' className={`checkbox-${role.name}`}  onClick={()=>{ this.checkboxMonthly(); }} /> 
                                        //   <label onClick={()=>{ this.checkboxMonthly(); }} >{role.display} </label>
                                        // </div>
                                      }
                                    </>))
                                  : null }


                                </select>
                              </td> :null }
                            {data.name? <td onClick={()=>{ this.getInfo(data); }} >{data.startdate}</td> :null } 
                            
                           
                        </tr>
                    )}
                  </> :null }
                  </tbody>
                </table>
                      
                         
        </div>


</div>


</> }


 

 
</div>

</div>
          
        )

    }

}

