import React, { Component } from 'react';  
import axios from 'axios';   
import ReactPlayer from 'react-player'; 
import { MdEmail } from "react-icons/md";   
import { BsFillPersonLinesFill } from "react-icons/bs";   
import validator from 'validator';   
import styles from '../../styles/freefooter.scss';
import logohead from "./../../content/images/TUEPennantSmall-1.png";
import logofoot from "./../../content/images/webinarlogopennant.png";

export default class XoX extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {  
            hidefollowup: false,
            id: false,
            dropdown: null,  

} }/////////////////////////////////////////////////////////////////////////////

followUp() {
    // this.setState({hidefollowup: true});
    // axios.post('/owner_/yesfollowup',{ 
    //     id: this.state.id,
    //     xox: 'nickxox'
    // }); 
    this.props.history.push('/contactme');
}
clickPlay() {
    // var myVideo = document.querySelector('video');
    // try{ 
    //     if (this.state.firststart) { 
    //         myVideo.play();
    //         this.setState({ firststart: false });
    //         // console.log('clickde this bitch');
    //     } else {
    //         // console.log('ekse');
    //     }
    // } catch(err) {
    //     // console.log('err:', err); 
    // } 
}
async clickVideo() {
    // console.log('clicked the viedoS');
    // await this.setState({ firststart: false });
}
dropdown(tab) {
    if (this.state.dropdown == tab) {
        this.setState({ dropdown: null });
    } else {
        this.setState({ dropdown: tab });
    } 
};
async componentDidMount() {    
    try {
        var token = this.props.match.params.token;
        var res = await axios.post('/managing/vidtoken',{
            token: token,
            xox: 'nickxox'
        });
        // console.log('token: ', token);
        if ( !res.data ) {
            // console.log('data: ', res.data);
            this.props.history.push('/free-webinar');
        } else {
            this.setState({ id: res.data.id });
            // console.log('data: ', res.data);
        }
    } catch(err) {
        // console.log('err: ', err);
        this.props.history.push('/free-webinar');
    }


    // document.addEventListener('keydown', () => {this.clickPlay('button');});
    // document.addEventListener('click', () => {this.clickPlay('click');});
    // document.addEventListener('touchstart', () => {this.clickPlay('touchstart');});

};
 
render() { return(<div className='perpagecss'> {/*//////////////////////////////////////////////////*/}


<div id="site-header">
<div id="headerbar">
	<div className="wrapper">
		
		<div className="custom-info">
			Have any questions? Email us at support@tradersultimateedge.com.		</div> 

		
			<nav className="top-navigator" itemscope="itemscope" itemtype="http://schema.org/SiteNavigationElement">
				
				<ul id="menu-top-menu" class="menu"><li id="menu-item-726" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-726"><a href="https://www.youtube.com/channel/UCdBFnxrkDNGODw0Y09qHAiQ">Youtube Page</a></li>
<li id="menu-item-440" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-440"><a href="https://www.facebook.com/tradersultimateedge">Facebook Page</a></li>
<li id="menu-item-438" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-438"><a href="https://www.tradingview.com/u/TradersUltimateEdge/">TradingView Profile</a></li>
<li id="menu-item-727" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-727"><a href="https://www.tradersedgeclassroom.com/login">Client Login</a></li>
</ul>
			</nav> 

			</div> 
</div>
</div>

{/* C:\users\nickb\desktop\python\stock\antony\tony\client\src\routes\Free\webfolder\TUEPennantSmall-1.png */}
<div className='head_cnt'> 
<img className="logo-retina_x" src={logohead} alt=''></img>
<div className='other_nav'>

<nav className='nav_wrapper'> 
<ul ><li className='remove_listdot'><a href="https://www.tradersultimateedge.com/">Home</a></li>
<li onMouseEnter={()=>{this.dropdown('whower');}} onMouseLeave={()=>{this.dropdown('whower');}}  className='remove_listdot' ><a href="https://www.tradersultimateedge.com/who-we-are/">Who we are</a>
    { this.state.dropdown === 'whower' ? 
        <ul className='other_dropdown'>
        <li ><a href="https://www.tradersultimateedge.com/who-we-are/">Overview</a></li>
        <li> <a href="https://www.tradersultimateedge.com/who-we-are/leadership-team/">Leadership</a></li>
        <li> <a href="https://www.tradersultimateedge.com/who-we-are/testimonials/">Testimonials</a></li>
        <li><a href="https://www.tradersultimateedge.com/who-we-are/frequently-asked-questions/">FAQ</a></li>
        </ul>
    : null }
</li>
<li onMouseEnter={()=>{this.dropdown('wedo');}} onMouseLeave={()=>{this.dropdown('wedo');}}  className='remove_listdot'><a href="https://www.tradersultimateedge.com/what-we-do/">What we do</a>
    { this.state.dropdown === 'wedo' ? 
        <ul className='other_dropdown'>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-324"><a href="https://www.tradersultimateedge.com/what-we-do/education/">Education</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-322"><a href="https://www.tradersultimateedge.com/what-we-do/mentorship/">Mentorship</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-434"><a href="https://www.tradersultimateedge.com/what-we-do/proprietary-software/">Proprietary Software</a></li>
        </ul>
    : null }
</li>
<li onMouseEnter={()=>{this.dropdown('program');}} onMouseLeave={()=>{this.dropdown('program');}}  className='remove_listdot'><a href="https://www.tradersultimateedge.com/education-programs/">Education Programs</a>
    { this.state.dropdown === 'program' ? 
        <ul className='other_dropdown'>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-367"><a href="https://www.tradersultimateedge.com/education-programs/basic-investing-and-trading/">Basic Investing and Trading</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-370"><a href="https://www.tradersultimateedge.com/education-programs/full-time-day-trader/">Full-Time Day Trader</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-369"><a href="https://www.tradersultimateedge.com/education-programs/building-an-income/">Building an Income</a></li>
        </ul>
    : null }

</li>
</ul>
</nav>

</div>
</div> 





<div className='freeweb_outter'>
    <div className="freeweb_header_x">
        {/* <h3 >There’s an <i>EASIER </i> way to make a living <i>!</i></h3>  */} 
        <h3 >Here’s my number one <i>secret </i> for getting an edge in the stock markets and making a <i>real</i> income<i>!</i></h3> 
    </div> 
    <div className='freeweb_innerbod_just'>  
            {/* <div className="freeweb_header">
                <h3 >There’s an easier way to make a living.</h3>

            </div>  */}
         <>
            {/* <div className="freeweb_header">
                <h3 >Join the freedom with us.</h3> 
            </div>  */}
            <div className='freeweb_vid2_cnt'>
                {/* <ReactPlayer url='/uploads/videos/FreeWebinar.mp4_163812664200222' 
                    width='100%' height='100%' controls={true} playing={true}
                    config={{ file: { 
                        attributes: {
                            controlsList: 'nodownload'
                        }
                    }}}
                />  */}
                <video id='autovideo' controls controlslist="nodownload"  width = '100%' height = '100%' 
                autoPlay muted onClick={()=>{this.clickVideo();}}
                    >
                    <source src='/uploads/videos/FreeWebinar_163812664200222.mp4' type='video/mp4'/> 
                    {/* <source src='/uploads/videos/nasa1_Trim.mp4' type='video/mp4'/>  */}
                </video>
            </div>
            { !this.state.hidefollowup ? 
                <div className='freeweb_btn_ctn'>
                    <button className='freeweb_btn' onClick={()=>{ this.followUp(); }}
                    >FOLLOW UP WITH ME!</button>
                </div>
            : 
                <div className="freeweb_header2">
                    <h3 >Thank you! We will be in contact shortly.</h3> 
                </div> 
            }          
        </> 
        
    </div>
</div>


<div id="site-footer">
    <div id="content-bottom-widgets">
        <div className="wrapper">
            <div className="row">
                <div className="columnsx columns-10">
                    <div id="text-3" className="widget widget_text">			
                        <div className="textwidget">
                            <div className="custom-infox">Have any questions? Email us at support@tradersultimateedge.com.</div>
                        </div>
                    </div>				
                </div>
                <div className="columnsx columns-2">
                    <div id="text-2" className="widget widget_text">			
                        <div className="textwidget">
                            <div className="logo-ft">
                                <img src={logofoot} alt=''></img>
                            </div>
                        </div>
                    </div>				
                </div> 
            </div> 
        </div>  
    </div>


	
	<div id="footer-content">
		<div className="wrapper">
						<div className="copyright">
	<div className="copyright-content">
		Copyright © 2021 Labrador Trading, LLP dba Trader's Ultimate Edge.<br/><br/>

ANY AND ALL MATERIAL OFFERED BY TRADER’S ULTIMATE EDGE AND IT’S INDIVIDUAL OWNERS AND INSTRUCTORS IS OFFERED FOR EDUCATIONAL AND GENERAL INFORMATIONAL PURPOSES ONLY. NO INVESTMENT ADVICE OFFERED. WE ARE NOT A FINANCIAL ADVISOR, SECURITIES BROKER-DEALER, OR REGISTERED ANALYST. CONSULT YOUR FINANCIAL ADVISOR BEFORE YOU DO ANYTHING.	</div>
	
</div>

		</div>
	</div>
	
</div> 


</div> ) }}/////////////////////////////////////////////////////////////////////

