/* eslint-disable */
import React, { Component } from 'react';  
import axios from 'axios';   
import ReactPlayer from 'react-player'; 
import { MdEmail } from "react-icons/md";   
import { BsFillPersonLinesFill } from "react-icons/bs";   
import validator from 'validator';   
import lightbulb from "./../../content/images/1f4a1.svg";
import logohead from "./../../content/images/TUEPennantSmall-1.png";
import logofoot from "./../../content/images/webinarlogopennant.png";
// import { ReactComponent as YourSvg } from './content/images/traders.svg';
import styles from '../../styles/freefooter.scss';

// const theme = require('../../styles/freefooter.scss'); 
export default class XoX extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {  
            preferredname: '',
            lastname: '',
            useremail: '',
            phone: '',
            filteredphone: '',
            preferredname_err: false,
            useremail_err: false,
            errormsg: false,
            verifiedemail: 'the email to match the code',
            isuniqueemail: false, 
            showvid: false,
            hidefollowup: false,
            id: false,
            firststart: true,
            dropdown: null, //ovo


} }/////////////////////////////////////////////////////////////////////////////
updatePhone(value) { 
    var newvalue = ''
    if (value.length<this.state.phone.length) {
        newvalue = value
    } else {
        if (value.length == 1) {
            if (parseInt(value) || value == 0 ) {
                newvalue = `(${value}`
            } else {
                newvalue = `(`
            }
        } else if (value.length == 2) {
            if (parseInt(value[1]) || value[1] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 3) {
            if (parseInt(value[2]) || value[2] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 4) {
            if (parseInt(value[3]) || value[3] == 0 ) {
                newvalue = `${value}) `
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 5) {
            if (parseInt(value[4]) || value[4] == 0 ) {
                newvalue = `${this.state.phone}) ${value[4]}`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 6) {
            if (parseInt(value[5]) || value[5] == 0 ) {
                newvalue = `${this.state.phone} ${value[5]}`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 7) {
            if (parseInt(value[6]) || value[6] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 8) {
            if (parseInt(value[7]) || value[7] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 9) {
            if (parseInt(value[8]) || value[8] == 0 ) {
                newvalue = `${value}-`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 10) {
            if (parseInt(value[9]) || value[9] == 0 ) {
                newvalue = `${this.state.phone}-${value[9]}`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 11) {
            if (parseInt(value[10]) || value[10] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 12) {
            if (parseInt(value[11]) || value[11] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 13) {
            if (parseInt(value[12]) || value[12] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 14) {
            if (parseInt(value[13]) || value[13] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else {
            if (value.length<this.state.phone.length) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        }
    }  
    var filteredphone = newvalue.replace('(','').replace(') ','').replace('-','');
    this.setState({ phone: newvalue });
    this.setState({ filteredphone: filteredphone });
}


async checkAll() {
    if (this.state.preferredname.length<1) {
        this.setState({ preferredname_err: 'Your Name is empty' }); 
    } else {
        this.setState({ preferredname_err: false }); 
    }

    if (validator.isEmail(this.state.useremail)) {   
        this.setState({ useremail_err: false }); 
    } else {
        this.setState({ useremail_err: 'Invaid Email' }); 
    } 
         
    
};

confirmForms() {
    // console.log('role:', this.state.role_err);
    if (!this.state.preferredname_err && !this.state.useremail_err
       
        ) {
        this.setState({ errormsg: false }); 
    } else {
        if (this.state.preferredname_err) {
        this.setState({ errormsg: this.state.preferredname_err });
        } else if (this.state.useremail_err) {
        this.setState({ errormsg: this.state.useremail_err });
        } 
    }   
};

  

async collectInfo() { 
    // // document.querySelector('.hidden-input').focus(); 
    await this.checkAll();
    this.confirmForms(); 
    if (!this.state.errormsg) { 
        var id = Date.now() ; 
        let base = {} ;
        base.firstname = this.state.preferredname;
        // base.lastname = this.state.lastname ; 
        base.email = this.state.useremail ; 
        // base.phone = this.state.phone ; 
        base.id =  id ; 
        base.followup =  'No' ; 
        
        // console.log('goin in dark ');
        var res = await axios.post('/owner_/createfollowup',{ 
            follow: base,
            xox: 'nickxox'
        });
        // console.log('res  : ',res.data);
        this.setState({showvid: true});
        this.setState({id: id});
 
    }  
    // this.setState({showvid: true});//xoxremove
}; 
 
secondButton() {
    document.querySelector('.freeweb_info_wrap').scrollIntoView({
        behavior: 'smooth',
        block: 'center'
    });
}
followUp() {
    this.setState({hidefollowup: true});
    axios.post('/owner_/yesfollowup',{ 
        id: this.state.id,
        xox: 'nickxox'
    }); 
}
clickPlay() {
    // var myVideo = document.querySelector('video');
    // try{ 
    //     if (this.state.firststart) { 
    //         myVideo.play();
    //         this.setState({ firststart: false });
    //         // console.log('clickde this bitch');
    //     } else {
    //         // console.log('ekse');
    //     }
    // } catch(err) {
    //     // console.log('err:', err); 
    // } 
}
async clickVideo() {
    // console.log('clicked the viedoS');
    // await this.setState({ firststart: false });
}
dropdown(tab) {
    if (this.state.dropdown == tab) {
        this.setState({ dropdown: null });
    } else {
        this.setState({ dropdown: tab });
    } 
};
componentDidMount() {    
    // document.addEventListener('keydown', () => {this.clickPlay('button');});
    // document.addEventListener('click', () => {this.clickPlay('click');});
    // document.addEventListener('touchstart', () => {this.clickPlay('touchstart');});

};
render() { return(<div className='perpagecss'> {/*//////////////////////////////////////////////////*/}
<div id="site-header">
<div id="headerbar">
	<div className="wrapper">
		
		<div className="custom-info">
			Have any questions? Email us at support@tradersultimateedge.com.		</div> 

		
			<nav className="top-navigator" itemscope="itemscope" itemtype="http://schema.org/SiteNavigationElement">
				
				<ul id="menu-top-menu" class="menu"><li id="menu-item-726" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-726"><a href="https://www.youtube.com/channel/UCdBFnxrkDNGODw0Y09qHAiQ">Youtube Page</a></li>
<li id="menu-item-440" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-440"><a href="https://www.facebook.com/tradersultimateedge">Facebook Page</a></li>
<li id="menu-item-438" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-438"><a href="https://www.tradingview.com/u/TradersUltimateEdge/">TradingView Profile</a></li>
<li id="menu-item-727" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-727"><a href="https://www.tradersedgeclassroom.com/login">Client Login</a></li>
</ul>
			</nav> 

			</div> 
</div>
</div>

{/* C:\users\nickb\desktop\python\stock\antony\tony\client\src\routes\Free\webfolder\TUEPennantSmall-1.png */}
<div className='head_cnt'> 
<img className="logo-retina_x" src={logohead} alt=''></img>
<div className='other_nav'>

<nav className='nav_wrapper'> 
<ul ><li className='remove_listdot'><a href="https://www.tradersultimateedge.com/">Home</a></li>
<li onMouseEnter={()=>{this.dropdown('whower');}} onMouseLeave={()=>{this.dropdown('whower');}}  className='remove_listdot' ><a href="https://www.tradersultimateedge.com/who-we-are/">Who we are</a>
    { this.state.dropdown === 'whower' ? 
        <ul className='other_dropdown'>
        <li ><a href="https://www.tradersultimateedge.com/who-we-are/">Overview</a></li>
        <li> <a href="https://www.tradersultimateedge.com/who-we-are/leadership-team/">Leadership</a></li>
        <li> <a href="https://www.tradersultimateedge.com/who-we-are/testimonials/">Testimonials</a></li>
        <li><a href="https://www.tradersultimateedge.com/who-we-are/frequently-asked-questions/">FAQ</a></li>
        </ul>
    : null }
</li>
<li onMouseEnter={()=>{this.dropdown('wedo');}} onMouseLeave={()=>{this.dropdown('wedo');}}  className='remove_listdot'><a href="https://www.tradersultimateedge.com/what-we-do/">What we do</a>
    { this.state.dropdown === 'wedo' ? 
        <ul className='other_dropdown'>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-324"><a href="https://www.tradersultimateedge.com/what-we-do/education/">Education</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-322"><a href="https://www.tradersultimateedge.com/what-we-do/mentorship/">Mentorship</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-434"><a href="https://www.tradersultimateedge.com/what-we-do/proprietary-software/">Proprietary Software</a></li>
        </ul>
    : null }
</li>
<li onMouseEnter={()=>{this.dropdown('program');}} onMouseLeave={()=>{this.dropdown('program');}}  className='remove_listdot'><a href="https://www.tradersultimateedge.com/education-programs/">Education Programs</a>
    { this.state.dropdown === 'program' ? 
        <ul className='other_dropdown'>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-367"><a href="https://www.tradersultimateedge.com/education-programs/basic-investing-and-trading/">Basic Investing and Trading</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-370"><a href="https://www.tradersultimateedge.com/education-programs/full-time-day-trader/">Full-Time Day Trader</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-369"><a href="https://www.tradersultimateedge.com/education-programs/building-an-income/">Building an Income</a></li>
        </ul>
    : null }

</li>
</ul>
</nav>

</div>
</div>


<div className='freeweb_outter'>
    <div className="freeweb_header_x">
        <h3 >There’s an <i>EASIER </i> way to make a living <i>!</i></h3> 
    </div> 
    <div className='freeweb_innerbod'>  
    { !this.state.showvid ? <>
            <div className="freeweb_vidp">
                <div className='freeweb_vid_cnt'>
                    {/* <ReactPlayer url='/uploads/videos/WebinarIntroParlor2.mp4_1639219393678222' 
                        width='100%' height='100%' controls={true} playing={true}
                        config={{ file: { 
                            attributes: {
                                controlsList: 'nodownload'
                            }
                        }}}
                    />  */}
                    <video id='autovideo' controls controlslist="nodownload"  width = '100%' height = '100%' 
                    autoPlay muted onClick={()=>{this.clickVideo();}}
                        >
                        <source src='/uploads/videos/WebinarIntroParlor2_1639219393678222.mp4' type='video/mp4'/> 
                        {/* <source src='/uploads/videos/nasa1_Trim.mp4' type='video/mp4'/>  */}
                    </video>
                </div>
                <div className="freeweb_wrapper">
                    {/* <p>My name is Anthony Garcia. I’m a father, husband&nbsp; and the Founder of Trader’s Ultimate Edge! Mind if I really introduce myself?</p>
                    <p>I used to be a truck driver for years.<b> In the video, to the right you’ll see the actual hard hat I wore <em>every day</em>.</b> That same hard hat was with me for years in the oil field. I worked on a frac crew and served in every job by dragging hoses through the mud and swinging a hammer for a living.</p>
                    <p>Let me tell you those jobs are not especially fun, safe, or easy and I knew there was a better way! I have been investing for fifteen years, and ten years ago I really started studying hard and learning technical analysis. I’ve finally <i>Cracked The Code. </i>I’ve figured out how to make a living with the <strong>freedom</strong> we all deserve!</p>
                    <p>I’ve figured out how to day trade, swing trade, and invest for a living. I will teach YOU how to <i>ACTUALLY</i> use the math the big boys show us to predict the movements of the markets and put the probabilities on your side.</p>
                    <p><img draggable="false" role="img" className="emoji" alt="💡" src="uploads/fonts/1f4a1.svg"/> Did you know that the market makers tip their hands? <img draggable="false" role="img" className="emoji" alt="🤔" src="uploads/fonts/1f914.svg"/></p>
                    <p><img draggable="false" role="img" className="emoji" alt="💡" src="uploads/fonts/1f4a1.svg"/> Did you know you can calculate WIN probabilities QUICKLY and EASILY with information available from any broker? <img draggable="false" role="img" className="emoji" alt="🤩" src="uploads/fonts/1f929.svg"/><img draggable="false" role="img" className="emoji" alt="👍" src="uploads/fonts/1f44d.svg"/></p>
                    <p><b>Just remember, Life is about working Smarter, Not Harder </b><img draggable="false" role="img" className="emoji" alt="😉" src="uploads/fonts/1f609.svg"/></p>
                    <p><b>That’s what I’d like to teach you for FREE, today!!</b></p>
                    <p><img draggable="false" role="img" className="emoji" alt="🛑" src="uploads/fonts/1f6d1.svg"/> <b>SPOTS ARE LIMITED!! </b><img draggable="false" role="img" className="emoji" alt="🛑" src="uploads/fonts/1f6d1.svg"/></p> */}
                    
                    <dd><b>What you'll be learning in the webinar:</b></dd>
                    <p><b><img draggable="false" role="img" className="emoji" alt="" src={lightbulb}/>
                    </b>  Information on the options chain that <b><i>Wall Street</i></b> doesn't want you to know<b><i>!</i></b></p>
                    <p><b><img draggable="false" role="img" className="emoji" alt="" src={lightbulb}/>
                    </b>  How to calculate probabilities with information from <b><i>Any Broker</i></b><b><i>!</i></b></p>
                    <p><b> <img draggable="false" role="img" className="emoji" alt="" src={lightbulb}/>
                    </b> The basics of a <b><i>Simple Strategy</i></b> that we use to make <b><i>Weekly Income</i> </b>from the markets<b><i>!</i></b></p>
                    {/* <p>&nbsp;</p>     */}
           
                </div>

            </div>
            <div className='freeweb_btn_ctn'>
                    <button className='freeweb_btn' onClick={()=>{ this.secondButton();} }
                    >Get your Free Webinar Today</button>
            </div>
        </> : null }
        {! this.state.showvid ? <>
            {/* <div className="freeweb_header">
                <h3 >There’s an easier way to make a living!</h3>

            </div>  */}
    
            <div className="freeweb_header">
                {/* <h3 >Click below to get access to my <strong>free</strong> webinar and start learning today!</h3> */}
                <h3>
                Enter your details below to get access to my free webinar and start learning today! 
                </h3>

            </div> 
            <div className="freeweb_header">
                <h3>
                For a limited time I'll send you a list of my top stocks for a long term investment to help get you started on the right path immediately! 
                 </h3>
            </div>
            <div className="freeweb_header">
                <h3>
                 That's right, we do everything from day trading to long term investing.
                </h3>
            </div>
            <div className="freeweb_info_wrap"> 
                        <div className="loginUser">
                            <ruby>Where should I send your video?</ruby>
                        
                        <div className='alert-error'>
                        
                                {this.state.errormsg?
                                    <error>{this.state.errormsg}</error> : null
                                }</div>
                            <div className='input-title'>
                        Name: 
                                <div className='input-error'>
                                    {this.state.preferredname_err?
                                    <error>{this.state.preferredname_err}</error> : null
                                }</div>
                            </div> 
                            <div className="loginUserCon"> 
                            
                                {/* <img src={imgname} alt='' ></img>  */}
                                <BsFillPersonLinesFill className='login-img-name'/>
                                <form onSubmit={this.focusEmail}> 
                                    <input
                                        type="text"  
                                        onChange={(e) => {
                                            this.setState({ preferredname: e.target.value }); }  } 
                                        value={this.state.preferredname}
                                    />
                                </form> 
                            </div>

                            {/* <div className='input-title'>
                        Last Name: 
                               
                            </div> 
                            <div className="loginUserCon"> 
                             
                                <BsFillPersonLinesFill className='login-img-name'/>
                                <form onSubmit={this.focusEmail}> 
                                    <input
                                        type="text"  
                                        onChange={(e) => {
                                            this.setState({ lastname: e.target.value }); }
                                        } 
                                        value={this.state.lastname}
                                        />
                                </form> 
                            </div> */}

                            {/* <div className='input-title'>
                        Phone Number:  
                            </div> 
                            <div className="loginUserCon"> 
                              
                                <BsFillPersonLinesFill className='login-img-name'/>
                                <form onSubmit={this.focusEmail}> 
                                    <input
                                        type="text"  
                                        onChange={(e) => {
                                            this.updatePhone(e.target.value);
                                        } } 
                                        value={this.state.phone}
                                        // placeholder='optional'
                                        />
                                </form> 
                            </div> */}

                    


                            <div className='input-title'>
                        Email: 
                                <div className='input-error'>
                                    {this.state.useremail_err?
                                    <error>{this.state.useremail_err}</error> : null
                                }</div>
                            </div>  
                            <div className="loginUserCon"> 
                                {/* <img src={imgperson} alt='' ></img>  */}
                                <MdEmail className='login-img-email'/>
                                <form onSubmit={this.focusSendCode}> 
                                    <input className='input-email'
                                        type="text"  
                                        onChange={(e) => {
                                            this.setState({ useremail: e.target.value }); }
                                        } 
                                        value={this.state.useremail}
                                        />
                                </form> 
                            </div> 

                            

                            {/* Your phone number is only for contacting you about Trader's Ultimate Edge. It will not be sold or used for any other purpose. You can opt out at any time. */}


                
                                {/* <div className="buttonC-f">   
                                    <button className="rbtn-blue-f" onClick={()=>{ this.collectInfo();} }>Show Webinar</button>
                                    <div className="buttonC">  
                                    
                                    <input className="hidden-input" type="text" />
                                    
                                </div>   
                                </div>   */}
                        
                        
                        </div> 
                        
                    </div>

            <div className='freeweb_btn_ctn'>
                <button className='freeweb_btn' onClick={()=>{ this.collectInfo();} }
                >Get Your Free Webinar Today</button>
            </div>
        </> : <>
            {/* <div className="freeweb_header">
                <h3 >Join the freedom with us.</h3> 
            </div>  */}
            <div className='freeweb_vid2_cnt'>
                {/* <ReactPlayer url='/uploads/videos/FreeWebinar.mp4_163812664200222' 
                    width='100%' height='100%' controls={true} playing={true}
                    config={{ file: { 
                        attributes: {
                            controlsList: 'nodownload'
                        }
                    }}}
                />  */}
                <video id='autovideo' controls controlslist="nodownload"  width = '100%' height = '100%' 
                autoPlay muted onClick={()=>{this.clickVideo();}}
                    >
                    <source src='/uploads/videos/FreeWebinar_163812664200222.mp4' type='video/mp4'/> 
                    {/* <source src='/uploads/videos/nasa1_Trim.mp4' type='video/mp4'/>  */}
                </video>
            </div>
            { !this.state.hidefollowup ? 
                <div className='freeweb_btn_ctn'>
                    <button className='freeweb_btn' onClick={()=>{ this.followUp(); }}
                    >FOLLOW UP WITH ME!</button>
                </div>
            : 
                <div className="freeweb_header2">
                    <h3 >Thank you! We will be in contact shortly.</h3> 
                </div> 
            }          
        </> }

    </div>
</div>
{/* ~~~~~~~~~footer */}
<div id="site-footer">
    <div id="content-bottom-widgets">
        <div className="wrapper">
            <div className="row">
                <div className="columnsx columns-10">
                    <div id="text-3" className="widget widget_text">			
                        <div className="textwidget">
                            <div className="custom-infox">Have any questions? Email us at support@tradersultimateedge.com.</div>
                        </div>
                    </div>				
                </div>
                <div className="columnsx columns-2">
                    <div id="text-2" className="widget widget_text">			
                        <div className="textwidget">
                            <div className="logo-ft">
                                <img src={logofoot} alt=''></img>
                            </div>
                        </div>
                    </div>				
                </div> 
            </div> 
        </div>  
    </div>


	
	<div id="footer-content">
		<div className="wrapper">
						<div className="copyright">
	<div className="copyright-content">
		Copyright © 2021 Labrador Trading, LLP dba Trader's Ultimate Edge.<br/><br/>

ANY AND ALL MATERIAL OFFERED BY TRADER’S ULTIMATE EDGE AND IT’S INDIVIDUAL OWNERS AND INSTRUCTORS IS OFFERED FOR EDUCATIONAL AND GENERAL INFORMATIONAL PURPOSES ONLY. NO INVESTMENT ADVICE OFFERED. WE ARE NOT A FINANCIAL ADVISOR, SECURITIES BROKER-DEALER, OR REGISTERED ANALYST. CONSULT YOUR FINANCIAL ADVISOR BEFORE YOU DO ANYTHING.	</div>
	
</div>

		</div>
	</div>
	
</div> 
</div> ) }}/////////////////////////////////////////////////////////////////////

