  
module.exports =  {  
    darkTheme() {
        let root = document.documentElement ; 
        root.style.setProperty('--background', 'rgb(40, 41, 58)');
        root.style.setProperty('--trim', 'rgb(54, 62, 85)');
        root.style.setProperty('--headerback', 'rgb(33, 33, 43)');
        root.style.setProperty('--headcolor', 'rgb(89, 121, 148)');
        root.style.setProperty('--backgray', 'rgb(21, 66, 110)'); 
        root.style.setProperty('--font-hover', '#7782b3');
        root.style.setProperty('--font', '#000000');
        root.style.setProperty('--active-button', '#332730');
        root.style.setProperty('--mpink', 'rgb(63, 50, 58)');
        root.style.setProperty('--mblue', 'rgb(0, 140, 255)');
        root.style.setProperty('--mlblue', 'rgb(0, 162, 255)'); 
        root.style.setProperty('--colhighcolor', 'rgb(23,144,244)');  
        root.style.setProperty('--quiztxt', 'rgb(0, 140, 255)'); 
        root.style.setProperty('--backgroundcoursepreview', 'rgb(53, 66, 90)'); 
        root.style.setProperty('--placeholder', 'rgb(53, 66, 90)'); 

 
    },
    lightTheme() {
        let root = document.documentElement ; 
        root.style.setProperty('--background', 'rgb(255,255,255)');
        root.style.setProperty('--trim', 'rgb(231, 231, 231)');
        root.style.setProperty('--headerback', 'rgb(248,248,250)');
        root.style.setProperty('--headcolor', 'rgb(44,56,71)');
        root.style.setProperty('--backgray', 'rgb(21, 66, 110)'); //rgb(24, 42, 66)
        root.style.setProperty('--font-hover', '#FCA2E0');
        root.style.setProperty('--font', '#F7F7F7');
        root.style.setProperty('--active-button', '#E81EBD');
        root.style.setProperty('--mpink', 'rgb(203,1,134)');
        root.style.setProperty('--mblue', 'rgb(23,144,244)');
        root.style.setProperty('--mlblue', 'rgb(203,236,255)');
        root.style.setProperty('--colhighcolor', 'rgb(10,24,32)'); 
        root.style.setProperty('--quiztxt', 'rgb(0,0,0)'); 
        root.style.setProperty('--backgroundcoursepreview', 'rgb(199,199,199)'); 
        root.style.setProperty('--placeholder', 'rgb(201, 197, 197)'); 
 
 
    }
 }
