import React, { Component } from 'react';  
import axios from 'axios';   
import ReactPlayer from 'react-player'; 
import { Link } from 'react-router-dom';  

export default class XoX extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {  
            allcalls: '',
} }/////////////////////////////////////////////////////////////////////////////
 
async loadAll() { 
    let res= await axios.get('/courses/loadcalls' ) 
    this.setState({ allcalls: res.data.reverse() }) 
    // console.log('out: ',res.data) 
}
componentDidMount() { 
    this.loadAll();   
}
render() { return(//////////////////////////////////////////////////////////////
<div className='column-body'>
        <div className="header-home">
            <h1>Recorded Calls</h1>  
        </div>

        <div className='cnt-calls-videos'>
            { this.state.allcalls !='' ? 
                this.state.allcalls.map((call,index)=>(<>
                    { call.status.includes(this.props.user.role) ? 
                        <div className='cnt-call'> 
                            <div className='vid-calls'>
                                {/* <ReactPlayer url={ `/uploads/videos/${call.filename}` } 
                                    width='100%' height='98.5%' controls={true}   
                                    config={{ file: { 
                                        attributes: {
                                          controlsList: 'nodownload'
                                        }
                                      }}}
                                />  */}
                                <video id='autovideo' controls controlslist="nodownload"  width = '100%' height = '98%' 
                                    //autoPlay muted  
                                >
                                    <source src={ `/uploads/videos/${call.filename}` }      type='video/mp4'/>  
                                </video>
                                
                            </div>
                            <div>
                                <div className='cnt-call-details'>
                                    <h1>{call.title}</h1>
                                    <p>{call.details}</p>
                                </div>
                                <div className='cnt-call-delete'>
                                    <div className='txt-call-date'>
                                        <p>{`Release date: ${call.date}`}</p>
                                    </div>
                                
                            
                                        
                                
                                </div>
                            </div> 
                        </div> 
                    : null }
                </>)) 
            : null } 
        </div> 
    </div> 
) }}////////////////////////////////////////////////////////////////////////////

