import React, { Component } from 'react';  
import axios from 'axios';  
// import { Link } from 'react-router-dom';  
import { FiTrash2 } from "react-icons/fi";  
import { AiFillCheckCircle } from 'react-icons/ai'; 
import { FaPencilAlt } from "react-icons/fa";   

export default class Homework extends Component { 
    constructor(props) { 
        super(props); 
        //base home date title role
        this.state = {  
            // new: -2,
            innertab: 'homework',
            // status: 'inprogress',
            title: '',
            index: '',
            questions: '',
            // questions: [
            //     { question: '' , answer: ''}
            // ],
            homework: [],
            // homework: [
            //     { id: 1, status: 'inprogress', title: 'NICK' , questions: [   { question: 'asdasd' , answer: 'asdasd'},{ question: 'asdaasdasd' , answer: 'asdasdaasd'} ]},
            //     { id: 2, status: 'inprogress', title: 'XoX'  , questions: [   { question: 'asdasd' , answer: 'asdasd'},{ question: 'asdaasdasd' , answer: 'asdasdaasd'} ]},
            //     { id: 3, status: 'inprogress', title: 'StarStarStarStasdasdasddasdsarStasdasdassdfsdfsdfsdfrStarStarStarStarStarStarStarStarStarStar' , questions: [   { question: 'asdasd' , answer: 'asdasd'},{ question: 'asdaasdasd' , answer: 'asdasdaasd'} ] }
            // ]
} }/////////////////////////////////////////////////////////////////////////////
changeInnerTab(tab) {
    this.setState({ innertab: tab });
}
async sendHomework() {
    var homebase = {};
    homebase.title = this.state.title ;
    homebase.date = new Date(Date.now()).toLocaleDateString() ;
    homebase.questions = this.state.questions ;

    axios.post('/takecourse/savehomework', {
        folder: this.props.user.folder,
        homebase: homebase
    });
    await this.loadAll();
    this.changeInnerTab('homework');   
}
 
updateHomeAnswers(index,value) { 
    var questions = this.state.questions ;  
    questions[index].answer = value ; 
    this.setState({ questions : questions });  
}

tabHomework(index) {
    let homework = this.state.homework[index] ;
    let title = homework.title ;
    let questions = homework.questions ; 
    // console.log('homework: ', homework);
    // console.log('questionswer: ', typeof homework.questions);

    this.setState({ title: title }); 
    this.setState({ index: index }); 
    this.setState({ questions: questions });
    this.changeInnerTab('thesenutes_aka_take_homework');  
}

async loadAll() { 
    let res= await axios.get('/courses/loadhomework' )

    let homework = res.data 
    homework.forEach((work,index)=>{
        homework[index].questions = JSON.parse(work.questions)  
    }) 

    var respons = await axios.get('/takecourse/gethomework', {params: { 
        folder: this.props.user.folder
    }}); 
    // console.log('respons: ', respons);
    // console.log('responsty: ',typeof respons);
    var newhomework = [] ;
    homework.forEach((work,index)=>{ 
        // console.log('status: ', work.status);
        if ( work.status.includes(this.props.user.email.replace('@','-').replace('.','-'))) {
            var base = {} ; 
            base.title = work.title ;
            base.questions = [];
            work.questions.forEach(ques => {
                var quesbase = {}
                quesbase.question = ques.question;
                quesbase.answer = '';
                quesbase.placeholder = ques.answer;
                base.questions.push(quesbase);
            }); 
            base.completed = false
            try {
                respons.data.forEach(completedwork =>{
                    if (completedwork.title === work.title) {
                        base.completed = true 
                        base.questions = completedwork.questions ; 
                    }
                });
            } catch {}

            newhomework.push(base);

        } 
         
    }); 
    this.setState({ homework: newhomework }) 
    // console.log('out: ',res.data) 
}
componentDidMount() {  
    this.loadAll();
};
render() { return(<div> {/*//////////////////////////////////////////////////*/}
{ this.state.innertab === 'homework' ? 
    <div className='column-body'>

        <div className="header-home">
            <h1> Homework </h1> 
        
        </div>

        <div className='ctn-homework'>
            {/* title/edit, edit preview delelte */}
            { this.state.homework.length !== 0 ? 
                this.state.homework.map((homework,index)=>( <div className='cnt-kidhomework'>
                    
                    <div className='cnt-each-homework'>
                        <div className='cnt-homework-title' onClick={()=>{ this.tabHomework(index); }} >   
                            { homework.title }
                        </div> 
                    
                        <div className='cnt-homework-btn' onClick={()=>{ this.tabHomework(index); }} >
                            <FaPencilAlt/>
                        </div>     
                    </div> 
                        { homework.completed ?
                            <AiFillCheckCircle className='img-check-homework' />
                        : null }  
                    </div>
                ))
            : null }
        </div> 
        
    </div> 
: 
<div>
{/* cnt-take-worksheet */}
<div className='column-body'>
    <div className='header-home' >
        <button className='btn-blue' onClick={()=>{ this.changeInnerTab('homework');  }}  >   Back </button>
        <h1> {this.state.title} </h1> 
        <button className='btn-hidden' > Back </button>
    </div>

    <div id='cnt-take-homework-question'>  
        { this.state.questions !== '' ? <> 
            { this.state.questions.map((question,index) => (<div id='cnt-worksheet-each'>   
                <div  id='cnt-take-quiz-question'> 
                    <dd> {`${index + 1}) ${question.question}`} </dd>
                </div> 

                <div className='input input-indent'>     
                    <input type="text" autoComplete="on" 
                        onChange={(e)=>{ this.updateHomeAnswers(index,e.target.value); }} 
                        placeholder= {question.placeholder}
                        value = { question.answer}
                        />
                </div>
            </div>))  }
        </> : null }
    </div>
    <div id='cnt-take-button'>    
    
        <button className='rbtn-green' onClick={ ()=>{ this.sendHomework(); } } >  Submit Homework </button>    
    </div>
</div>
</div>
}

</div> ) }}/////////////////////////////////////////////////////////////////////

