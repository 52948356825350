import React, { Component } from 'react';  
 
import { MdEmail } from "react-icons/md";   
import { BsFillPersonLinesFill } from "react-icons/bs";   

import validator from 'validator';   
import axios from 'axios';
import ReactPlayer from 'react-player'; 
//variables  

export default class Webinar extends Component {
    constructor(props) {
        super(props); 
 
        this.state = { 
            preferredname: '',
            lastname: '',
            useremail: '',
            phone: '',
            filteredphone: '',
            preferredname_err: false,
            useremail_err: false,
            errormsg: false,


            verifiedemail: 'the email to match the code',
            isuniqueemail: false, 
            
            showthanks: false,

            hidefollowup: false,
            id: false,


} }/////////////////////////////////////////////////////////////////////////////
updatePhone(value) { 
    var newvalue = ''
    if (value.length<this.state.phone.length) {
        newvalue = value
    } else {
        if (value.length == 1) {
            if (parseInt(value) || value == 0 ) {
                newvalue = `(${value}`
            } else {
                newvalue = `(`
            }
        } else if (value.length == 2) {
            if (parseInt(value[1]) || value[1] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 3) {
            if (parseInt(value[2]) || value[2] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 4) {
            if (parseInt(value[3]) || value[3] == 0 ) {
                newvalue = `${value}) `
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 5) {
            if (parseInt(value[4]) || value[4] == 0 ) {
                newvalue = `${this.state.phone}) ${value[4]}`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 6) {
            if (parseInt(value[5]) || value[5] == 0 ) {
                newvalue = `${this.state.phone} ${value[5]}`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 7) {
            if (parseInt(value[6]) || value[6] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 8) {
            if (parseInt(value[7]) || value[7] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 9) {
            if (parseInt(value[8]) || value[8] == 0 ) {
                newvalue = `${value}-`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 10) {
            if (parseInt(value[9]) || value[9] == 0 ) {
                newvalue = `${this.state.phone}-${value[9]}`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 11) {
            if (parseInt(value[10]) || value[10] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 12) {
            if (parseInt(value[11]) || value[11] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 13) {
            if (parseInt(value[12]) || value[12] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 14) {
            if (parseInt(value[13]) || value[13] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else {
            if (value.length<this.state.phone.length) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        }
    }  
    var filteredphone = newvalue.replace('(','').replace(') ','').replace('-','');
    this.setState({ phone: newvalue });
    this.setState({ filteredphone: filteredphone });
}


async checkAll() {
    if (this.state.preferredname.length<1) {
        this.setState({ preferredname_err: 'Your Name is empty' }); 
    } else {
        this.setState({ preferredname_err: false }); 
    }

    if (validator.isEmail(this.state.useremail)) {   
        this.setState({ useremail_err: false }); 
    } else {
        this.setState({ useremail_err: 'Invaid Email' }); 
    } 
         
    
};

confirmForms() {
    // console.log('role:', this.state.role_err);
    if (!this.state.preferredname_err && !this.state.useremail_err
       
        ) {
        this.setState({ errormsg: false }); 
    } else {
        if (this.state.preferredname_err) {
        this.setState({ errormsg: this.state.preferredname_err });
        } else if (this.state.useremail_err) {
        this.setState({ errormsg: this.state.useremail_err });
        } 
    }   
};

  

async collectInfo() { 
    document.querySelector('.hidden-input').focus(); 
    await this.checkAll();
    this.confirmForms(); 
    if (!this.state.errormsg) { 
        let date = Date.now() ; 
        let base = {} ;
        base.firstname = this.state.preferredname;
        base.lastname = this.state.lastname ; 
        base.email = this.state.useremail ; 
        base.phone = this.state.phone ; 
        base.date =  date ;  
         
        axios.post('/owner_/creatediscord',{ 
            discord: base,
            xox: 'nickxox'
        });

        // console.log('res  : ',res.data);
        this.setState({showthanks: true});
          
    }  
}; 

componentDidMount = () => { 
};
render() { return(<div> {/*//////////////////////////////////////////////////*/}
{ this.state.showthanks ?  <> 
    <div className="header-home-free">
    <h1>Just a couple of details and you're one step closer to freedom!</h1> 

</div>
    <div className="buttonC-v margin_top25">   
        <h2>Thank you! We will email you shortly.</h2> 
    </div> 


</>: <>
    <div className="header-home-free">
    <h1>Just a couple of details and you're one step closer to freedom!</h1> 

</div>
 <div> 

                <div className="login-Body">
                    <div className="loginUser">
                    <div className='alert-error'>
                            {this.state.errormsg?
                                <error>{this.state.errormsg}</error> : null
                            }</div>
                        <div className='input-title'>
                    First Name: 
                            <div className='input-error'>
                                {this.state.preferredname_err?
                                <error>{this.state.preferredname_err}</error> : null
                            }</div>
                        </div> 
                        <div className="loginUserCon"> 
                        
                            {/* <img src={imgname} alt='' ></img>  */}
                            <BsFillPersonLinesFill className='login-img-name'/>
                            {/* <form onSubmit={this.focusEmail}>  */}
                                <input
                                    type="text"  autoFocus
                                    onChange={(e) => {
                                        this.setState({ preferredname: e.target.value }); }  } 
                                    value={this.state.preferredname}
                                />
                            {/* </form>  */}
                        </div>
                        <div className='input-title'>
                    Last Name: 
                            {/* <div className='input-error'>
                                {this.state.preferredname_err?
                                <error>{this.state.preferredname_err}</error> : null
                            }</div> */}
                        </div> 
                        <div className="loginUserCon"> 
                        
                            {/* <img src={imgname} alt='' ></img>  */}
                            <BsFillPersonLinesFill className='login-img-name'/>
                            {/* <form onSubmit={this.focusEmail}>  */}
                                <input
                                    type="text"  
                                    onChange={(e) => {
                                        this.setState({ lastname: e.target.value }); }
                                    } 
                                    value={this.state.lastname}
                                    />
                            {/* </form>  */}
                        </div>

                        <div className='input-title'>
                    Phone Number:  
                        </div> 
                        <div className="loginUserCon"> 
                        
                            {/* <img src={imgname} alt='' ></img>  */}
                            <BsFillPersonLinesFill className='login-img-name'/>
                            {/* <form onSubmit={this.focusEmail}>  */}
                                <input
                                    type="text"  
                                    onChange={(e) => {
                                        this.updatePhone(e.target.value);
                                    } } 
                                    value={this.state.phone}
                                    // placeholder='optional'
                                    />
                            {/* </form>  */}
                        </div>

                  


                        <div className='input-title'>
                    Email: 
                            <div className='input-error'>
                                {this.state.useremail_err?
                                <error>{this.state.useremail_err}</error> : null
                            }</div>
                        </div>  
                        <div className="loginUserCon"> 
                            {/* <img src={imgperson} alt='' ></img>  */}
                            <MdEmail className='login-img-email'/>
                            {/* <form onSubmit={this.focusSendCode}>  */}
                                <input className='input-email'
                                    type="text"  
                                    onChange={(e) => {
                                        this.setState({ useremail: e.target.value }); }
                                    } 
                                    value={this.state.useremail}
                                    />
                            {/* </form>  */}
                        </div> 

                        

                        Your phone number is only for contacting you about Trader's Ultimate Edge. It will not be sold or used for any other purpose. You can opt out at any time.


               
                            <div className="buttonC-f">   
                                <button className="rbtn-blue-f" onClick={()=>{ this.collectInfo();} }>Discord Trial</button>
                                <div className="buttonC">  
                                
                                <input className="hidden-input" type="text" />
                                
                            </div>   
                            </div>  
                       
                       
                    </div> 
                    
                </div>
</div> </> }
</div> ) }}/////////////////////////////////////////////////////////////////////
