import React, { Component } from 'react';  
import axios from 'axios';   
import { FiTrash2 } from "react-icons/fi";  
import { GiTrade } from "react-icons/gi";  



export default class XoX extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {  
            showold: false,
            showfollowup: true, 
            // filtered: [
            //   {firstname: 'nick', lastname: 'asdasd', email: 'star@xox.com', phone: '(423) 504-2231' ,       date: 2638138162160, followup: 'No', old:'No'  },
            //   {firstname: 'admin', lastname: 'asdfsdasd', email: 'admin@xox.com', phone: '(423) 504-2231' , date:  1638136178412, followup: 'Yes' ,old:'Yes' },
            //   {firstname: 'xox', lastname: 'asdsdasd', email: 'xox@star.com', phone: '(423) 504-2231' , date: Date.now() , followup: 'No', old:'Yes' }
            // ],
            filtered: '',
} }/////////////////////////////////////////////////////////////////////////////
toSort(array, key) {
    return array.sort((a, b) => {
        var x = a[key]; var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}  
async loadAll() {
    var res = await axios.get('owner_/getdiscord');
    // console.log('res: ', res);
    var data = res.data ; 
    // var data = this.state.data ; 
    // var filtered = []; 
    // data.forEach((item,i) => { 
    //     var ind = item ;
    //     if ( item.id !== '0' ) {  
    //         let tt = new Date(Number(item.id));   
    //         let date = `${tt.getMonth() +1}/${tt.getDate()}/${tt.getFullYear()}`;
    //         // ind.date = date; 
    //         ind.datefor = date; 
    //     }  
    //     // if ( item.followup === 'Yes' ) {   
    //     //     
    //     // }  
    //     filtered.push(ind);
    // });
    // // console.log('data: ', data[0].date);
    
    // //to sort by id/date   
    // filtered = this.toSort(filtered, 'date'); 

    this.setState({ filtered: data });
}

keep(email) {   
    axios.post('owner_/keepdiscord',{
        email: email
    });
    let current = this.state.filtered;
    for (let x = 0; x < current.length; x++) { 
        if (current[x].email === email) {
            current[x].status = 'keep'
        } 
    };
    this.setState({ filtered: current });
}
componentDidMount() {    
    this.loadAll();
};
render() { return(<div> {/*//////////////////////////////////////////////////*/}


<div className='column-body'>
    <div className="header-home">
        <h1>Free Webinar</h1> 
        <div>
            {/* { this.state.showfollowup ? 
                <input className="btn-blue" type="submit" value="Show Follw Up Both" onClick={()=>{this.changeFollowUp();}}/> 
            :   <input className="btn-blue" type="submit" value="Only Show Follow Up" onClick={()=>{this.changeFollowUp();}}/> 
            }
            { this.state.showold ? 
                <input className="btn-blue" type="submit" value="Hide Old" onClick={()=>{this.changeOld();}}/> 
            :   <input className="btn-blue" type="submit" value="Show Old" onClick={()=>{this.changeOld();}}/> 
            } */}
        </div>
    </div>

    <div className='student-cnt'>
       
       <div className="main-body-table"> 
                   
            <table>
                <thead>
                <tr className='tr-head'> 
                    <th> First Name </th>  
                    <th> Last Name </th> 
                    <th> Eamil </th>
                    <th> Phone </th> 
                    <th> Discord Name </th>  
                    <th> Status </th> 
                    <th> Keep </th> 
                </tr>
                </thead>
                <tbody>
    
                {this.state.filtered != ''? <>
                { this.state.filtered.map(data =>  
        
                    <tr className='tr-table' > 
                        <td >{data.fname}</td>   
                        <td >{data.lname}</td>  
                        <td >{data.email}</td>  
                        <td >{data.phone}</td>  
                        <td >{data.discord_name}</td>  
                        <td >{data.status}</td>  
                        <td ><GiTrade className='img-delete-followup'
                            onClick={()=>{ this.keep(data.email); }}
                        /></td>  
                        {/* <td >{data.old}</td>   */}
                      
                        
                        
                    </tr>
                )}
                </> :null }
                </tbody>
            </table>
                         
                            
        </div>
   
   
   </div>

</div>

</div> ) }}/////////////////////////////////////////////////////////////////////

