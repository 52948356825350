import axios from 'axios';
import React, { Component } from 'react';

export default class Blog extends Component {

    constructor(props) { 
        super(props);
        this.getfile = this.getfile.bind(this);  
        this.uploadfile = this.uploadfile.bind(this);  

        this.state = {
            selectedfile: null,
            showimg: false,

        } 
    }
    getfile(e) { 
        this.setState({ selectedfile: e.target.files[0] });
    }

    async uploadfile(e) {
        e.preventDefault();
        var formData = new FormData();
        for (var key of formData.entries()) 
        {
            console.log(key[0] + ', ' + key[1])
        }
        this.setState({ showimg: true });
        console.log('in thie bitch')
        console.log(this.state.selectedfile)

        console.log('formData  :  ',formData)

        formData.append("file",this.state.selectedfile);
        console.log('formData  :  ',formData)
        for (var key of formData.entries()) 
        {
            console.log(key[0] + ', ' + key[1])
        }
        // try {
        //     const res = await axios.post('/blog/upload', {data: {formData} },
        //     {headers :{"Content-Type":"multipart/form-data"}});
        // console.log('res: ',res.data)

        // } catch(err) {
        //     console.log('rip: ',err)
        // }
        axios({
            method: "post",
            url: "/blog/upload",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(function (response) {
              //handle success
              console.log(response);
            })
            .catch(function (response) {
              //handle error
              console.log(response);
            });

    }

    render() {

        return(
            <div className='cnt-soon'>   
            <h1 className='txt-soon'>Coming Soon...</h1>
        </div>
        )

    }

}