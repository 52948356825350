import React, { Component } from 'react';  
import axios from 'axios';   
import { FiTrash2 } from "react-icons/fi";  
export default class XoX extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {  
            showold: false,
            showfollowup: true, 
            // data: [
            //   {firstname: 'nick', lastname: 'asdasd', email: 'star@xox.com', phone: '(423) 504-2231' ,       date: 2638138162160, followup: 'No', old:'No'  },
            //   {firstname: 'admin', lastname: 'asdfsdasd', email: 'admin@xox.com', phone: '(423) 504-2231' , date:  1638136178412, followup: 'Yes' ,old:'Yes' },
            //   {firstname: 'xox', lastname: 'asdsdasd', email: 'xox@star.com', phone: '(423) 504-2231' , date: Date.now() , followup: 'No', old:'Yes' }
            // ],
            // filtered: '',
            data: '',
            doublecheck: false,
            todel: '',
} }/////////////////////////////////////////////////////////////////////////////
download() { 
    // var data = [
    //     ['name1', 'city1', 'some other info'],
    //     ['name2', 'city2', 'more info']
    // ];
    var data = this.state.data ;
    // console.log('data: ', data);
  // Building the CSV from the Data two-dimensional array
  // Each column is separated by ";" and new line "\n" for next row
    var csvContent = 'First Name, Last Name, Email, Phone, Date, TradingView, Discord, Role, SmS, SmS Role\n';
    // data.forEach(function(infoArray, index) {
    //     var dataString = infoArray.join(',');
    //     console.log('dataString: ', dataString);
    //     csvContent += index < data.length ? dataString + '\n' : dataString;
    // });
    data.forEach((stud,index)=>{
        var dataString = ''; 
        // var first = true ;
        // for (const [key, value] of Object.entries(stud)) {
        //     if (first) {
        //         if (
        //             key === 'name' || 
        //             key === 'lastname' || 
        //             key === 'email' || 
        //             key === 'phone' || 
        //             key === 'date' || 
        //             key === 'tradingview' || 
        //             key === 'discord' || 
        //             key === 'sms_on' 
        //         ) {
        //             first = false;
        //             dataString += value ;
        //         }  

        //     } else {
        //         if (
        //             key === 'name' || 
        //             key === 'lastname' || 
        //             key === 'email' || 
        //             key === 'phone' || 
        //             key === 'date' || 
        //             key === 'tradingview' || 
        //             key === 'discord' || 
        //             key === 'sms_on' 
        //         ) {
        //             dataString += `,${value}`;
        //         } 
        //     } 
        // } 
        var dataString = `${stud.name},${stud.lastname},${stud.email},${stud.phone}\
            ,${stud.startdate},${stud.tradingview},${stud.discord}\
            ,${stud.role.replace('XoX_','').replace('_oVo', '').replaceAll('_',' ')}\
            ,${stud.sms_on},${stud.sms_role.replace('_',' & ')}`


        csvContent += index < data.length ? dataString + '\n' : dataString; 
    });
  
  // The download function takes a CSV string, the filename and mimeType as parameters
  // Scroll/look down at the bottom of this snippet to see how download is called
    var download = function(content, fileName, mimeType) {
        var a = document.createElement('a');
        mimeType = mimeType || 'application/octet-stream';
    
        if (navigator.msSaveBlob) { // IE10
            navigator.msSaveBlob(new Blob([content], {
                type: mimeType
            }), fileName);
        } else if (URL && 'download' in a) { //html5 A[download]
            a.href = URL.createObjectURL(new Blob([content], {
                type: mimeType
            }));
            a.setAttribute('download', fileName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            window.location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
        }
    } 
    download(csvContent, 'Students.csv', 'text/csv;encoding:utf-8');
}
async loadData(){
    let res = await axios.get('/profile/getallusers');
    if (!res.data ) {
          //returned an error
    } else {
      var data = res.data ; 
      for (let i = 0; i < data.length; i++) { 
        if ( data[i].startdate !== '0' ) {  
          let tt = new Date(Number(data[i].startdate));   
          let date = `${tt.getMonth() +1}/${tt.getDate()}/${tt.getFullYear()}`;
          data[i].startdate = date; 
        } 
      }
      this.setState({ data: data });
    }
    
}
doubleCheck(email) {
    this.setState({ doublecheck: true });
    this.setState({ todel: email });
};
nodouble() {
    this.setState({ doublecheck: false });
};
async goDelete() {
    await axios.post('owner_/delclient',{ 
        email: this.state.todel
    }); 
    this.setState({ doublecheck: false });
    window.location.reload(false);
};
componentDidMount() {    
    this.loadData();
};
render() { return(<div> {/*//////////////////////////////////////////////////*/}

{ this.state.doublecheck ?
    <div className='classconfirmfull'>
        <div className='classconfirm'>
            <h4> Are you sure?</h4>
            <span>
                <button className="btn-blue" onClick={()=>{ this.goDelete(); }}>Yes</button>
                <button className="btn-blue" onClick={()=>{ this.nodouble(); }}>No</button>
            </span>
            
        </div>
    </div>
: null }

<div className='column-body'>
    <div className="header-home">
        <h1>Students</h1> 
        <div>
            <input className="btn-blue" type="submit" value="Download" onClick={()=>{this.download();}}/>
            {/* { this.state.showfollowup ? 
                <input className="btn-blue" type="submit" value="Show Follw Up Both" onClick={()=>{this.changeFollowUp();}}/> 
            :   <input className="btn-blue" type="submit" value="Only Show Follow Up" onClick={()=>{this.changeFollowUp();}}/> 
            }
            { this.state.showold ? 
                <input className="btn-blue" type="submit" value="Hide Old" onClick={()=>{this.changeOld();}}/> 
            :   <input className="btn-blue" type="submit" value="Show Old" onClick={()=>{this.changeOld();}}/> 
            } */}
        </div>
    </div>

    <div className='student-cnt'>
       
       <div className="main-body-table"> 
                   
            <table>
                <thead>
                <tr className='tr-head'> 
                    <th> First Name </th>  
                    <th> Last Name </th> 
                    <th> Eamil  </th>
                    <th> Phone </th> 
                    <th> Date </th> 
                    <th> TradingView </th> 
                    <th> Discord </th> 
                    <th> Role </th> 

                    <th> SmS </th> 
                    <th> SmS Role </th> 
                   <th> Delete </th> 
                   {/*   email
name
role
lastname
sms_on
phone
tradingview
discord
sms_role
 */}
                </tr>
                </thead>
                <tbody>
    
                {this.state.data != ''? <>
                { this.state.data.map(data =>  
        
                    <tr className='tr-table' > 
                        <td >{data.name}</td>   
                        <td >{data.lastname}</td>  
                        <td >{data.email}</td>  
                        <td >{data.phone}</td>  
                        <td >{data.startdate}</td>  
                        <td >{data.tradingview}</td>  
                        <td >{data.discord}</td>  
                        <td >{`${data.role.replace('XoX_','').replace('_oVo', '').replaceAll('_',' ')}`}</td>  
                        <td >{data.sms_on}</td>  
                        <td >{`${data.sms_role.replace('_',' & ')}`}</td>  
                        <td onClick={()=>{ this.doubleCheck(data.email); }}> <FiTrash2 className='img-delete'/>  </td>    
                      
                        
                        
                    </tr>
                )}
                </> :null }
                </tbody>
            </table>
                         
                            
        </div>
   
   
   </div>

</div>

</div> ) }}/////////////////////////////////////////////////////////////////////


