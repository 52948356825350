import React, { Component } from 'react';  
import axios from 'axios';   
import { FiEdit } from "react-icons/fi";  


export default class OnBoard extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {  
            innertab: '', 
            display: '',
            amountm: '',
            amount1: '',
            url: '',
            editroleindex: '',
            sms_role: 'false',
            // roles: '',  
            roles: [
                { name: 'monthly_mem' , display: 'monthly mem' , gostripe: false , showurl: false },
                { name: 'monthly_mem2' , display: 'monthly mem2' , gostripe: true , showurl: false},
                { name: 'monthly_mem3' , display: 'monthly mem3' , gostripe: false , showurl: false}
            ],

} }/////////////////////////////////////////////////////////////////////////////
goEdit(index) {
    this.innertab('edit') ;
    var role = this.state.roles[index];
    this.setState({ display: role.display });     
    this.setState({ editroleindex: index });     
}
updateRole() {
    let display = this.state.display ;
    var roles = this.state.roles ;
    console.log('roles1: ', roles);
    roles[this.state.editroleindex].display = display ;
    console.log('roles2: ', roles);
  
    this.setState({ roles: roles });     
    this.setState({ display: '' });     
    this.setState({ editroleindex: '' });      
    this.innertab('');


    axios.post('/managing/updaterole', { 
        role: roles[this.state.editroleindex] 
    });
 
 

}
goSave() {
    let display = this.state.display ;
    let amountm = this.state.amountm ;
    let amount1 = this.state.amount1 ;
    let sms_role = this.state.sms_role ;
    // console.log('nick: ');
    let name = `XoX_${display.replaceAll(' ','_')}_oVo` ;
    // console.log('name: ', name); 
    let base = {} ;
    base.name = name ;
    base.display = display ;
    base.amountm = amountm ;
    base.amount1 = amount1 ;
    base.gostripe = false ;
    base.showurl = false ;
    base.sms_role = sms_role ;
    // console.log('before: '); 
    axios.post('/managing/addrole', { 
        role: base 
    });
    // console.log('after: '); 
    let roles = this.state.roles ;
    roles.push(base);
    this.setState({ roles: roles });     
    this.setState({ display: '' });     
    this.innertab('');

}

innertab(tab) {
    this.setState({ innertab: tab });     
}

updateStripe(index) {
    var roles = this.state.roles ;
    roles[index].gostripe = !roles[index].gostripe;
    this.setState({ roles: roles });   
}

async getToken(index) {  
    var roles = this.state.roles ;
    roles.forEach((role,indx) => {
        roles[indx].showurl = false ; 
    });
    roles[index].showurl = true ;
    this.setState({ roles: roles }); 

    let res = await axios.get('/managing/createsignuptoken', {params: {
        name: roles[index].name
    }});
    var url = '' ;
    if (!res.data) {
        url = 'Error'
    } else {
        url = `${window.location.origin}/signup/${res.data}` ;  
    } 
    this.setState({ url: url });  
    navigator.clipboard.writeText(url);
} 
changeSmsRole(value) { 
    if (value === 'None') {
        this.setState({ sms_role: 'false' }); 
    } else if (value === 'Both') {
        this.setState({ sms_role: 'income_daytrader' }); 
    } else if (value === 'Income Guys') {
        this.setState({ sms_role: 'income' }); 
    } else if (value === 'Day Traders') {
        this.setState({ sms_role: 'daytrader' }); 
    }    
}
async loadAll() {
    var res = await axios.get('/managing/getroles');
    this.setState({ roles: res.data }); 
}

componentDidMount() {    
    this.loadAll();
};
render() { return(<div> {/*//////////////////////////////////////////////////*/}

<div className='column-body'>

    { this.state.innertab === 'edit' ? <>
        <div className="header-home">
            <button className='rbtn-blue' onClick={()=>{ this.innertab(''); }}>Back</button>
            <h1> Add Role </h1>  
            <button className='btn-hidden' >Back</button> 
        </div>
        <div className="loginBody">
            <div className="login-store">
                <div className='input-title'>
                    Role:  
                </div> 
                <div className="loginUserCon"> 
                    <input
                        type="text"  autoFocus
                        onChange={(e) => {
                            this.setState({ display: e.target.value });
                        }}
                        value={this.state.display}
                        placeholder = 'Monthly Members'
                        autoComplete="on"
                    />
                   
                </div>

                {/* <div className='input-title'>
                    Monthly Amount:  
                </div> 
                <div className="loginUserCon"> 
                    <input
                        type="text"   
                        onChange={(e) => {
                            this.setState({ amountm: e.target.value });
                        }}
                        placeholder = '99'
                        autoComplete="on"
                    />
                   
                </div>


                <div className='input-title'>
                    One-Time Amount:  
                </div> 
                <div className="loginUserCon"> 
                    <input
                        type="text"   
                        onChange={(e) => {
                            this.setState({ amount1: e.target.value });
                        }}
                        placeholder = '999'
                        autoComplete="on"
                    />
                   
                </div> */}

                <div className='flexcenter'>
                    <button className='rbtn-blue' onClick={()=>{ this.updateRole(); }}>Update Role</button>
                </div>
               
            </div> 
        </div>
        
    </> 
    : this.state.innertab === 'add' ? <>
        <div className="header-home">
            <button className='rbtn-blue' onClick={()=>{ this.innertab(''); }}>Back</button>
            <h1> Add Role </h1>  
            <button className='btn-hidden' >Back</button> 
        </div>
        <div className="loginBody">
            <div className="login-store">
                <div className='input-title'>
                    Role:  
                </div> 
                <div className="loginUserCon"> 
                    <input
                        type="text"  autoFocus
                        onChange={(e) => {
                            this.setState({ display: e.target.value });
                        }}
                        placeholder = 'Monthly Members'
                        autoComplete="on"
                    />
                   
                </div>
                <div className='input-title'>
                    SmS Role:  
                </div> 
                <div className='rand-drop-store'>  
                    <select className="rand-dropdown"  onChange={(e)=>{ this.changeSmsRole(e.currentTarget.value); }}  >
                        <option>{`None`}</option>
                        <option >{`Both`}</option>      
                        <option >{`Day Traders`}</option>      
                        <option >{`Income Guys`}</option>      
                     
                    </select>
                </div>
                
                
                {/* <div className='input-title'>
                    Monthly Amount:  
                </div> 
                <div className="loginUserCon"> 
                    <input
                        type="text"   
                        onChange={(e) => {
                            this.setState({ amountm: e.target.value });
                        }}
                        placeholder = '99'
                        autoComplete="on"
                    />
                   
                </div>


                <div className='input-title'>
                    One-Time Amount:  
                </div> 
                <div className="loginUserCon"> 
                    <input
                        type="text"   
                        onChange={(e) => {
                            this.setState({ amount1: e.target.value });
                        }}
                        placeholder = '999'
                        autoComplete="on"
                    />
                   
                </div> */}

                <div className='flexcenter'>
                    <button className='rbtn-blue' onClick={()=>{ this.goSave(); }}>Add Role</button>
                </div>
               
            </div> 
        </div>
        
    </> : <>
        <div className="header-home">
            <h1> Onboarding </h1>  
            <button className='rbtn-blue'  onClick={()=>{ this.innertab('add'); }}>+ Add Role</button>
        </div>
        
        <div className='wrap-onboard-body' >

            { this.state.roles !== '' ? 

                this.state.roles.map((role,index)=>(<>

                    <div className='wrap-onboard-txt'   >
                        <div className='wrap-onboard-txt-title' onClick={()=>{ this.getToken(index); }} >
                            <p>   {role.display}  </p>
                        </div> 
                       
                        {/* <div className='wrap-onboard-txt-stripe' onClick={()=>{ this.updateStripe(index); }} >
                            { role.gostripe ? 
                                <p>  w/ stripe </p>
                            : <p>  w/o stripe </p>
                            }   
                        </div>  */}
                      
                        <div className='wrap-onboard-txt-edit' onClick={()=>{ this.goEdit(index); }} >
                            <FiEdit className='img-edit-role' />
                        </div> 
                        
                    </div> 
                   
                    { role.showurl ? 
                        <div className='wrap-onboard-token'   > 
                            
                                <p>{`Copied! URL: ${this.state.url}`} </p>
                            
                        </div>
                    : null }
                </>))
            : null }

        

        </div>
    </> }
</div>

</div> ) }}/////////////////////////////////////////////////////////////////////

