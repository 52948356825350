import React, { Component } from 'react';

export default class Assignment extends Component {

    constructor(props) {

        super(props);

        this.state = {
            data: [], 
        }

    }

    returnAssignment(e) { 
        e.preventDefault();   
        this.props.history.push('/education') 
    }

    render() {

        return(
        <div className='page-con'>
            <div className="header">
                <h1>Stock Market IQ Test</h1> 
            </div>
            <div className="main-body">

<p class="p-1">1 of 15) Which of the four investments has shown the highest returns historically?</p>
<p class="p-2"> Real Estate
 Stocks
 Bonds
 Cash </p> 

 <p class="p-1"> 2 of 15) Which feature do Exchange-Trade Funds (ETFs) have in common with mutual funds? </p>
<p class="p-2">  They can be traded at any time
 One investment purchases a group of assets
 They are tied to an index
 They are both deliberately confusing </p> 

<p class="p-1"> 3 of 15) How is the term Beta used to understand stock data? </p>
<p class="p-2">  It is a test to see if a stock will sell in the stock market
 It measures the volatility of a stock
 It measures the change in stock price compared to yesterday's closing price
 It indicates the total shares outstanding multiplied by the current stock price </p> 

<p class="p-1"> 4 of 15) What is the Sharpe Ratio Index? </p>
<p class="p-2">  A conservative way to purchase and sell stocks
 A way to spread out your stock sales
 A pen that helps you calculate stock prices
 A measure of risk that helps you select the right stocks </p> 

<p class="p-1"> 5 of 15) What does technical analysis look at for investment purposes? </p>
<p class="p-2">   A company's competition
 The price at which the stock trades
 A company's profit margins
 A company's revenue growth </p> 

<p class="p-1"> 6 of 15) What happens when an investor over-diversifies? </p>
<p class="p-2">  They make more money
 They are spread too thin
 They drive their money manager crazy
 They create high-risk scenarios </p> 

<p class="p-1"> 7 of 15) What is implied volatility? </p>
<p class="p-2">  Something that only impacts option writers
 A measure of how risk could occur
 A risk probability equation
 A calculation that compares the current market price and theoretical future value </p> 

<p class="p-1"> 8 of 15) What does the put versus call interest do? </p>
<p class="p-2">   Provide a measure of banking interest rates
 Indicate who is making money and losing money in options
 Measure overall market sentiment
 Offer a precise mathematical formula for pricing options </p> 

<p class="p-1"> 9 of 15) What partially defines a Bull Market? </p>
<p class="p-2">  Low stock prices
 Lack of investor confidence
 Stimulating energy drinks
 A positive outlook about the current business cycle </p> 

<p class="p-1"> 10 of 15) What are the dangers in choosing a short selling strategy? </p>
<p class="p-2">  You may end up with very high losses
 The bank holding your mortgage may not agree
 You may have to come up with money if share prices increase
 You may receive a margin call order. </p> 

<p class="p-1"> 11 of 15) What is the best course of action when faced with two stocks, one gaining value, the other losing value? </p>
<p class="p-2">   Become a deer in the headlights and do nothing
 Sell the losing stock and hold onto the winning stock
 Sell the winning stock, take the profit, and wait for the losing stock to regain its value
 Decide how to proceed based on your gut instincts </p> 

<p class="p-1"> 12 of 15) What are Trailing Stop Orders? </p>
<p class="p-2">   An immediate order to sell a security
 An order that comes with a set of conditions
 An order that falls behind the actions on the stock market
 An order that sets a distance between the market price and a stop order </p> 

 <p class="p-1"> 13 of 15) What is a Head and Shoulders pattern? </p>
<p class="p-2">  A bullish signal about prices.
 A pattern that is not often correct
 A pattern for identifying stocks that are about to rise
 A bearish signal that prices will fall after the pattern formation is done </p> 

<p class="p-1"> 14 of 15) What does arbitrage involve? </p>
<p class="p-2">   Price similarities in two different markets
 Price differences across two or more markets for the same investment
 A strategy that is risky and may not result in any profit
 Trading one stock for another very quickly </p> 

<p class="p-1"> 15 of 15) What can be learned from Warren Buffet's approach to investing? </p>
<p class="p-2">   Sell, sell, sell
 Skip the research
 Pay attention to rumors and pure market price indicators
 Use a buy and hold strategy </p> 
 

<form onSubmit={(e)=>{this.returnAssignment(e)}}> 
<input className="btn" type="submit" value="Submit" /> 
</form>
            </div>
        </div>
        )

    }

}



