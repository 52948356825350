import React, { Component } from 'react';  
 
import { MdEmail } from "react-icons/md";   
import { BsFillPersonLinesFill } from "react-icons/bs";   
import logohead from "./../../content/images/TUEPennantSmall-1.png";
import logofoot from "./../../content/images/webinarlogopennant.png";
import validator from 'validator';   
import axios from 'axios';
import ReactPlayer from 'react-player'; 
//variables  

export default class Webinar extends Component {
    constructor(props) {
        super(props); 
 
        this.state = { 
            preferredname: '',
            lastname: '',
            useremail: '',
            phone: '',
            filteredphone: '',
            preferredname_err: false,
            useremail_err: false,
            errormsg: false,
            verifiedemail: 'the email to match the code',
            isuniqueemail: false, 
            showvid: false,
            hidefollowup: false,
            id: false,
            dropdown: null, //ovo


} }/////////////////////////////////////////////////////////////////////////////
updatePhone(value) { 
    var newvalue = ''
    if (value.length<this.state.phone.length) {
        newvalue = value
    } else {
        if (value.length == 1) {
            if (parseInt(value) || value == 0 ) {
                newvalue = `(${value}`
            } else {
                newvalue = `(`
            }
        } else if (value.length == 2) {
            if (parseInt(value[1]) || value[1] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 3) {
            if (parseInt(value[2]) || value[2] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 4) {
            if (parseInt(value[3]) || value[3] == 0 ) {
                newvalue = `${value}) `
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 5) {
            if (parseInt(value[4]) || value[4] == 0 ) {
                newvalue = `${this.state.phone}) ${value[4]}`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 6) {
            if (parseInt(value[5]) || value[5] == 0 ) {
                newvalue = `${this.state.phone} ${value[5]}`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 7) {
            if (parseInt(value[6]) || value[6] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 8) {
            if (parseInt(value[7]) || value[7] == 0 ) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 9) {
            if (parseInt(value[8]) || value[8] == 0 ) {
                newvalue = `${value}-`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 10) {
            if (parseInt(value[9]) || value[9] == 0 ) {
                newvalue = `${this.state.phone}-${value[9]}`
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 11) {
            if (parseInt(value[10]) || value[10] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 12) {
            if (parseInt(value[11]) || value[11] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 13) {
            if (parseInt(value[12]) || value[12] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else if (value.length == 14) {
            if (parseInt(value[13]) || value[13] == 0 ) {
                newvalue =  value
            } else {
                newvalue = this.state.phone
            }
        } else {
            if (value.length<this.state.phone.length) {
                newvalue = value
            } else {
                newvalue = this.state.phone
            }
        }
    }  
    var filteredphone = newvalue.replace('(','').replace(') ','').replace('-','');
    this.setState({ phone: newvalue });
    this.setState({ filteredphone: filteredphone });
}

dropdown(tab) {
    if (this.state.dropdown == tab) {
        this.setState({ dropdown: null });
    } else {
        this.setState({ dropdown: tab });
    } 
};
async checkAll() {
    if (this.state.preferredname.length<1) {
        this.setState({ preferredname_err: 'Your Name is empty' }); 
    } else {
        this.setState({ preferredname_err: false }); 
    }

    if (validator.isEmail(this.state.useremail)) {   
        this.setState({ useremail_err: false }); 
    } else {
        this.setState({ useremail_err: 'Invaid Email' }); 
    } 
         
    
};

confirmForms() {
    // console.log('role:', this.state.role_err);
    if (!this.state.preferredname_err && !this.state.useremail_err
       
        ) {
        this.setState({ errormsg: false }); 
    } else {
        if (this.state.preferredname_err) {
        this.setState({ errormsg: this.state.preferredname_err });
        } else if (this.state.useremail_err) {
        this.setState({ errormsg: this.state.useremail_err });
        } 
    }   
};

  

async collectInfo() { 
    document.querySelector('.hidden-input').focus(); 
    await this.checkAll();
    this.confirmForms(); 
    if (!this.state.errormsg) { 
        var id = Date.now() ; 
        let base = {} ;
        base.firstname = this.state.preferredname;
        base.lastname = this.state.lastname ; 
        base.email = this.state.useremail ; 
        base.phone = this.state.phone ; 
        base.id =  id ; 
        base.followup =  'No' ; 
        
   
        await axios.post('/owner_/createfollowup',{ 
            follow: base,
            xox: 'nickxox'
        });
        // console.log('res  : ',res.data);
        this.setState({showvid: true});
        this.setState({id: id});
        this.followUp();
    }  
}; 
followUp() {
    this.setState({hidefollowup: true});
    axios.post('/owner_/yesfollowup',{ 
        id: this.state.id,
        xox: 'nickxox'
    }); 
}
async collectInfo2() {
    await this.collectInfo();
    // this.setState({showvid: true});
    
}

componentDidMount = () => { 
};
render() { return(<div  className='perpagecss'> {/*//////////////////////////////////////////////////*/}
<div id="site-header">
<div id="headerbar">
	<div className="wrapper">
		
		<div className="custom-info">
			Have any questions? Email us at support@tradersultimateedge.com.		</div> 

		
			<nav className="top-navigator" itemscope="itemscope" itemtype="http://schema.org/SiteNavigationElement">
				
				<ul id="menu-top-menu" class="menu"><li id="menu-item-726" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-726"><a href="https://www.youtube.com/channel/UCdBFnxrkDNGODw0Y09qHAiQ">Youtube Page</a></li>
<li id="menu-item-440" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-440"><a href="https://www.facebook.com/tradersultimateedge">Facebook Page</a></li>
<li id="menu-item-438" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-438"><a href="https://www.tradingview.com/u/TradersUltimateEdge/">TradingView Profile</a></li>
<li id="menu-item-727" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-727"><a href="https://www.tradersedgeclassroom.com/login">Client Login</a></li>
</ul>
			</nav> 

			</div> 
</div>
</div>

{/* C:\users\nickb\desktop\python\stock\antony\tony\client\src\routes\Free\webfolder\TUEPennantSmall-1.png */}
<div className='head_cnt'> 
<img className="logo-retina_x" src={logohead} alt=''></img>
<div className='other_nav'>

<nav className='nav_wrapper'> 
<ul ><li className='remove_listdot'><a href="https://www.tradersultimateedge.com/">Home</a></li>
<li onMouseEnter={()=>{this.dropdown('whower');}} onMouseLeave={()=>{this.dropdown('whower');}}  className='remove_listdot' ><a href="https://www.tradersultimateedge.com/who-we-are/">Who we are</a>
    { this.state.dropdown === 'whower' ? 
        <ul className='other_dropdown'>
        <li ><a href="https://www.tradersultimateedge.com/who-we-are/">Overview</a></li>
        <li> <a href="https://www.tradersultimateedge.com/who-we-are/leadership-team/">Leadership</a></li>
        <li> <a href="https://www.tradersultimateedge.com/who-we-are/testimonials/">Testimonials</a></li>
        <li><a href="https://www.tradersultimateedge.com/who-we-are/frequently-asked-questions/">FAQ</a></li>
        </ul>
    : null }
</li>
<li onMouseEnter={()=>{this.dropdown('wedo');}} onMouseLeave={()=>{this.dropdown('wedo');}}  className='remove_listdot'><a href="https://www.tradersultimateedge.com/what-we-do/">What we do</a>
    { this.state.dropdown === 'wedo' ? 
        <ul className='other_dropdown'>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-324"><a href="https://www.tradersultimateedge.com/what-we-do/education/">Education</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-322"><a href="https://www.tradersultimateedge.com/what-we-do/mentorship/">Mentorship</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-434"><a href="https://www.tradersultimateedge.com/what-we-do/proprietary-software/">Proprietary Software</a></li>
        </ul>
    : null }
</li>
<li onMouseEnter={()=>{this.dropdown('program');}} onMouseLeave={()=>{this.dropdown('program');}}  className='remove_listdot'><a href="https://www.tradersultimateedge.com/education-programs/">Education Programs</a>
    { this.state.dropdown === 'program' ? 
        <ul className='other_dropdown'>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-367"><a href="https://www.tradersultimateedge.com/education-programs/basic-investing-and-trading/">Basic Investing and Trading</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-370"><a href="https://www.tradersultimateedge.com/education-programs/full-time-day-trader/">Full-Time Day Trader</a></li>
        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-369"><a href="https://www.tradersultimateedge.com/education-programs/building-an-income/">Building an Income</a></li>
        </ul>
    : null }

</li>
</ul>
</nav>

</div>
</div>

<>
{ !this.state.showvid ? <>   
    <div className="header-home-free">
    {/* <h1>Just a couple of details and you're one step closer to freedom!</h1>  */}
    <div>
         
    </div>
</div>
 <div>  

                <div className="login-Body">
                    <div className="loginUser">
                    <div className='alert-error'>
                            {this.state.errormsg?
                                <error>{this.state.errormsg}</error> : null
                            }</div>
                        <div className='input-title'>
                    First Name: 
                            <div className='input-error'>
                                {this.state.preferredname_err?
                                <error>{this.state.preferredname_err}</error> : null
                            }</div>
                        </div> 
                        <div className="loginUserCon"> 
                        
                            {/* <img src={imgname} alt='' ></img>  */}
                            <BsFillPersonLinesFill className='login-img-name'/>
                            <form onSubmit={this.focusEmail}> 
                                <input
                                    type="text"  autoFocus
                                    onChange={(e) => {
                                        this.setState({ preferredname: e.target.value }); }  } 
                                    value={this.state.preferredname}
                                />
                            </form> 
                        </div>
                        <div className='input-title'>
                    Last Name: 
                            {/* <div className='input-error'>
                                {this.state.preferredname_err?
                                <error>{this.state.preferredname_err}</error> : null
                            }</div> */}
                        </div> 
                        <div className="loginUserCon"> 
                        
                            {/* <img src={imgname} alt='' ></img>  */}
                            <BsFillPersonLinesFill className='login-img-name'/>
                            <form onSubmit={this.focusEmail}> 
                                <input
                                    type="text"  
                                    onChange={(e) => {
                                        this.setState({ lastname: e.target.value }); }
                                    } 
                                    value={this.state.lastname}
                                    />
                            </form> 
                        </div>

                        <div className='input-title'>
                    Phone Number:  
                        </div> 
                        <div className="loginUserCon"> 
                        
                            {/* <img src={imgname} alt='' ></img>  */}
                            <BsFillPersonLinesFill className='login-img-name'/>
                            <form onSubmit={this.focusEmail}> 
                                <input
                                    type="text"  
                                    onChange={(e) => {
                                        this.updatePhone(e.target.value);
                                    } } 
                                    value={this.state.phone}
                                    // placeholder='optional'
                                    />
                            </form> 
                        </div>

                  


                        <div className='input-title'>
                    Email: 
                            <div className='input-error'>
                                {this.state.useremail_err?
                                <error>{this.state.useremail_err}</error> : null
                            }</div>
                        </div>  
                        <div className="loginUserCon"> 
                            {/* <img src={imgperson} alt='' ></img>  */}
                            <MdEmail className='login-img-email'/>
                            <form onSubmit={this.focusSendCode}> 
                                <input className='input-email'
                                    type="text"  
                                    onChange={(e) => {
                                        this.setState({ useremail: e.target.value }); }
                                    } 
                                    value={this.state.useremail}
                                    />
                            </form> 
                        </div> 

                        

                        Your phone number is only for contacting you about Trader's Ultimate Edge. It will not be sold or used for any other purpose. You can opt out at any time.


               
                            <div className="buttonC-f">   
                                <button className="rbtn-blue-f" onClick={()=>{ this.collectInfo2();} }>Contact Me</button>
                                <div className="buttonC">  
                                
                                <input className="hidden-input" type="text" />
                                
                            </div>   
                            </div>  
                       
                       
                    </div> 
                    
                </div>
</div>
</>:<>
    <div className="header-home-free">
        <h1>Congratulations!</h1>  
    </div>
    <div className="login-Body">
        <div className="loginUser">
             <div className='cnt-contactme'>
            We will contact you shortly. <br/>  
            <p>Find more information at: <br/></p>
            <a href="https://www.tradersultimateedge.com/">Traders Ultimate Edge</a>
            </div>
        </div>
    </div>
</>}
</> 

{/* ~~~~~~~~~footer */}
<div id="site-footer">
    <div id="content-bottom-widgets">
        <div className="wrapper">
            <div className="row">
                <div className="columnsx columns-10">
                    <div id="text-3" className="widget widget_text">			
                        <div className="textwidget">
                            <div className="custom-infox">Have any questions? Email us at support@tradersultimateedge.com.</div>
                        </div>
                    </div>				
                </div>
                <div className="columnsx columns-2">
                    <div id="text-2" className="widget widget_text">			
                        <div className="textwidget">
                            <div className="logo-ft">
                                <img src={logofoot} alt=''></img>
                            </div>
                        </div>
                    </div>				
                </div> 
            </div> 
        </div>  
    </div>


	
	<div id="footer-content">
		<div className="wrapper">
						<div className="copyright">
	<div className="copyright-content">
		Copyright © 2021 Labrador Trading, LLP dba Trader's Ultimate Edge.<br/><br/>

ANY AND ALL MATERIAL OFFERED BY TRADER’S ULTIMATE EDGE AND IT’S INDIVIDUAL OWNERS AND INSTRUCTORS IS OFFERED FOR EDUCATIONAL AND GENERAL INFORMATIONAL PURPOSES ONLY. NO INVESTMENT ADVICE OFFERED. WE ARE NOT A FINANCIAL ADVISOR, SECURITIES BROKER-DEALER, OR REGISTERED ANALYST. CONSULT YOUR FINANCIAL ADVISOR BEFORE YOU DO ANYTHING.	</div>
	
</div>

		</div>
	</div>
	
</div> 
</div> ) }}/////////////////////////////////////////////////////////////////////
