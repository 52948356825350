import React, { Component } from 'react';
import axios from 'axios'; 
import imgtrade from '../../../content/images/star.png'; 
import { AiFillSetting, AiOutlineEye } from 'react-icons/ai'; 
import { BiDuplicate } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";  
import Create from './create';
import Preview from './preview';
import { Link } from 'react-router-dom'; 
// courses:  [
//       {
//       ID: 151,
//       status: 'inprogress',
//       title: 'asd',
//       synopsis: '',
//       description: '',
//       img: '1632323972968_clouds.jpg',
//       unit: '[{"num":1,"title":"","lessons":[{"num":1,"title":"","content":{"builder":"","video":"","quiz":[{"question":"","answers":[{"correct":false,"answer":""}]}],"worksheet":{"title":"","questions":[{"question":"","placeholder":""}]},"download":""}}]}]'
//     },
//       {
//       ID: 152,
//       status: 'inprogress',
//       title: 'sa',
//       synopsis: '',
//       description: '',
//       img: '',
//       unit: '[{"num":1,"title":"","lessons":[{"num":1,"title":"","content":{"builder":"","video":"","quiz":[{"question":"","answers":[{"correct":false,"answer":""}]}],"worksheet":{"title":"","questions":[{"question":"","placeholder":""}]},"download":""}}]}]'
//     }
//   ]
export default class Courses extends Component {

    constructor(props) {

        super(props);

        this.state = {  
            innernav: 'courses',
            alert: '',
            editID: null,
            courses: '', 
            isimgURL: false,
            course: '',
            // nick: {2: 'thse', 22: 'nutes', 222: 'bitch'}
            // courses: [
            //     {title: 'these'},
            //     {title: 'fucking nutes'},
            //     {title: 'this is the titleasasadasdasdasdas'}, //tleasasadasdasdasdas
            //     {title: 'fucking nutes'},
            //     {title: 'fucking nutes'}
            // ],

        }

    }

    // xox() {
    //     console.log('nick')
    // }
    
    async goEdit(id) {
        await this.setState({ editID: id}) ;
        this.changeNav('create-edit');
    }

    async duplicate(id) {
        let res= await axios.post('/courses/duplicate', { id: id } ) ;
        // console.log('out: ',res.data);
        window.location.reload(false); //refresh page
    }
    alertDelete(id) {
        this.setState({ alert: id}) 
    }
    async delete(id) {
        this.state.courses.forEach(course => {
            if ( course.ID === id ) {
                this.goDeleteFile('image', course.img );
                course.unit.forEach(unit=>{
                    unit.lessons.forEach((lesson)=>{
                        if ( lesson.content.video != '' ) {
                            this.goDeleteFile('video', lesson.content.video) 
                        }
                        if ( lesson.content.download != '' ) {
                            this.goDeleteFile('file', lesson.content.download) 
                        }
                    });
                })
            } 
        }); 

        // await console.log('id: ', id );
        let res= await axios.post('/courses/delete', { id: id } ) ; 
        // console.log('out: ',res.data);
        await window.location.reload(false);
        this.loadAll(); 
    } 
 
    async loadAll() { 
        let res= await axios.get('/courses/loadall' )

        let courses = res.data 
        courses.forEach((course,index)=>{
                courses[index].unit = JSON.parse(course.unit) 
          
        })

        // console.log(courses.unit);
        
        this.setState({ courses: courses }) 
        // console.log('out: ',res.data) 
    }


    changeNav(page) {
        this.setState({ innernav: page });
        if (page=='courses') {
            window.location.reload(false);
        }
        
    } 
    async goDeleteFile(type,filename) {        
        axios.delete('/courses/deletefile', {params: {type: type, file: filename } }) 
    };

    async goPreview(id) {
        let courses = this.state.courses;
        var course = '';
        courses.forEach(coursex=>{
            if (coursex.ID === id) {
                course = coursex;
            }
        }); 
        await this.setState({ course: course });
        this.changeNav('preview');


    }
    async componentDidMount() { 
        await this.loadAll();   
    }
    
    render() { 
               
        return(
            <div>  
{/* { this.state.editID ? <div className="header-home">nick</div> : <div className="header-home">bad</div> } */}
{ this.state.alert != '' ? 
    <div className='alert-delete' onClick={()=>{this.setState({ alert: false}) }}>
        <div className='alert-cnt'>
            Are you sure you would like delete this Course?
            <div className='alert-cnt-btn'>
                <button className='rbtn-blue'   
                    onClick={()=>{  this.delete( this.state.alert ); }}
                > Yes Delete it </button>
                <button className='rbtn-blue'   
                    onClick={()=>{this.setState({ alert: false}) }} 
                > No Dont!</button>
            </div> 
        </div> 
    </div>
: null }
            
{ this.state.innernav==='create' ? 
    <Create
        changeNav={this.changeNav.bind(this)}
    />
: this.state.innernav==='create-edit' ? 
    <Create
        changeNav={this.changeNav.bind(this)}
        editID={this.state.editID}

    />
: this.state.innernav==='preview' ? 
    <Preview
        changeNav={this.changeNav.bind(this)}
        course={this.state.course}
        qty=''
    />
: <>
<div className='column-body'>
<div className="header-home">
    <h1>Course Library</h1> 
    <div>
    <input className="btn-blue" type="submit" value="+ Create" onClick={()=>{this.changeNav('create');}}/>
    {/* <input className="btn-blue" type="submit" value="Manage" /> */}
    </div>
</div>

{/* <button className='rbtn-blue' onClick={()=>{ this.fixShowImg(); }}> NICK XoX </button> */}
<div className='course-cnt' > 

{  this.state.courses != '' ?  <>
    {  this.state.courses.map(course =>
        <lis className='list-con'> 
       
        { course.img!='' ?  
            <img src={ `/uploads/images/${course.img}` } alt='' onClick={()=> { this.goEdit(course.ID); } } ></img>
        :  <img src={imgtrade} alt='' onClick={()=> { this.goEdit(course.ID); } } ></img>
        }
            <p>
                {course.title}
            </p> 
            <div className='cnt-delete'>
                <Link   id='all-Link'  
                    onClick= {()=>{ this.alertDelete(course.ID); }}     
                >
                <FiTrash2 className='img-delete'/>
                    Delete
                </Link>
                <Link  id='all-Link' 
                    // onClick= {()=>{ this.duplicate(course.ID); }}    
                >
                <BiDuplicate className='img-delete'/>
                    Duplicate
                </Link> 
                <Link   id='all-Link'
                    onClick= {()=>{ this.goPreview(course.ID); }}     
                >
                    <AiOutlineEye className='img-delete'/>
                    Preview 
                </Link>
            </div>

        </lis>
    )} </> : null  }
 
 




</div>

 
</div>
</>}
</div>
          
        )

    }

}

