import React, { Component } from 'react';  
import axios from 'axios';  
// import { Link } from 'react-router-dom';  
import { FiTrash2 } from "react-icons/fi";  
import { AiOutlineEye } from 'react-icons/ai'; 
import { FiEdit3 } from "react-icons/fi";  
import DoStatus from './homework-studnets';


export default class Homework extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {  
            roles: '',
            new: -2,
            innertab: 'homework',//
            status: '',
            title: '',
            questions: [
                { question: '' , answer: ''}
            ],
            homework: []
            // homework: [
            //     { id: 1, status: 'inprogress', title: 'NICK' },
            //     { id: 2, status: 'inprogress', title: 'XoX' },
            //     { id: 3, status: 'inprogress', title: 'StarStarStarStarStarStarStarStarStarStarStarStarStarStarStarStarStarStarStarStarStar' }
            // ]
} }/////////////////////////////////////////////////////////////////////////////
changeStatus(value) {
    this.setState({ status: value});
}
async innerTab(tab) {
    await this.setState({ innertab: tab }); 
    if (tab === 'add') {
        // this.updateChecks();
    }
};
async goSave() { 
    var homework = this.state.homework;
    // console.log('ststate: ',this.state.status);
    // console.log('questionsxox: ',this.state.questions);
    // console.log('questionsxoxty: ',typeof this.state.questions);

    var newwork = { status: this.state.status, title: this.state.title, questions: this.state.questions };
    if (this.state.new===-2) {
        homework.push(newwork);
        this.create(newwork);
    } else {
         
        homework[this.state.new] = newwork;
        // console.log('theid: ',homework[this.state.new].id);
        newwork.id = this.state.new;
    // console.log('newwork: ', newwork);
      
        this.save(newwork);
    } 
    await this.setState({ homework: homework });  
    
    this.setState({ status: 'inprogress' }); 
    this.setState({ title: '' }); 


    // console.log('questions: ',this.state.questions); 
    // console.log('questionsty: ', typeof this.state.questions);

    await this.setState({ questions:  [{ question: '' , answer: ''}] }); 

    // console.log('questions: ',this.state.questions); 
    // console.log('questionsty: ', typeof this.state.questions);
    await this.innerTab('homework');
    this.setState({ new:  -2 }); 
    

};
async save(work) {    
    let thework = work;
    // console.log('ingosave: ',thework)
    thework.id = this.state.homework[this.state.new].id
    // console.log('midgosave: ',thework)

    thework.questions = JSON.stringify(thework.questions) 
    // console.log('asdasd: ',thework)

    axios.post('/courses/savehomework', { work: thework })  
}
async create(work) {   
    let thework = work
    thework.questions =  JSON.stringify(thework.questions)  
    let res = await axios.post('/courses/createhomework', { work: thework })
    let homework = this.state.homework  
    homework[homework.length-1].id = res.data
    this.setState({ homework: homework }); 

}
updateQues(index,value) {
    let questions = this.state.questions;
    questions[index].question = value;
    this.setState({ questions: questions });  
};
updateAnsw(index,value) {
    let questions = this.state.questions;
    questions[index].answer = value;
    this.setState({ questions: questions });  
};
addWork() {
    let questions = this.state.questions;
    let newwork = { question: '' , answer: ''}
    questions.push(newwork);
    this.setState({ questions: questions }); 
}
async deleteWork(index) {
    let questions = this.state.questions;
    questions.splice(index,1);
    await this.setState({ questions: questions });
    if (questions.length === 0) {
        this.addWork();
    } 
};
deleteHomework(index) {
    let homework = this.state.homework  
    axios.post('/courses/deletehomework',{
        title: homework[index].title
    });
    homework.splice(index,1);
    this.setState({ homework: homework });
};
async edit(index) {
    // console.log('roles: ', this.state.roles);  
    let question = this.state.homework[index];
    // console.log('status: ', question.status); 
    await this.setState({ status: question.status}); 

    await this.setState({ title: question.title });  
    if ( typeof question.questions === 'string' ) {
        await this.setState({ questions: JSON.parse(question.questions) });
    } else {
        await this.setState({ questions: question.questions });
    } 
    await this.setState({ new: index });
    await this.innerTab('add');

};
checkboxInprogress() {
    let inprogress = document.querySelectorAll('.checkbox-inprogress')[0].checked; 
    // let futures = document.querySelectorAll('.checkbox-futures')[0];  
    // let monthly = document.querySelectorAll('.checkbox-monthly')[0]; 
    // futures.checked = !inprogress 
    // if (inprogress) {
    //     monthly.checked = !inprogress 
    //     this.setState({ status: 'inprogress' }); 
    // } 
    if (inprogress) { 
        this.setState({ status: 'inprogress' });  
        this.state.roles.forEach((role,index) => {
            if ( role.name !== 'XoX_admin_oVo') {
                var check = document.querySelectorAll(`.checkbox-${role.name}`)[0]; 
                check.checked = false ;
            }

        });
    }
}
checkboxMonthly() { 
    // let monthly = document.querySelectorAll('.checkbox-monthly')[0].checked; 
    // let futures = document.querySelectorAll('.checkbox-futures')[0].checked;  
    // let inprogress = document.querySelectorAll('.checkbox-inprogress')[0]; 
    // if (monthly && futures) {
    //     inprogress.checked = false
    //     this.setState({ status: 'monthlyfutures' });  
    // }  
    // else if (!monthly && futures) {
    //     inprogress.checked = false
    //     this.setState({ status: 'futures' }); 
    // }  
    // else if (monthly && !futures) {
    //     inprogress.checked = false
    //     this.setState({ status: 'monthly' });  
    // }  
    // else if (!monthly && !futures) {
    //     inprogress.checked = true
    //     this.setState({ status: 'inprogress' });   
    // } 
    var inprogress = document.querySelectorAll('.checkbox-inprogress')[0]; 
    var status = '' ; 

    this.state.roles.forEach((role,index) => {
        // console.log('role: ', role);
        if (  role.name !== 'XoX_admin_oVo') {
            var check = document.querySelectorAll(`.checkbox-${role.name}`)[0];   
            if (check.checked) {
                inprogress.checked = false ;
                status += role.name ;  
            }   
        } 
    });
    if (status === '') {
        inprogress.checked = true ; 
        this.setState({ status: 'inprogress' });  

    }
    // console.log('status: ', status); 
    this.setState({ status: status });  

};
updateChecks() {
    // console.log('statusasd: ', this.state.status);
    // let inprogress = document.querySelectorAll('.checkbox-inprogress')[0];
    // let monthly = document.querySelectorAll('.checkbox-monthly')[0]; 
    // let futures = document.querySelectorAll('.checkbox-futures')[0];  
    // if (this.state.status === 'inprogress') {
    //     inprogress.checked = true }  
    // if (this.state.status.includes('monthly')) {
    //     monthly.checked = true  } 
    // if (this.state.status.includes('futures')) {
    //     futures.checked = true  } 
    let inprogress = document.querySelectorAll('.checkbox-inprogress')[0];
    // let monthly = document.querySelectorAll('.checkbox-monthly')[0]; 
    // let futures = document.querySelectorAll('.checkbox-futures')[0];  
    if (this.state.status === 'inprogress') {
        inprogress.checked = true 
    } else {
        this.state.roles.forEach((role,index) => { 
            if (  role.name !== 'XoX_admin_oVo') {
                var check = document.querySelectorAll(`.checkbox-${role.name}`)[0];  
                if ( this.state.status.includes(role.name) ) {
                    check.checked = true
                }
            } 
        });
    }  
}
async loadAll() { 
    let res= await axios.get('/courses/loadhomework' )

    let homework = res.data 
    homework.forEach((work,index)=>{
        homework[index].questions = JSON.parse(work.questions)  
    }) 
    this.setState({ homework: homework }) 
    // console.log('out: ',res.data) 
}
async componentDidMount() {  
    this.loadAll();
    var res = await axios.get('/managing/getroles'); 
    this.setState({ roles: res.data }); 
};
render() { return(//////////////////////////////////////////////////////////////
<>{
this.state.innertab === 'dostatus' ?<>
    <DoStatus
        status = {this.state.status}
        changeStatus={this.changeStatus.bind(this)}
        backTab={this.innerTab.bind(this)}
    />
</> : this.state.innertab === 'homework' ? 
    <div className='column-body'>
        <div className="header-home">
            <h1> Homework </h1> 
            <div>
                <input className="btn-blue" type="submit" value="+ Add" onClick={()=>{this.innerTab('add');}}/> 
            </div>
        </div>
    
        <div className='ctn-homework'>
            {/* title/edit, edit preview delelte */}
            { this.state.homework.length !==0 ? 
                this.state.homework.map((homework,index)=>(
                    <div className='cnt-each-homework'>
                        <div className='cnt-homework-title' onClick={()=>{ this.edit(index); }} >   
                            { homework.title }
                        </div> 
                        <div className='cnt-homework-btn' onClick={()=>{ this.edit(index); }} >
                            <FiEdit3/>
                        </div> 
                        <div className='cnt-homework-btn' onClick={()=>{ this.deleteHomework(index); }} >
                            <FiTrash2/>
                        </div> 
                        {/* <div className='cnt-homework-btn'   >
                            <AiOutlineEye/>
                        </div>  */}
                    
                    </div> 
                ))
            : null }
        </div> 
    
    </div> 
: this.state.innertab === 'add' ?  
    <div className='column-body'>
        <div className="header-home">
            <input className="btn-blue" type="submit" value="Go Back" onClick={()=>{this.goSave();}}/>  
            <h1> Add Homework </h1> 
            <input className="btn-blue" type="submit" value="Save" onClick={()=>{this.goSave();}}/> 
        </div>
        

        <div className='cnt-homework-add'>               
          
      
                <div>
            <div className='cnt-worksheet'>
                Homework Title
                <div>
                        <input type="text" autoComplete="on" 
                            onChange={(e)=>{ this.setState({ title: e.target.value }) }} 
                            value={this.state.title} 
                            />
                </div>
            </div>

            { this.state.questions.map((work,index)=>(
                <div className='cnt-worksheet'>
                    <div className='cnt-worksheet-question'>
                        <p> Question/ Prompt </p> 
                        <button className='rbtn-clear' onClick={()=>{ this.deleteWork(index); }}>   
                            <FiTrash2 className='img-delete2'/>  Delete
                        </button> 
                
                    </div>
                    <div>
                        <input type="text" autoComplete="on" 
                            onChange={(e)=>{ this.updateQues(index,e.target.value); }} 
                            value={this.state.questions[index].question} 
                            />
                    </div>
                    <div className='txt-answer'>
                        Answer/ Placeholder
                    </div>
                    <div>
                        <input type="text" autoComplete="on"
                            onChange={(e)=>{ this.updateAnsw(index,e.target.value); }}
                            value={this.state.questions[index].answer} 
                            />
                    </div>
                </div>
            ))}
    
            <div className='cnt-btn-question'>
                    <button className='rbtn-blue' 
                        onClick={()=>{ this.addWork(); }}> + Add New Question </button> 
            </div>
        </div>   
            <div className='ctn-create-side'>
                <div className='ctn-status'> Assign To:
                    <div className='ctn-check'>



                    <button className='rbtn-blue' 
                        onClick={()=>{ this.innerTab('dostatus'); }}> Assign Students </button> 

                        {/* <div>
                            <input type='checkbox' className='checkbox-inprogress' 
                                onClick={()=>{ this.checkboxInprogress(); }} /> 
                                <label >In progress...</label>
                        </div>

                        { this.state.roles !== '' ?  
                            this.state.roles.map((role,index)=>(<> 
                                {  role.name === 'XoX_admin_oVo' ? null :
                                    <div>
                                        <input type='checkbox' className={`checkbox-${role.name}`}  onClick={()=>{ this.checkboxMonthly(); }} /> 
                                        <label  >{role.display} </label>
                                    </div>
                                }
                            </>))
                        : null } */}


                        {/* <div>
                            <input type='checkbox' className='checkbox-monthly' 
                                onClick={()=>{ this.checkboxMonthly(); }} /> 
                            <label >Publish: Monthly</label>
                        </div>
                        <div>  
                            <input type='checkbox' className='checkbox-futures' 
                                onClick={()=>{ this.checkboxMonthly(); }} /> 
                            <label >Publish: Futures BootCamp</label>
                        </div>  */}



                        
                    </div>
                </div> 
            </div>   
        </div>
    </div>
     
 : null } </>
) }}////////////////////////////////////////////////////////////////////////////

