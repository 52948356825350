import React, { Component } from 'react';  
// import axios from 'axios';   
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';

import XoX from './_updateCard';   
// const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);
const stripePromise = loadStripe('pk_live_51JeQywClHw133esC8Cao8LYddjtyjekbXH18S1f0EXF4mZR3XJxESi66nkB5DqfM5EDgdjIbgTiQ5JOdKCjCx1oJ00DzN7l5Vi');



export default class XSoX extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {  
            nick: 222
} }/////////////////////////////////////////////////////////////////////////////ctx
 
componentDidMount() {    
};
render() { return(<div> {/*//////////////////////////////////////////////////*/}
<Elements stripe={stripePromise}>
    <ElementsConsumer>  
        {(props) => <XoX {...props} 
             mystripe = { this.props.mystripe }
        
        />}
    </ElementsConsumer>
</Elements>

</div> ) }}/////////////////////////////////////////////////////////////////////

