import React, { Component } from 'react';  
import { BiColorFill } from 'react-icons/bi';
import ReactPlayer from 'react-player'; 
import fileDownload from 'js-file-download'; 
import { IoClose, IoCheckmark } from "react-icons/io5";     
import { AiOutlineCloudDownload } from "react-icons/ai";    
import axios from 'axios';  

// / unit: '[{"num":1,"title":"","lessons":[{"num":1,"title":"","content":{"builder":"","video":"",
// "quiz":[{"question":"","answers":[{"correct":false,"answer":""}]}],"worksheet":{"title":"","questions":[{"question":"","placeholder":""}]},"download":""}}]}]'
 


// { unqcourse: 222, items: [{ unit: 0, lesson: 0, type: '', content: '' , complete: false }], percent: 0 } ;


export default class TakeCourse extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {  
            course: '',
            progression: { unit: 0, lesson: 0, type: 'nick', content: '', index: 0 },
            allprogress: false,
            progress: false,
            isgrading: false,
            worksheetanswers: {},
            downloadfile: '',

            

} }/////////////////////////////////////////////////////////////////////////////


async goDownloadFile() {

    let file = this.state.progression.content;
    let res = await axios.get('/takecourse/download',{params: { 
        file: file, token:'nickxox'
    },responseType:'blob'}); 
    // console.log('res: ',res.data) 
    fileDownload(res.data,this.state.downloadfile );  
}



updateWorkAnswers(question,value) {

    var worksheetanswers = this.state.worksheetanswers ;

    worksheetanswers[question] = value ;

    this.setState({ worksheetanswers : worksheetanswers });
 
}

sendWorksheet() {
    // var workbase = { title: '', lesson: '', date: '' , worksheet: {} } ;
    // var progression = this.state.progression ;

    // workbase.title = progression.content.title ; 
    // workbase.lesson = this.state.course.unit[this.state.progression.unit].lessons[this.state.progression.lesson].title ;
    // workbase.date = new Date(Date.now()).toLocaleDateString() ;
    // workbase.worksheet = this.state.worksheetanswers ; 
    // // send to back end 
    // // console.log('workbase: ', workbase );
    // axios.post('/takecourse/saveworksheet', {
    //     folder: this.props.user.folder,
    //     workbase: workbase
    // });
    
    this.goNext();
};

gradeQuiz() {
    this.setState({ isgrading: true });
    var gradedquiz = {};
    gradedquiz.lesson = this.state.course.unit[this.state.progression.unit].lessons[this.state.progression.lesson].title;
    gradedquiz.date = new Date(Date.now()).toLocaleDateString() ;

    var htmlgradedquiz = [];
    var rightanswers = 0 ;
    var wronganswers = 0 ;
    this.state.course.unit[this.state.progression.unit].lessons[this.state.progression.lesson].content.quiz.forEach((question,index)=>{ 
        var htmtgradequizbase = { answers: [] };
        question.answers.forEach((answer,indexx)=>{
            var htmlgradeanswerbase = {};
            htmlgradeanswerbase.rightanswer = answer.correct ;
            htmlgradeanswerbase.theiranswer = document.querySelectorAll(`.check-take-${index}-${indexx}`)[0].checked; 
            htmtgradequizbase.answers.push(htmlgradeanswerbase);
            
            if ( answer.correct ) {
                if ( htmlgradeanswerbase.theiranswer === answer.correct ) {
                    rightanswers += 1 ;
                } else {
                    wronganswers += 1 ; 
                };
            };
 

        });

        htmlgradedquiz.push(htmtgradequizbase);
    });


    gradedquiz.score = Math.round((rightanswers / ( rightanswers + wronganswers)) * 100 ) ;
    // push gradedquiz to db
    // console.log('htmlgradedquiz: ', gradedquiz);
    // console.log('htmlgradedquiz: ', htmlgradedquiz); 
    this.setState({ htmlgradedquiz: htmlgradedquiz });
    this.setState({ gradedquiz: gradedquiz });

    // axios.post('/takecourse/savequiz', {
    //     folder: this.props.user.folder,
    //     gradedquiz: gradedquiz
    // });
};



handleQuizChecks(indexx,index) {  
    var check = document.querySelectorAll(`.check-take-${index}-${indexx}`)[0];
    
    
    
    if ( check.checked  ) { // turned to true kill others
        // check.checked = true 
      
        var answers = this.state.course.unit[this.state.progression.unit].lessons[this.state.progression.lesson].content.quiz[index].answers
        answers.forEach((answer,indx)=>{
            if ( indx != indexx ) {
                var check = document.querySelectorAll(`.check-take-${index}-${indx}`)[0];
                check.checked = false 
            }

        });

        }

  

                    // let check = document.querySelectorAll(`.check-${index}-${indexx}`)[0];
                    // check.checked = true
};

goBack() {
 
 
    var allprogress = this.state.allprogress ;
    var base = { unit: 0, lesson: 0, type: '', content: '', index: 0 } ;
    var progression = this.state.progression ;
    // var length = allprogress.items.length - 1 ;
    var next = allprogress.items[progression.index - 1] ; 
    

    if (progression.index === 0 ) {
        this.props.changeTab('preview'); 
    } else {
        base.unit = next.unit ;
        base.lesson = next.lesson ;
        base.type = next.type ;
        base.content = next.content ;
        base.index = progression.index - 1 ;
        this.setState({ progression: base });
        // console.log('base2: ', base);

        if ( base.type === 'worksheet' ) {
            // //create worksheetanswers
            var worksheetanswers = {} ;
            base.content.questions.forEach((question)=>{
                worksheetanswers[question.question] = ''
            });
            this.setState({ worksheetanswers: worksheetanswers });  
            // console.log('worksheetanswers: ', worksheetanswers); 
        } else if ( base.type === 'download' ) {
            var renamefile = '' ;
            var startrename = false ; 
            for (var i = 0; i < base.content.length; i++) {
                if ( base.content[i] === '_' ) {
                    startrename = true
                } else if ( startrename ) {
                    renamefile += base.content[i] ; 
                    
                }
            }
            this.setState({ downloadfile: renamefile });
            
        }   


        if ( allprogress.items[progression.index].type === 'quiz' ) {
            this.setState({ isgrading: false }); 
        } 
    }
};

goNext() {
  
    var allprogress = this.state.allprogress ;
    var base = { unit: 0, lesson: 0, type: '', content: '', index: 0 } ;
    var progression = this.state.progression ;
    var length = allprogress.items.length - 1 ;
    var next = allprogress.items[progression.index + 1] ;
 

    if (progression.index === length ) {
        this.props.backbackTab('');
        // this.props.changeTab('preview');
        // console.log('preview: ', 'preview');
    } else {
        base.unit = next.unit ;
        base.lesson = next.lesson ;
        base.type = next.type ;
        base.content = next.content ;
        base.index = progression.index + 1 ;
        this.setState({ progression: base });
        // console.log('base2: ', base.content); 


        if ( base.type === 'worksheet' ) {
            // //create worksheetanswers
            var worksheetanswers = {} ;
            base.content.questions.forEach((question)=>{
                worksheetanswers[question.question] = ''
            });
            this.setState({ worksheetanswers: worksheetanswers });  
            // console.log('worksheetanswers: ', worksheetanswers); 
        } else if ( base.type === 'download' ) {
            var renamefile = '' ;
            var startrename = false ; 
            for (var i = 0; i < base.content.length; i++) {
                if ( base.content[i] === '_' ) {
                    startrename = true
                } else if ( startrename ) {
                    renamefile += base.content[i] ; 
                    
                }
            }
            this.setState({ downloadfile: renamefile });
            
        }   
        
        if ( allprogress.items[progression.index].type === 'quiz' ) {
            this.setState({ isgrading: false }); 
        } 
    }

    allprogress.items[progression.index].complete = true ;
    var percentc = 0 ; 
    allprogress.items.forEach((task,tindex)=>{
        if ( task.complete ) {
            percentc += 1 ;
        }  
    }); 
    var percent = Math.round((percentc / (length + 1)) * 100) ; 
    allprogress.percent = percent ;
    axios.post('/takecourse/updateprogress',{
        folder: this.props.user.folder,
        progress: allprogress 
    }); 


}

loadCourse() {
    this.setState({ course: this.props.course });
    // console.log('course: ', this.props.course );
    var course = this.props.course ;
    var quid = course.title;
    var allprogress = { unqcourse: quid, items: [], percent: 0 } ;
 
    course.unit.forEach((unit,indexx)=>{  
        unit.lessons.forEach((lesson,idex)=>{
            if ( lesson.content.builder !== '') {
                var base = { unit: 0, lesson: 0, type: '', content: '' , complete: false }
                base.unit = indexx ; 
                base.lesson = idex ;
                base.type = 'builder' ;
                base.content = lesson.content.builder ; 
                allprogress.items.push(base);
            }
            if ( lesson.content.video !== '') {
                var base = { unit: 0, lesson: 0, type: '', content: '' , complete: false }
                base.unit = indexx ; 
                base.lesson = idex ;
                base.type = 'video' ;
                base.content = lesson.content.video ; 
                allprogress.items.push(base);
            }
            if ( lesson.content.quiz[0].question !== '') {
                var base = { unit: 0, lesson: 0, type: '', content: '' , complete: false }
                base.unit = indexx ; 
                base.lesson = idex ;
                base.type = 'quiz' ;
                base.content = lesson.content.quiz ; 
                allprogress.items.push(base);
            }
            if ( lesson.content.worksheet.questions[0].question !== '') {
                var base = { unit: 0, lesson: 0, type: '', content: '' , complete: false }
                base.unit = indexx ; 
                base.lesson = idex ;
                base.type = 'worksheet' ;
                base.content = lesson.content.worksheet ; 
                allprogress.items.push(base);

            }
            if ( lesson.content.download !== '') {
                var base = { unit: 0, lesson: 0, type: '', content: '' , complete: false }
                base.unit = indexx ; 
                base.lesson = idex ;
                base.type = 'download' ;
                base.content = lesson.content.download ; 
                allprogress.items.push(base);
            } 
        })
    }) 

    var base = { unit: 0, lesson: 0, type: '', content: '', index: 0 } ;
    var next = allprogress.items[0]  

    base.unit = next.unit ;
    base.lesson = next.lesson ;
    base.type = next.type ;
    base.content = next.content ;
    base.index = 0 ;
    this.setState({ progression: base });  

    // console.log('allprogress: ', allprogress);
    this.setState({ allprogress: allprogress });

    this.setState({ progress: allprogress.items });
    
   
 


}
 

componentDidMount() {    
    this.loadCourse();
    

};
render() { return(<div> {/*/////////////////////////////////////////////////////*/}

<div className='column-body'>

    <div className="header-home">
        <input className="btn-blue" type="submit" value="Back" onClick={()=>{ this.goBack(); }}/>

        { this.state.course === '' ? 
            <h1>Loading..</h1> 
        :  
            <h1>{ `${this.state.course.unit[this.state.progression.unit].lessons[this.state.progression.lesson].title}/${this.state.progression.type}` }</h1> 
        }

        <div> 
            <input className="btn-blue" type="submit" value="Next"   onClick = {()=>{ this.goNext(); }}  />
        </div>
    </div>


        { this.state.progression.type === 'builder'   ? 

            <div id='cnt-take-build' > 
                <dd> {this.state.progression.content} </dd>
            </div>   
        :  this.state.progression.type === 'video'   ? 
            <div className='vid-take-video'> 
                {/* <ReactPlayer url={ `/uploads/videos/${this.state.progression.content}` } 
                    width='100%' height='98%' controls={true}   
                    config={{ file: { 
                        attributes: {
                          controlsList: 'nodownload'
                        }
                      }}}
                />  */}
                <video id='autovideo' controls controlslist="nodownload"  width = '100%' height = '98%' 
                    autoPlay muted  
                >
                    <source src={ `/uploads/videos/${this.state.progression.content}` }      type='video/mp4'/>  
                </video>

            </div>
        :  this.state.progression.type === 'quiz'   ? 
            <div> 
                { this.state.progression.content.map((question,index) => (<div>  
                    <div id='cnt-take-quiz' > 
                        <div  id='cnt-take-quiz-question'> 
                            <dd> {`${index + 1}) ${question.question}?`} </dd>
                        </div> 
                        <div id='cnt-take-quiz-answers'>     
                            { question.answers.map((answer,indexx) => (<div>   
                                <div className='flex' id='input-take-check' >    
                                    <input type='checkbox' className={`check-take-${index}-${indexx}`}    onClick={()=>{ this.handleQuizChecks(indexx,index); }}    /> 
                                    <dd  > { answer.answer } </dd>
                                    { this.state.isgrading ? <>
                                        { this.state.htmlgradedquiz[index].answers[indexx].rightanswer ? <IoCheckmark id='img-check' /> 
                                        : this.state.htmlgradedquiz[index].answers[indexx].theiranswer && !this.state.htmlgradedquiz[index].answers[indexx].rightanswer ? 
                                            <IoClose id='img-close' /> 
                                        : null }
                                    </> : null }
                                </div>
                            </div>))   }
                        </div>
                    </div>   
                    
                </div>))   }
                { this.state.isgrading ? <>
                    <div id='cnt-take-button-next'>   
                        <input className="btn-blue" type="submit" value="Next"   onClick = {()=>{ this.goNext(); }}  />
                        <dd> {`Score: ${this.state.gradedquiz.score}%`} </dd>
                    </div>
                </> : 
                    <div id='cnt-take-button'>    
                        <button className='rbtn-green' onClick={ ()=>{ this.gradeQuiz(); } } >  Submit Answers </button>    
                    </div>
                }
            </div>

        :  this.state.progression.type === 'worksheet'   ? 
            <div>
                <div id='cnt-take-worksheet' >
                    <h1> {this.state.progression.content.title} </h1> 
                </div>

                <div id='cnt-take-worksheet-question'>  
                    
                    { this.state.progression.content.questions.map((question,index) => (<div id='cnt-worksheet-each'>   
                        <div  id='cnt-take-quiz-question'> 
                            <dd> {`${index + 1}) ${question.question}?`} </dd>
                        </div> 

                        <div className='input input-indent'>     
                            <input type="text" autoComplete="on" 
                                onChange={(e)=>{ this.updateWorkAnswers(question.question,e.target.value); }} 
                                placeholder= {question.placeholder}
                                value = { this.state.worksheetanswers[question.question]}
                                />
                        </div>
                    </div>))  }
                </div>
                <div id='cnt-take-button'>    
                        <button className='rbtn-green' onClick={ ()=>{ this.sendWorksheet(); } } >  Submit Worksheet </button>    
                </div>
            </div>
        :  this.state.progression.type === 'download'   ? 
            <div>
                <div id='cnt-take-download' >
                    <h1> {this.state.downloadfile} </h1> 
                    <AiOutlineCloudDownload  id='img-download-take' onClick={()=>{ this.goDownloadFile(); }} />
                </div>
            </div>
        :
        <div className='nothing'> 
            <h5 > {this.state.progression.type} </h5>
        </div>
        }




</div>



</div> ) }}////////////////////////////////////////////////////////////////////////

 