import axios from 'axios';
import React, { Component } from 'react';  

export default class Viewhomework extends Component { 
    constructor(props) { 
        super(props); 
        this.state = {  
            worksheet: '',
            // worksheet: {
            //     "title": "dfgdfgdfg",
            //     "lesson": "dfgdfgdddfgdfg",
            //     "date": "11/19/2021",
            //     "worksheet": {
            //       "dfg": "erterte",
            //       "dfgd": "rtertert",
            //       "": "erte"
            //     }
            //   }
} }/////////////////////////////////////////////////////////////////////////////
goBack() {
    this.props.backTab('');
}
async loadWorksheet() { 
    var res = await axios.get('/managing/getworksheet', { params: {
        folder: this.props.folder,
        title: this.props.title,
        lesson: this.props.lesson
    }});
    // console.log('homework:',  res.data);  
    // console.log('title:', this.props.title);  
    // console.log('folder:', this.props.folder);  
    if (!res.data) {
        // console.log('flaseddata:',  res.data);  
        this.goBack();
    } else {
        // console.log('else:',  res.data);   
        await this.setState({ worksheet: res.data }); 
    } 
}
componentDidMount() {  
    // console.log('title:', this.props.title);  
    this.loadWorksheet();
};
render() { return(<div> {/*//////////////////////////////////////////////////*/}


<div className='column-body'>
{ this.state.worksheet !== '' ? <> 

    <div className="header-home">
        <input className="btn-blue" type="submit" value="Back" onClick={()=>{ this.goBack(); }}/>  
        <h1>{this.state.worksheet.title}</h1>   
        <input className="btn-hidden" type="submit" value="Back"     /> 
    </div>


        { Object.keys(this.state.worksheet.worksheet).map((key,index) => ( 
            // key: {key} Data: {this.state.worksheet.worksheet[key]} 
            <div id='cnt-take-quiz' > 
            <div  id='cnt-take-quiz-question'> 
                <dd> {`${index + 1}) ${key}?`} </dd>
            </div> 
            <div id='cnt-take-quiz-answers'>     
               
                    <div className='flex' id='input-take-check' >    
                        {/* <input type='checkbox' className={`check-take-${index}-${indexx}`}    onClick={()=>{ this.handleQuizChecks(indexx,index); }}    />  */}
                        <dd  > {this.state.worksheet.worksheet[key]} </dd>
                        {/* { this.state.isgrading ? <>
                            { this.state.htmlgradedquiz[index].answers[indexx].rightanswer ? <IoCheckmark id='img-check' /> 
                            : this.state.htmlgradedquiz[index].answers[indexx].theiranswer && !this.state.htmlgradedquiz[index].answers[indexx].rightanswer ? 
                                <IoClose id='img-close' /> 
                            : null }
                        </> : null } */}
                    </div>
                
            </div>
        </div>   
    ))}


</> : null }
</div>  

</div> ) }}/////////////////////////////////////////////////////////////////////

